import React from 'react';
import {
  StyledResourceBox,
  StyledResource,
  StyledResourceItem,
} from '../LevelList.style';
import { t } from 'utils/i18n';

const difficultyLevels = [
  { code: 'Begin', name: t('containers.level.__easy', 'Dễ') },
  {
    code: 'Basic',
    name: t('containers.level.__mediumEasy', 'Trung Bình - Dễ'),
  },
  { code: 'Intermediate', name: t('containers.level.__medium', 'Trung Bình') },
  {
    code: 'Advanced',
    name: t('containers.level.__mediumHard', 'Trung Bình - Khó'),
  },
  {
    code: 'Expert',
    name: t('containers.level.__hard', 'Khó'),
  },
];

const Level = ({ selectedLevel, setSelectedLevel }) => {
  const clickHandler = (selectedLevel) => {
    setSelectedLevel(selectedLevel);
  };

  return (
    <StyledResource>
      {difficultyLevels.map((level) => (
        <StyledResourceBox key={level.code}>
          <div className="content">
            <div className="list">
              <StyledResourceItem
                key={level.code}
                onClick={() => clickHandler(level)}
                active={selectedLevel && selectedLevel.code === level.code}
              >
                {level.name}
              </StyledResourceItem>
            </div>
          </div>
        </StyledResourceBox>
      ))}
    </StyledResource>
  );
};

export default Level;
