import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MainLayout from 'layouts/MainLayoutV2';
import { useBitrix } from 'utils/hooks/useBitrix';
import useCountdownTimer from './utils/useCountdownTimer';
import { ViewMode } from 'white-react-sdk';
import styled from 'styled-components';
import Student from 'components/NewOneBoard/VideoInfo/Student';
import Footer from 'components/NewOneBoard/Footer';
import {
  useRosterState,
  ContentShare,
  useContentShareState,
  useRemoteVideoTileState,
  useAudioVideo,
  useVideoInputs,
  useLocalVideo,
  useMeetingStatus,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import { Roles, ClassType } from 'constants/index';
import moment from 'moment';
import Whiteboard from 'components/NewOneBoard/Whiteboard';
import ToolBox from 'components/NewOneBoard/ToolBox';
import {
  WaitingAnimationV2,
  WorldWallContainer,
  NetworkNotification,
  CourseCompleted,
} from 'components';
import { useLocalToolboxAuth } from '@oneboard/meeting';
import { ClassroomPerformanceCard } from 'components';
import { Box, Modal, FullButton } from '@oneboard/ui-components';
import {
  useMeetingState,
  MeetingStates,
  MeetingRoles,
  useMeetingDispatch,
} from '@oneboard/meeting';
import { Reward } from '@oneboard/meeting';
import { ContentShareContainer, IconBox } from './SyncSingle.style';
import { useRoom } from 'providers/RoomProvider';
import { useQuizService } from 'utils/hooks/useQuiz';
import { useCheckDevice } from 'utils/hooks/useCheckDevice';
import { InsertPictureModal } from 'components';
import AdvisorContainer from 'components/NewOneBoard/VideoInfo/common/AdvisorContainer';
import AdvisorVideo from 'components/NewOneBoard/VideoInfo/common/WhiteboardVideo';
import {
  QuizModalV2,
  SyncStagedVideoList,
  TeachingMaterialContainer,
} from 'containers';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useQuery } from 'utils/hooks/useQuery';
import { useNetworkContext } from 'providers/NetworkProvider';
import { NotificationLayout } from './SyncSingle.style';
import { useGroupContext } from 'providers/GroupProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { StudentMainRoomNotification } from 'containers/BreakoutRoomNotification';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`views.mainPage.student.syncSingleV2.${key}`, '', params);
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 6;
`;

const StagedVideoListLayout = styled(Box)`
  display: flex;
  min-height: 136px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 8px 16px 0px rgba(145, 158, 171, 0.16);
`;

export const SyncSingle = () => {
  const { meetingId } = useParams();
  const { courseInfo } = useCourseInfo();
  const { hideCRMChat } = useBitrix();
  const { roster } = useRosterState();
  const { allNotCheck } = useCheckDevice();
  const attendees = Object.values(roster);
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [roomClosingModal, setRoomClosingModalModal] = useState(false);
  const [inviteModalState, setInviteModalState] = useState(false);
  const [inviteInfo, setInviteInfo] = useState(null);
  const [iframeGroup, setIframeGroup] = useState([]);
  const toggleRoomClosingModal = () =>
    setRoomClosingModalModal((prev) => !prev);
  const hasOtherRole = !!attendees.find(
    (attendee) =>
      attendee.role === Roles.Teacher || attendee.role === Roles.Advisor
  );
  const { isToolboxEnabled } = useLocalToolboxAuth();
  const meetingState = useMeetingState();
  const { attendeeId: userId } = meetingState.context;
  const { pauseReward } = useMeetingDispatch();
  const { sharingAttendeeId } = useContentShareState();
  const {
    attendeeId: selfAttendeeId,
    worldWall,
    role,
    userName,
    stagedAttendeeIds,
    courseType,
    toolboxAuth,
  } = meetingState.context;
  const { insertPictureModalSwitch } = useRoom();
  const student = Object.values(roster).find(
    (attendee) => attendee.role === 'student'
  );
  const advisors = Object.values(roster).filter(
    (attendee) => attendee.role === 'advisor'
  );
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { noFocus, addToolboxAuthAttendeeIds } = useMeetingDispatch();
  const { studentQuizStateMatches, States } = useQuizService();
  const isOpenQuizModal = studentQuizStateMatches(States.Starting);
  const {
    redirectToBreakoutMeeting,
    getBreakoutMeetingInfo,
    getBreakoutLatest,
  } = useBreakoutMeeting();
  const audioVideo = useAudioVideo();
  const tileIds = useMemo(
    () => Object.values(attendeeIdToTileId),
    [attendeeIdToTileId]
  );
  const { muted, toggleMute } = useToggleLocalMute();
  const query = useQuery();
  const teacher = attendees.find((attendee) => attendee.role === Roles.Teacher);
  const advisor = attendees.find((attendee) => attendee.role === Roles.Advisor);
  const { devices: videoDevices } = useVideoInputs();
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const meetingStatus = useMeetingStatus();
  const {
    networkNotice,
    closeNetworkNotice,
    openNetworkNotice,
    networkStatus,
  } = useNetworkContext();
  const isInviting = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BreakoutMeetingInvite}.${MeetingStates.Inviting}`,
  });
  const { BreakoutRoomState, breakoutRoomStep, changeBreakoutRoomStep } =
    useGroupContext();
  const { service: whiteboardService } = useWhiteboard();
  const { scaleToFit } = useZoomControl();
  const teacherIsJoined =
    attendees.filter((attendee) => attendee.role === Roles.Teacher).length > 0;
  const advisorIsJoined =
    attendees.filter((attendee) => attendee.role === Roles.Advisor).length > 0;
  const isLeaving = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.BreakoutMeetingLeave}.${MeetingStates.Leaving}`,
  });
  const teacherIsExist = !!teacher;
  const advisorIsExist = !!advisor;
  const currentViewMode = whiteboardService?.state?.broadcastState?.mode;
  const countdown = useCountdownTimer(courseInfo?.startAt);

  const rewardCompletedHandler = useCallback(() => {
    pauseReward({ userId });
  }, []);

  const getBreakoutMeetingData = async () => {
    const data = await getBreakoutMeetingInfo();
    setInviteInfo(data);
  };

  const enterMessage = async () => {
    if (query.enterNotice === 'true') {
      let msg = trans('__enterClassroomMessage');

      await sendMessage(msg);
    }
  };

  const { sendMessage } = useChatMessage();

  const isClassroomPerformanceOpen = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.ClassroomPerformance}.${MeetingStates.Open}`,
  });

  const isRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });

  const contentSharingAttendeeId = useMemo(
    () => sharingAttendeeId?.split('#')[0] || '',
    [sharingAttendeeId]
  );

  const isShowContentShare = useMemo(
    () =>
      contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) =>
          prev.filter((item) => item.sources !== material.sources)
        );
        break;
      default:
        break;
    }
  };

  const noFocusHandler = () => {
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        noFocus({
          userName,
          key: selfAttendeeId,
        });
      }
    });
  };

  const isTilePaused = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.TileState}.${MeetingStates.Paused}`,
  });

  const toggleInviteModalHandler = () => setInviteModalState((prev) => !prev);
  const goToBreakoutRoomHandler = () => redirectToBreakoutMeeting();

  const getBreakoutInfo = async () => {
    const breakoutLatestInfo = await getBreakoutLatest(meetingId);
    if (breakoutLatestInfo?.status === 'Start') {
      await getBreakoutMeetingData();
      changeBreakoutRoomStep(BreakoutRoomState.grouping);
    }
  };

  const showCourseCompleted = useMemo(() => {
    if (!courseInfo?.endAt) return false;
    const currentTime = moment();
    const endAt = moment(courseInfo.endAt);
    return currentTime.isAfter(endAt);
  }, [courseInfo?.endAt]);

  const isInClassTime = useMemo(() => {
    if (!courseInfo?.startAt || !courseInfo?.endAt) {
      return false;
    }
    const currentMoment = moment();
    const startMoment = moment(courseInfo.startAt);
    const endMoment = moment(courseInfo.endAt);

    return currentMoment.isBetween(startMoment, endMoment);
  }, [courseInfo]);

  useEffect(() => {
    if (teacherIsExist || advisorIsExist) {
      hideCRMChat();
    }
  }, [teacherIsExist, advisorIsExist]);

  useEffect(() => {
    getBreakoutInfo();
  }, []);

  useEffect(() => {
    if (isLeaving) {
      toggleRoomClosingModal();
    }
  }, [isLeaving]);

  useEffect(() => {
    noFocusHandler();
  }, []);

  useEffect(() => {
    if (query.classType === ClassType.SyncSingle) {
      if (allNotCheck) {
        if (!muted) {
          toggleMute();
        }
      } else {
        if (muted) {
          toggleMute();
        }
      }
    } else {
      if (!muted) {
        toggleMute();
      }
    }
  }, []);

  useEffect(() => {
    if (!audioVideo) return;
    if (!teacherIsExist && !advisorIsExist) return;
    if (query.classType !== (ClassType.SyncMultiple || ClassType.SyncStreaming))
      return;

    const stagedTileIds = stagedAttendeeIds
      .filter((attendeeId) => attendeeIdToTileId[attendeeId])
      .map((attendeeId) => attendeeIdToTileId[attendeeId]);

    if (teacherIsExist && !advisorIsExist) {
      const teacherTileId = attendeeIdToTileId[teacher.chimeAttendeeId];
      tileIds
        .filter((tileId) => tileId !== teacherTileId)
        .filter((tileId) =>
          stagedTileIds.find((stagedTileId) => stagedTileId === tileId)
            ? false
            : true
        )
        .forEach((tileId) => {
          audioVideo.pauseVideoTile(tileId);
        });
    } else if (!teacherIsExist && advisorIsExist) {
      const advisorTileId = attendeeIdToTileId[advisor.chimeAttendeeId];
      tileIds
        .filter((tileId) => tileId !== advisorTileId)
        .filter((tileId) =>
          stagedTileIds.find((stagedTileId) => stagedTileId === tileId)
            ? false
            : true
        )
        .forEach((tileId) => {
          audioVideo.pauseVideoTile(tileId);
        });
    } else if (teacherIsExist && advisorIsExist) {
      const teacherTileId = attendeeIdToTileId[teacher.chimeAttendeeId];
      const advisorTileId = attendeeIdToTileId[advisor.chimeAttendeeId];

      audioVideo.unpauseVideoTile(teacherTileId);
      tileIds
        .filter(
          (tileId) => tileId !== teacherTileId && tileId !== advisorTileId
        )
        .filter((tileId) =>
          stagedTileIds.find((stagedTileId) => stagedTileId === tileId)
            ? false
            : true
        )
        .forEach((tileId) => {
          audioVideo.pauseVideoTile(tileId);
        });
    }
  }, [tileIds, attendees, stagedAttendeeIds]);

  useEffect(() => {
    if (courseType === (ClassType.SyncMultiple || ClassType.SyncStreaming))
      return;
    if (videoDevices.length === 0) return;
    if (!isVideoEnabled && meetingStatus === 1) {
      toggleVideo();
    }
  }, [meetingStatus]);

  useEffect(() => {
    if (toolboxAuth && (teacherIsJoined || advisorIsJoined)) {
      addToolboxAuthAttendeeIds({ attendeeId: selfAttendeeId });
    }
  }, [teacherIsJoined, advisorIsJoined, toolboxAuth]);

  useEffect(() => {
    if (videoDevices.length === 0) return;
    if (
      courseType === (ClassType.SyncMultiple || ClassType.SyncStreaming) &&
      isVideoEnabled &&
      !teacherIsJoined &&
      !advisorIsJoined
    ) {
      toggleVideo();
    }
  }, [teacherIsJoined, advisorIsJoined]);

  useEffect(() => {
    isTilePaused ? openNetworkNotice() : closeNetworkNotice();
  }, [isTilePaused]);

  useEffect(() => {
    if (!isInviting) return;

    toggleInviteModalHandler();
    getBreakoutMeetingData();
    changeBreakoutRoomStep(BreakoutRoomState.grouping);
  }, [isInviting]);

  useEffect(() => {
    if (!whiteboardService) return;
    const handler = () => {
      scaleToFit();
    };

    whiteboardService.addMagixEventListener('changeBookId', handler);
    whiteboardService.addMagixEventListener('closeViewMode', handler);

    return () => {
      whiteboardService.removeMagixEventListener('changeBookId', handler);
      whiteboardService.removeMagixEventListener('closeViewMode', handler);
    };
  }, [whiteboardService]);

  useEffect(() => {
    enterMessage();
  }, []);

  useEffect(() => {
    if (!currentViewMode && !whiteboardService) return;
    const checkAndSetFollowerMode = () => {
      if (whiteboardService && currentViewMode !== ViewMode.Follower) {
        whiteboardService.setViewMode(ViewMode.Follower);
      }
    };
    const intervalId = setInterval(checkAndSetFollowerMode, 3 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentViewMode, whiteboardService]);

  if (!student) {
    return null;
  }

  return (
    <>
      <MainLayout
        main={
          <MainContainer>
            <Whiteboard
              disableToolbox={!isToolboxEnabled}
              userRole={Roles.Student}
              toolBox={<ToolBox />}
            />
            {stagedAttendeeIds.length > 0 && (
              <StagedVideoListLayout>
                <SyncStagedVideoList attendees={attendees} />
              </StagedVideoListLayout>
            )}
            {isClassroomPerformanceOpen && (
              <StyledBox>
                <ClassroomPerformanceCard />
              </StyledBox>
            )}
            {isRewardActive && (
              <Reward rewardType="Trophy" onComplete={rewardCompletedHandler} />
            )}
            {isShowContentShare && (
              <ContentShareContainer>
                <ContentShare />
              </ContentShareContainer>
            )}
            {insertPictureModalSwitch && <InsertPictureModal />}
            {breakoutRoomStep === BreakoutRoomState.grouping && (
              <NotificationLayout>
                <StudentMainRoomNotification
                  roomInfo={inviteInfo}
                  isLeaving={isLeaving}
                />
              </NotificationLayout>
            )}
            {networkNotice && (
              <NotificationLayout>
                <NetworkNotification
                  networkStatus={networkStatus}
                  onClose={closeNetworkNotice}
                />
              </NotificationLayout>
            )}
            <TeachingMaterialContainer
              iframeGroup={iframeGroup}
              onClose={closeTeachingMaterialHandler}
            />
            {worldWall.switch && role === Roles.Student && (
              <WorldWallContainer url={worldWall.url} />
            )}
            {isOpenQuizModal && <QuizModalV2 role={Roles.Student} />}
            {roomClosingModal && (
              <Modal
                width="600px"
                onClose={toggleRoomClosingModal}
                header={trans('__groupDiscussion')}
              >
                <Box>{trans('__discussionRoomWillBeClosed')}</Box>
                <Box display="flex" justifyContent="center" width="100%" pt={8}>
                  <Box width="50%">
                    <FullButton.Secondly onClick={toggleRoomClosingModal}>
                      {trans('__closeWindow')}
                    </FullButton.Secondly>
                  </Box>
                </Box>
              </Modal>
            )}
            {inviteModalState && (
              <Modal
                width="600px"
                header={trans('__joinBreakoutRoom')}
                onClose={toggleInviteModalHandler}
              >
                {trans('__haveBeenInviteToJoinBreakoutSession')}
                <Box display="flex" width="100%" pt={8}>
                  <Box width="100%" mr={2}>
                    <FullButton.Secondly onClick={toggleInviteModalHandler}>
                      {trans('__joinLater')}
                    </FullButton.Secondly>
                  </Box>
                  <Box width="100%" ml={2}>
                    <FullButton onClick={goToBreakoutRoomHandler}>
                      {trans('__joinIn')}
                    </FullButton>
                  </Box>
                </Box>
              </Modal>
            )}
            <AdvisorContainer
              userType={student.role}
              isStudentList={stagedAttendeeIds.length > 0}
            >
              {advisors.map((advisor) => (
                <AdvisorVideo
                  key={advisor.chimeAttendeeId}
                  userType={advisor.role}
                  tileId={attendeeIdToTileId[advisor.chimeAttendeeId]}
                  attendeeId={advisor.chimeAttendeeId}
                  advisorName={advisor.name}
                />
              ))}
            </AdvisorContainer>
          </MainContainer>
        }
        side={
          <RightContainer>
            <Student isSettingModalOpen={isSettingModalOpen} />
          </RightContainer>
        }
        footer={
          <BottomContainer>
            <Footer
              isSettingModalOpen={isSettingModalOpen}
              setIsSettingModalOpen={setIsSettingModalOpen}
            />
          </BottomContainer>
        }
      />
      {!hasOtherRole && !showCourseCompleted && (
        <WaitingAnimationV2
          isInClassTime={isInClassTime}
          countdown={countdown}
        />
      )}
      {!hasOtherRole && showCourseCompleted && (
        <CourseCompleted
          startTime={courseInfo?.startAt}
          endTime={courseInfo?.endAt}
          courseType={courseInfo?.type}
        />
      )}
    </>
  );
};
