import React from 'react';
import { Button } from 'antd';
import { Box } from '@oneboard/ui-components';
import { useBitrixService } from 'utils/hooks/useBitrix';
import { StyledFallbackComponent } from './FallbackComponent.style';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`components.fallBackComponent.${key}`, '', params);
};

export const FallbackComponent = ({ className }) => {
  const { url: bitrixUrl } = useBitrixService();
  return (
    <StyledFallbackComponent
      className={className}
      data-testid="FallbackComponent"
    >
      <div className="wrap">
        <div className="textContent">
          <div className="oops">Oops!</div>
          <div className="title">{trans('__thereIsAnErrorOnTheWebPage')}</div>
          <div className="content">
            <div>{trans('__youCanTryToRefresh')}</div>
            <div>
              {trans('__customerServiceHotline')}
              <span className="phone">{trans('__phoneNumber')}</span>
            </div>
            <Box mt={1}>
              <Button type="primary" href={bitrixUrl} target="_blank">
                {trans('__instantOnlineCustomerService')}
              </Button>
            </Box>
          </div>
        </div>
        <div className="imgContent">
          <img src="/images/sorry.png" alt="" />
        </div>
      </div>
    </StyledFallbackComponent>
  );
};
