import React, { useEffect, useState, useRef, useMemo } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { Roles, ClassType } from 'constants/index';
import { Popover, notification } from 'antd';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { RECORD_VIDEO_STATE } from 'machines/RecordVideoMachine';
import {
  startRecordVideo,
  stopRecordVideo,
  recordVideoInfo,
} from 'services/recordVideo';
import { useRequest, useUpdateEffect } from 'ahooks';
import { v4 as uuid } from 'uuid';
import { StyledPopContent, StyledRecordBox } from './RecordButton.style';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
} from '@oneboard/meeting';
import { t } from 'utils/i18n';
import { LoadingOutlined } from '@ant-design/icons';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import {
  CancelButton,
  SendButton,
} from 'components/NewOneBoard/common/MessageButton';
import { useStreaming } from 'providers/StreamingProvider';
// import { useCourseInfo } from 'providers/CourseInfoProvider';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.recordButton.${key}`,
    '',
    params
  );
};

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ModalText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
`;

const EndButton = styled(SendButton)`
  width: auto;
`;

const openNotification = ({ error }) => {
  notification.error({
    className: 'customNotification',
    message: error,
    key: uuid(),
  });
};

const RecordingContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ec7963;
  font-weight: 500;
  gap: 2px;
`;

const RecordingText = styled.div`
  color: #637381;
  width: fit-content;
  white-space: nowrap;
`;

const STREAMING_URL = process.env.REACT_APP_STREAMING_API_DOMAIN;

export const RecordButton = ({ currentRole }) => {
  const { isFooterOpen } = useFooterStatus();
  // const { courseInfo } = useCourseInfo();

  const ReminderMessage =
    currentRole === Roles.Teacher
      ? t(
          'mainPage.teacher.syncSingle.remindToTurnVideoOn',
          '開始上課了嗎?\n別忘了按下錄影按鈕，讓學生可以在課後複習本次課程喔！'
        )
      : trans('__pleaseRecordConsultation');
  const StreamingMessage = trans('__haveYouStartedClass');
  const { meetingId } = useParams();
  const meetingState = useMeetingState();
  const { roster } = useRosterState();
  const {
    teacherStartRecord,
    teacherStopRecord,
    advisorStartRecord,
    advisorStopRecord,
    observerStopRecord,
  } = useMeetingDispatch();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const isRecordObserver = attendees.find(
    (attendee) =>
      attendee.role === Roles.Observer && attendee.name === 'recordObserver'
  );
  const { context } = meetingState;
  const { role, courseType } = context;
  const [recordingModalState, setRecordingModal] = useState(false);
  const [recordingStartTime, setRecordingStartTime] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(null);
  const [isOpenDisconnectModal, setIsOpenDisconnectModal] = useState(false);
  const { isBroadcasting, setIsBroadcasting } = useStreaming();
  const mainPageRef = useRef();
  const buttonRef = useRef();
  const recordVideoState = meetingState.value.Joined[role].RecordVideo;
  let isRecordVideoOn = false;

  switch (role) {
    case Roles.Teacher:
      isRecordVideoOn = meetingState.matches({
        [MeetingStates.Joined]: `${Roles.Teacher}.${MeetingStates.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
      });
      break;
    case Roles.Advisor:
      isRecordVideoOn = meetingState.matches({
        [MeetingStates.Joined]: `${Roles.Advisor}.${MeetingStates.RecordVideo}.${RECORD_VIDEO_STATE.On}`,
      });
      break;
    default:
      break;
  }

  const { loading: startRecordLoading, run: runStartRecordVideo } = useRequest(
    startRecordVideo,
    {
      manual: true,
      onSuccess: async (result, params) => {
        if (result.ok) {
          switch (role) {
            case Roles.Teacher:
              teacherStartRecord();
              break;
            case Roles.Advisor:
              advisorStartRecord();
              break;
            default:
              break;
          }
        } else {
          const errorText = await result.text();
          openNotification({ error: errorText });
        }
      },
      onError: (error, params) => {
        openNotification({ error: error.message });
      },
    }
  );

  const { loading: stopRecordLoading, run: runStopRecordVideo } = useRequest(
    stopRecordVideo,
    {
      manual: true,
      onSuccess: async (result, params) => {
        if (result.ok) {
          setRecordingModal(false);
          switch (role) {
            case Roles.Teacher:
              teacherStopRecord();
              break;
            case Roles.Advisor:
              advisorStopRecord();
              break;
            default:
              break;
          }
        } else {
          const errorText = await result.text();
          openNotification({ error: errorText });
        }
      },
      onError: (error, params) => {
        openNotification({ error: error.message });
      },
    }
  );

  // 錄影狀態
  const { loading: recordVideoInfoLoading, run: runRecordVideoInfo } =
    useRequest(recordVideoInfo, {
      manual: true,
      onSuccess: async (result, params) => {
        if (result.ok) {
          const recordInfo = await result.json().then((res) => res.data[0]);

          if (recordInfo) {
            const isRecording = recordInfo.isRecording;
            if (isRecording) {
              switch (role) {
                case Roles.Teacher:
                  teacherStartRecord();
                  break;
                case Roles.Advisor:
                  advisorStartRecord();
                  break;
                default:
                  break;
              }
            }
          }
        } else {
          const errorText = await result.text();
          openNotification({ error: errorText });
        }
      },
    });

  const openConfirmModal = () => setRecordingModal(true);

  const closeConfirmModal = () => {
    if (stopRecordLoading) return;
    setRecordingModal(false);
  };

  const confirmEndHandler = async () => {
    setRecordingStartTime(null);
    stopRecord();
  };

  const startStreaming = async () => {
    const response = await fetch(
      `${STREAMING_URL}/stream-course-video/${meetingId}/start`,
      {
        method: 'POST',
      }
    );
    const data = await response.json();
    if (data.err === 0) {
      setIsBroadcasting(true);
    } else {
      openNotification({ error: trans('__liveStreamingFailed') });
    }
  };

  const startRecord = async () => {
    if (recordVideoInfoLoading || startRecordLoading) return;
    setRecordingStartTime(new Date());
    runStartRecordVideo({ meetingId });

    if (courseType === ClassType.SyncStreaming && role === Roles.Teacher) {
      //  確定拿到錄影檔案再開始串流直播
      setTimeout(() => {
        startStreaming();
      }, 10000);
    }
  };

  // 檢查串流狀態
  const getStreamingStatus = async () => {
    // Not available in Vietnam
    return;
    // if (!courseInfo) return;
    // const grade = courseInfo?.livestreaming?.grade;
    // const subject = courseInfo?.livestreaming?.subject;
    // const response = await fetch(
    //   `${STREAMING_URL}/stream-course-video/ivs/stream-status/${grade}/${subject}`,
    //   {
    //     method: 'GET',
    //   }
    // );
    // const res = await response.json();
    // const statusData = res.data;
    // if (statusData.state === 'offline') {
    //   setIsOpenDisconnectModal(true);
    //   setIsBroadcasting(false);
    //   // 串流斷線，停止錄影
    //   stopRecord();
    // }
  };

  const handleCloseDisconnectModal = () => {
    setIsOpenDisconnectModal(false);
  };

  useEffect(() => {
    if (isBroadcasting) {
      const interval = setInterval(() => {
        getStreamingStatus();
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [isBroadcasting]);

  useEffect(() => {
    if (recordingStartTime) {
      const interval = setInterval(() => {
        const now = new Date();
        const distance = Math.floor((now - recordingStartTime) / 1000);
        const minutes = Math.floor(distance / 60)
          .toString()
          .padStart(2, '0');
        const seconds = (distance % 60).toString().padStart(2, '0');
        setRecordingDuration(`${minutes}:${seconds}`);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [recordingStartTime]);

  const stopRecord = () => {
    runStopRecordVideo({ meetingId });
    if (courseType === ClassType.SyncStreaming && role === Roles.Teacher) {
      setIsBroadcasting(false);
    }
  };

  const clickHandler = () => {
    const { On } = RECORD_VIDEO_STATE;
    switch (recordVideoState) {
      case On:
        openConfirmModal();
        break;

      default:
        startRecord();
        break;
    }
  };

  useEffect(() => {
    const getRecordVideoInfo = () => {
      if (role === Roles.Teacher || role === Roles.Advisor) {
        runRecordVideoInfo({ meetingId });
      }
    };

    getRecordVideoInfo();

    const rootDom = document.getElementById('MainPage');
    mainPageRef.current = rootDom;
  }, []);

  useUpdateEffect(() => {
    if (!isRecordObserver) {
      observerStopRecord();
    }
  }, [isRecordObserver]);

  return (
    <StyledRecordBox ref={buttonRef}>
      {isRecordVideoOn ? (
        <Popover
          overlayInnerStyle={{
            borderRadius: '8px',
            width: 'fit-content',
          }}
          overlayClassName='stopRecordPopover'
          content={
            <StyledPopContent>
              <RecordingContainer>
                <Icon name='RecordSolid' size='xs' color='#EC7963' />
                {courseType !== ClassType.SyncStreaming &&
                  trans('__stopRecording')}
                {courseType === ClassType.SyncStreaming &&
                  trans('__stopLiveStreaming')}
              </RecordingContainer>
              {/* {recordingDuration && <RecordingText>錄影時間：{recordingDuration}</RecordingText>} */}
            </StyledPopContent>
          }
          placement={'top'}
          trigger='hover'
          arrowPointAtCenter={true}
        >
          <div>
            <ToolButton
              onClick={clickHandler}
              icon={
                <Icon
                  name={
                    courseType === ClassType.SyncStreaming
                      ? 'FluentLiveOffFilled'
                      : 'StopRecordSolid'
                  }
                  size='sm'
                  color='#CD3B33'
                />
              }
            ></ToolButton>
          </div>
        </Popover>
      ) : (
        <Popover
          visible={true}
          placement={courseType === ClassType.SyncMultiple ? 'topLeft' : 'top'}
          getPopupContainer={() => buttonRef.current}
          overlayInnerStyle={{
            backgroundColor: '#FF7257',
            borderRadius: '8px',
            width: '190px',
          }}
          overlayClassName='startRecordPopover'
          content={
            <StyledPopContent>
              {courseType === ClassType.SyncStreaming
                ? StreamingMessage
                : ReminderMessage}
            </StyledPopContent>
          }
        >
          <ToolButton
            onClick={startRecord}
            icon={
              recordVideoInfoLoading || startRecordLoading ? (
                <LoadingOutlined />
              ) : (
                <Icon
                  name={
                    courseType === ClassType.SyncStreaming
                      ? 'FluentLiveFilled'
                      : 'RecordSolid'
                  }
                  size='sm'
                  color='#CD3B33'
                />
              )
            }
          ></ToolButton>
        </Popover>
      )}

      <ToolModal
        active={recordingModalState}
        onClose={closeConfirmModal}
        title={
          courseType === ClassType.SyncStreaming
            ? trans('__liveStreamingEnded')
            : trans('__videoEnd')
        }
        footerHeight='84px'
        footerContent={
          <ButtonContainer>
            <CancelButton
              block
              size='large'
              onClick={closeConfirmModal}
              loading={stopRecordLoading}
            >
              {trans('__cancel')}
            </CancelButton>
            <EndButton
              block
              size='large'
              onClick={confirmEndHandler}
              loading={stopRecordLoading}
            >
              {stopRecordLoading ? (
                <LoadingOutlined />
              ) : (
                t('containers.recordButton.confirmModal.button', '結束')
              )}
            </EndButton>
          </ButtonContainer>
        }
      >
        <ModalText>
          {courseType === ClassType.SyncStreaming
            ? trans('__confirmEndLiveStream')
            : t(
                'containers.recordButton.confirmModal.content',
                '確認結束錄影嗎？'
              )}
        </ModalText>
      </ToolModal>
      <ToolModal
        active={isOpenDisconnectModal}
        onClose={handleCloseDisconnectModal}
        title={trans('__liveStreamInterrupted')}
        footerHeight='84px'
        footerContent={
          <ButtonContainer>
            <EndButton block size='large' onClick={handleCloseDisconnectModal}>
              {trans('__confirm')}
            </EndButton>
          </ButtonContainer>
        }
      >
        <ModalText>{trans('__continueLiveStream')}</ModalText>
      </ToolModal>
    </StyledRecordBox>
  );
};
