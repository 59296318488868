import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { LinkModal, TeachingMaterialModal } from 'containers';
import {
  useCanvasDispatch,
  useCanvasState,
  CanvasStates,
} from '@oneboard/whiteboard';
import { Box, Popover } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import { useQuizService } from 'utils/hooks/useQuiz';
import {
  StyledPaintToolBar,
  PaintTool,
  PaintToolGroup,
} from './PaintToolBar.style';
import { useGroupContext } from 'providers/GroupProvider';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`containers.paintToolBar.${key}`, '', params);
};

export const PaintToolBar = ({
  className,
  bookId,
  onInsertMedia,
  handleWhiteboardToolClick,
  isWhiteboardOpen = false,
  loadTeachingMaterials,
}) => {
  const state = useCanvasState();
  const {
    mouse,
    draw,
    select,
    eraser,
    eraseAll,
    insertImage,
    toggleTeachingMaterial,
  } = useCanvasDispatch();
  const { toggleRandomModal } = useGroupContext();

  const {
    context: { teachingMaterialState },
  } = state;

  const inputFileRef = useRef();

  const isMatchState = (currentState) => {
    return state.matches({
      [CanvasStates.Initialized]: currentState,
    });
  };

  const toggleTeachingMaterialHandler = useCallback(
    () => toggleTeachingMaterial(teachingMaterialState),
    [teachingMaterialState]
  );

  const [isLinkModalShow, setLinkModalShow] = useState(false);

  const toggleLinkModal = () => setLinkModalShow((prev) => !prev);

  const inserMediaHandler = (evt) => {
    const files = evt.target.files;
    if (files.length < 1 || !files[0]) return;
    const fileType = files[0].type;
    const url = URL.createObjectURL(files[0]);
    let params = {};

    if (fileType.match('image.*')) {
      insertImage(evt);
      params = {
        type: 'image',
        materials: [url],
      };
    }

    if (fileType.match('video.*')) {
      params = {
        type: 'video',
        sources: url,
      };
    }

    if (fileType.match('audio.*')) {
      params = {
        type: 'audio',
        sources: url,
      };
    }

    onInsertMedia(params);
    evt.target.value = null;
  };

  const submitLinkHandle = (link) => {
    const params = {
      label: 'website',
      sources: link,
      type: 'iframe',
    };
    onInsertMedia(params);
  };

  const { setQuiz } = useQuizService();

  const changeTeachingMaterialHandler = (materials) => {
    loadTeachingMaterials(materials);
    toggleTeachingMaterialHandler();
  };

  return (
    <Box width="100%" height="100%" display="flex" alignItem="center">
      <StyledPaintToolBar className={className} data-testid="PaintToolBar">
        <PaintToolGroup>
          <PaintTool
            active={isMatchState(CanvasStates.Idle)}
            title={trans('__mouse')}
            onClick={mouse}
          >
            <Icon name="MousePointerSolid" />
          </PaintTool>

          <PaintTool
            active={isMatchState(CanvasStates.Selecting)}
            title={trans('__select')}
            onClick={select}
          >
            <Icon name="HandPaperSolid" />
          </PaintTool>

          <PaintTool
            active={isMatchState(CanvasStates.Drawing)}
            title={trans('__pen')}
            onClick={draw}
          >
            <Icon name="PenSolid" />
          </PaintTool>

          <PaintTool
            active={isMatchState(CanvasStates.Erasing)}
            title={trans('__eraser')}
            onClick={eraser}
          >
            <Icon name="EraserSolid" />
          </PaintTool>

          <PaintTool onClick={eraseAll} title={trans('__clearAll')}>
            <Icon name="TrashSolid" />
          </PaintTool>

          <PaintTool
            onClick={() => inputFileRef.current.click()}
            title={trans('__addFile')}
          >
            <input
              ref={inputFileRef}
              onChange={inserMediaHandler}
              hidden
              type="file"
              accept="image/*,audio/*,video/*"
            />
            <Icon name="FolderOpenSolid" />
          </PaintTool>

          <PaintTool
            active={isWhiteboardOpen}
            onClick={handleWhiteboardToolClick}
            title={trans('__whiteboard')}
          >
            <Icon name="ChalkboardSolid" />
          </PaintTool>

          <Popover
            trigger="click"
            visible={teachingMaterialState}
            placement="rightTop"
            content={
              <TeachingMaterialModal
                onClose={toggleTeachingMaterialHandler}
                onChange={changeTeachingMaterialHandler}
                currentBookId={bookId}
              />
            }
          >
            <PaintTool
              onClick={toggleTeachingMaterialHandler}
              title={trans('__addMaterial')}
            >
              <Icon name="ShapesSolid" />
            </PaintTool>
          </Popover>

          <PaintTool
            onClick={toggleLinkModal}
            title={trans('__websiteResources')}
          >
            <Icon name="GlobeSolid" />
          </PaintTool>

          <PaintTool onClick={setQuiz} title={trans('__answerTool')}>
            <Icon name="QaToolSolid" />
          </PaintTool>

          <PaintTool onClick={toggleRandomModal} title={trans('__randomTool')}>
            <Icon name="SlotsSolid" />
          </PaintTool>
        </PaintToolGroup>
        {isLinkModalShow && (
          <LinkModal onClose={toggleLinkModal} submit={submitLinkHandle} />
        )}
      </StyledPaintToolBar>
    </Box>
  );
};

PaintToolBar.propTypes = {
  className: PropTypes.string,
  bookId: PropTypes.string,
  onInsertMedia: PropTypes.func,
  handleWhiteboardToolClick: PropTypes.func,
  isWhiteboardOpen: PropTypes.bool,
  loadTeachingMaterials: PropTypes.func,
};
