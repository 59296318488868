import React, { useState, useRef, useEffect } from 'react';
import { useMeetingState, useMeetingDispatch } from '@oneboard/meeting';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { Popover } from '@oneboard/ui-components';
import {
  useAudioVideo,
  useMeetingManager,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { ClassType, Roles } from 'constants/index';
import styled from 'styled-components';
import Icon from '@onedesign/icon';
import { EmojiButtonV2 } from 'components';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`components.newOneBoard.chatRoom.${key}`, '', params);
};

const Container = styled.div`
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  width: 100%;
  gap: 16px;
  z-index: 2;
`;

const ChatRoomContainer = styled.div`
  display: flex;
  padding: 16px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  max-width: 276px;
  height: 229px;
  margin: 0;
  height: 335px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;

const ChatRoomTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  background: #fff;
  border-radius: 16px 16px 0 0;
  width: 276px;
  height: 41px;
  border-bottom: 0.5px solid #919eab52;
  align-items: center;
`;

const ChatRoomTittle = styled.p`
  color: #454f5b;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

const ChatRoomContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  flex: 1 0 0;
  gap: 10px;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
  align-items: ${({ isSelf }) => (isSelf ? 'flex-end' : 'flex-start')};
`;

const MessageBubble = styled.div`
  background-color: ${(props) => (props.isSelf ? '#BDE3E4' : '#F4F6F8')};
  border-radius: 12px;
  font-size: 11px;
  padding: 7px 12px;
  margin: 0;
  width: fit-content;
  word-break: break-word;
  user-select: text;
  flex: 1;
`;

const ChatRoomFooter = styled.div`
  display: flex;
  max-width: 276px;
  padding: 10px 8px 8px 8px;
  gap: 10px;
  align-self: stretch;
  align-items: flex-end;
  background: #fff;
  border-radius: 0 0 16px 16px;
  margin: 0;
`;

const InputLeftContainer = styled.div`
  background: #f2f4f8;
  border-radius: 8px;
  display: flex;
  align-items: ${({ message }) => (message.length > 0 ? 'flex-end' : 'center')};
  gap: 8px;
  padding: 4px 8px;
  height: auto;
  flex: 1 0 0;
  height: fit-content;
`;

const TextArea = styled.textarea`
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  background: transparent;
  border: none;
  width: 100%;
  color: #000;
  resize: none;
  overflow-y: hidden;
  min-height: 16px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'text')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ::placeholder {
    color: #919eab;
    font-size: 12px;
    font-weight: 400;
  }
  &:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }
`;

const NameWrapper = styled.div`
  display: ${({ isSelf }) => (isSelf ? 'none' : 'flex')};
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 6px;
  padding-left: 6px;
`;

const MessageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: ${({ isSelf }) => (isSelf ? 'row-reverse' : 'row')};
  gap: 4px;
`;

const Name = styled.p`
  margin: 0;
  font-size: 11px;
  color: #637381;
`;

const Time = styled.p`
  color: #919eab;
  font-size: 11px;
  font-weight: 400;
  margin: 0;
  width: 46px;
`;

const MuteOption = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 8px 0;
  width: 196px;
  .optionList {
    display: flex;
    margin: 0 16px;
    padding: 12px 16px;
    color: #637381;
    cursor: pointer;
    border-radius: 15px;
    user-select: none;

    &:hover {
      background-color: #ffeddd;
    }
  }
  .listIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
  }
  .listContent {
    font-size: 14px;
    margin-left: 8px;
    line-height: 24px;
    flex: 1;

    .customerService {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: inherit;
    }
  }
`;

export function ChatRoom({
  toggleChatRoom,
  setUnreadMessages,
  isChatRoomOpen,
}) {
  const { roster } = useRosterState();
  const audioVideo = useAudioVideo();
  const meetingManager = useMeetingManager();
  const { sendMessage, receiveMessage, createMessage } = useChatMessage();
  const { disableChat, enableChat } = useMeetingDispatch();
  const { attendeeId: selfAttendeeId } =
    meetingManager?.meetingSessionConfiguration?.credentials || {};
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const { chatDisabledAttendeeIds, courseType, role } = context;
  const textAreaRef = useRef();
  const panelRef = useRef();
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const isSelfDisabledChat = chatDisabledAttendeeIds.includes(selfAttendeeId);

  const onFocusHandler = () => {
    setIsFocused(true);
    setUnreadMessages(0);
  };

  const onBlurHandler = () => {
    setIsFocused(false);
  };

  const sendMessageHandler = async () => {
    if (!message.trim()) return;

    try {
      await sendMessage(message.trim());
      const newMessage = createMessage(message.trim());
      setMessages((prev) => [...prev, newMessage]);
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const enterHandler = async (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 13 && !e.shiftKey) {
      e.preventDefault();
      await sendMessageHandler();
    }
  };

  const onChange = (e) => {
    setMessage(e.target.value);
  };

  const insertEmojiHandler = (emojiObject) => {
    setMessage(message + emojiObject.emoji);
  };

  const handleDisableChat = (attendeeId) => {
    disableChat({ attendeeId });
  };

  const handleEnableChat = (attendeeId) => {
    enableChat({ attendeeId });
  };

  const resizeTextArea = () => {
    textAreaRef.current.style.height = 'auto';
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    if (parseInt(textAreaRef.current.style.height) > 3 * 16) {
      textAreaRef.current.style.height = 3 * 16 + 'px';
    }
  };

  useEffect(resizeTextArea, [message]);

  useEffect(() => {
    if (panelRef.current) {
      const element = panelRef.current;
      element.scrollTop = element.scrollHeight;
    }
  }, [messages.length]);

  useEffect(() => {
    if (!audioVideo) return;
    const receiveDataMessageHandler = (dataMessage) => {
      const data = dataMessage.json();
      const { senderAttendeeId, content, timestamp, role } = data;
      const receivedData = {
        senderAttendeeId,
        content,
        timestamp,
        role,
      };
      setMessages((prev) => [...prev, receivedData]);
      if (!(isChatRoomOpen && isFocused)) {
        setUnreadMessages((prevUnread) => prevUnread + 1);
      }
    };
    receiveMessage(receiveDataMessageHandler);
  }, [audioVideo, receiveMessage]);

  useEffect(() => {
    if (isChatRoomOpen) {
      textAreaRef.current.focus();
    }
  }, [isChatRoomOpen]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isFocused && e.key === 'Escape') {
        toggleChatRoom();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isFocused, toggleChatRoom]);

  const renderMessages = () => {
    return messages.map((msg, idx) => {
      const isSelf = msg.senderAttendeeId === selfAttendeeId;
      const userName = roster[msg.senderAttendeeId]?.name || '';
      const isSameSenderAsPrevious =
        idx > 0 && msg.senderAttendeeId === messages[idx - 1].senderAttendeeId;
      const displayName = !isSelf && !isSameSenderAsPrevious && userName;
      const messageContent = msg.content;
      const urlRegex = /(https?|ftp):\/\/[^\s/$.?#].[^\s]*/;
      const containsLink = urlRegex.test(messageContent);
      const isChatDisabled = chatDisabledAttendeeIds.includes(
        msg.senderAttendeeId
      );

      return (
        <MessageContainer key={idx} isSelf={isSelf}>
          <NameWrapper isSelf={isSelf || isSameSenderAsPrevious}>
            <Name>{displayName}</Name>
          </NameWrapper>
          <Popover
            placement="bottomLeft"
            trigger={
              courseType === ClassType.SyncMultiple &&
              role === Roles.Teacher &&
              !isSelf
                ? 'contextMenu'
                : ''
            } //團班時點右鍵觸發
            arrow={true}
            content={
              <MuteOption>
                {isChatDisabled ? (
                  <div
                    className="optionList"
                    onClick={() => handleEnableChat(msg.senderAttendeeId)}
                  >
                    <div className="listIcon">
                      <Icon name="CommentSolid" />
                    </div>
                    <div className="listContent">{trans('__chatDisabled')}</div>
                  </div>
                ) : (
                  <div
                    className="optionList"
                    onClick={() => handleDisableChat(msg.senderAttendeeId)}
                  >
                    <div className="listIcon">
                      <Icon name="CommentSlashSolid" />
                    </div>
                    <div className="listContent">{trans('__chatEnabled')}</div>
                  </div>
                )}
              </MuteOption>
            }
          >
            <MessageWrapper isSelf={isSelf}>
              {containsLink ? (
                <MessageBubble isSelf={isSelf}>
                  <a
                    href={messageContent}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {messageContent}
                  </a>
                </MessageBubble>
              ) : (
                <MessageBubble isSelf={isSelf}>{messageContent}</MessageBubble>
              )}
              <Time>
                {new Date(msg.timestamp).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })}
              </Time>
            </MessageWrapper>
          </Popover>
        </MessageContainer>
      );
    });
  };

  return (
    <Container>
      <ChatRoomTitleContainer>
        <ChatRoomTittle>{trans('__message')}</ChatRoomTittle>
        <div
          className="close"
          onClick={toggleChatRoom}
          style={{ cursor: 'pointer' }}
        >
          <Icon name="XmarkOutline" size="xxs" color={'#637381'} />
        </div>
      </ChatRoomTitleContainer>
      <ChatRoomContainer ref={panelRef}>
        <ChatRoomContent>{renderMessages()}</ChatRoomContent>
      </ChatRoomContainer>
      <ChatRoomFooter>
        <InputLeftContainer message={message}>
          <TextArea
            placeholder={
              isSelfDisabledChat
                ? trans('__speechDisabled')
                : trans('__pleaseEnterMessage')
            }
            ref={textAreaRef}
            value={message}
            onChange={onChange}
            onKeyDown={enterHandler}
            rows={1}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            disabled={isSelfDisabledChat}
          />
          {!isSelfDisabledChat && (
            <EmojiButtonV2 insertEmojiHandler={insertEmojiHandler} />
          )}
        </InputLeftContainer>
        {message.length > 0 && (
          <div>
            <Icon
              name="PaperPlaneSolid"
              size="md"
              color={'#FDAA74'}
              onClick={sendMessageHandler}
            />
          </div>
        )}
      </ChatRoomFooter>
    </Container>
  );
}
