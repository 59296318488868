import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '@onedesign/icon';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import VideoAudioButton from './common/VideoAudioButton';
import AudioSetting from './AudioSetting';
import VideoSetting from './VideoSetting';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.settingButton.settingButton.${key}`,
    '',
    params
  );
};

const VideoAudioContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const Divider = styled.div`
  display: flex;
  width: 1px;
  height: 100%;
  margin: 0 17px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #d8d8d8;
`;

const VideoAudioLeftPanel = styled.div`
  width: 26%;
`;

const VideoAudioRightPanel = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  align-self: stretch;
  padding-right: 16px;
`;

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

export const SettingButton = ({ isModalOpen, setIsModalOpen, currentRole }) => {
  const [activeAudio, setActiveAudio] = useState(false);
  const [activeVideo, setActiveVideo] = useState(false);
  const [activeEffect, setActiveEffect] = useState(0);

  const handleSettingModal = () => {
    setIsModalOpen(!isModalOpen);
    setActiveAudio(true);
    setActiveVideo(false);
  };

  const handleAudioClick = () => {
    setActiveAudio(true);
    setActiveVideo(false);
  };

  const handleVideoClick = () => {
    setActiveAudio(false);
    setActiveVideo(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title={trans('__moreSetting')}>
        <div>
          <ToolButton
            onClick={handleSettingModal}
            icon={<Icon name='SpeakingNoneSolid' size='md' color='#637381' />}
          ></ToolButton>
        </div>
      </Tooltip>
      <ToolModal
        active={isModalOpen}
        title={trans('__settings')}
        onClose={handleCloseModal}
        width='700px'
        height='500px'
        showBottomContainer={false}
      >
        <VideoAudioContainer>
          <VideoAudioLeftPanel>
            <VideoAudioButton active={activeAudio} onClick={handleAudioClick}>
              <Icon
                name='HeadsetSolid'
                size='lg'
                color={activeAudio ? '#EC7963' : '#637381'}
              />
              {trans('__news')}
            </VideoAudioButton>
            <VideoAudioButton active={activeVideo} onClick={handleVideoClick}>
              <Icon
                name='VideoSolid'
                size='lg'
                color={activeVideo ? '#EC7963' : '#637381'}
              />
              {trans('__video')}
            </VideoAudioButton>
          </VideoAudioLeftPanel>
          <Divider />
          <VideoAudioRightPanel>
            {activeAudio ? (
              <AudioSetting />
            ) : activeVideo ? (
              <VideoSetting
                activeEffect={activeEffect}
                setActiveEffect={setActiveEffect}
                currentRole={currentRole}
              />
            ) : null}
          </VideoAudioRightPanel>
        </VideoAudioContainer>
      </ToolModal>
    </>
  );
};
