import { useBitrixService } from 'utils/hooks/useBitrix';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.serviceButton.${key}`,
    '',
    params
  );
};
export const ServiceButton = () => {
  const { url: bitrixUrl } = useBitrixService();

  const handleCustomerService = () => {
    window.open(bitrixUrl, '_blank');
  };
  return (
    <Tooltip title={trans('__contactCustomerService')}>
      <div>
        <ToolButton
          icon={<Icon name='HeadsetSolid' size='md' color='#75BEC2' />}
          onClick={handleCustomerService}
        ></ToolButton>
      </div>
    </Tooltip>
  );
};
