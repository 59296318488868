import React, { useState } from 'react';
import { Modal, Box, Button } from '@oneboard/ui-components';
import PropTypes from 'prop-types';
import { StyledInput, StyledBox } from './LinkModal.style';
import queryString from 'query-string';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`containers.linkModal.${key}`, '', params);
};

const filterYouTubeId = (url) => {
  const isYouTubeURL = url.includes('www.youtube.com');
  const isShareYouTubeURL = url.includes('youtu.be');
  let youTubeId = '';

  if (isYouTubeURL) {
    const { search } = new URL(url);
    const params = queryString.parse(search);
    youTubeId = params.v;
  }

  if (isShareYouTubeURL) {
    const pattern = /^.*\/([a-zA-Z0-9_-]{11})$/;
    youTubeId = pattern.exec(url)[1];
  }
  return youTubeId;
};

export const LinkModal = ({ onClose, submit }) => {
  const [link, setLink] = useState('');
  const [noticeState, setNoticeState] = useState(false);
  const onChange = (e) => setLink(e.target.value);

  const noticeHandler = () => setNoticeState((prev) => !prev);

  const submitHandle = () => {
    const urlPattern = /^http/;
    const httpStart = urlPattern.test(link);

    if (!httpStart) {
      noticeHandler();
      return;
    }

    onClose();

    const youTubeId = filterYouTubeId(link);

    if (youTubeId !== '') {
      const youTubeURL = `https://www.youtube.com/embed/${youTubeId}`;
      submit && submit(youTubeURL);
      return;
    } else {
      submit && submit(link);
      return;
    }
  };

  return (
    <>
      <Modal
        width="600px"
        header={trans('__websiteResources')}
        onClose={onClose}
      >
        <StyledInput
          placeholder={trans('__enterURLHere')}
          value={link}
          onChange={onChange}
          allowClear={true}
        />
        <StyledBox>
          <Box display="flex" justifyContent="flex-end" width="100%" pt={8}>
            <Button className="close" onClick={onClose}>
              {trans('__close')}
            </Button>
            <Button disabled={link === ''} onClick={submitHandle}>
              {trans('__joinScreen')}
            </Button>
          </Box>
        </StyledBox>
      </Modal>
      {noticeState && (
        <Modal header={trans('__attention')} onClose={noticeHandler}>
          <Box>{trans('__enterCorrectURL')}</Box>
          <Box display="flex" justifyContent="flex-end" width="100%" pt={8}>
            <Button onClick={noticeHandler}>{trans('__close')}</Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

LinkModal.propTypes = {
  onClose: PropTypes.string,
  submit: PropTypes.func,
};
