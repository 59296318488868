import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { Box } from '@oneboard/ui-components';
import { t } from 'utils/i18n';
import { StyledPrivateMessageModal, Button } from './PrivateMessageModal.style';

const trans = (key, params) => {
  return t(`containers.privateMessageModal.${key}`, '', params);
};

const messageMaxLength = 40;

export const PrivateMessageModal = ({
  className,
  onClose,
  targetRole,
  placeholder,
}) => {
  const { sendPrivateMessage } = useChatMessage();
  const [messageValue, setMessageValue] = useState('');

  const changeHandler = (e) => {
    if (messageValue.length > messageMaxLength) return;
    const textAreaValue = e.target.value.trim();

    setMessageValue(textAreaValue);
  };

  const sendHandler = () => {
    sendPrivateMessage(messageValue, targetRole);
    onClose();
  };

  const enterHandler = (evt) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode === 13) {
      sendHandler();
    }
  };

  return (
    <StyledPrivateMessageModal
      className={className}
      data-testid="PrivateMessageModal"
    >
      <div className="content">
        <textarea
          placeholder={placeholder}
          maxLength={messageMaxLength}
          onChange={changeHandler}
          onKeyDown={enterHandler}
          autoFocus
        ></textarea>
        <div className="tip">
          {`${messageValue.length} / ${messageMaxLength}`}
        </div>
      </div>
      <Box display="flex" justifyContent="flex-end" width="100%" pt={5}>
        <Box mr={2} width="100%">
          <Button className="secondly" onClick={onClose}>
            {trans('__cancel')}
          </Button>
        </Box>
        <Box ml={2} width="100%">
          <Button
            className="primary"
            disabled={messageValue.length === 0 ? true : false}
            onClick={sendHandler}
          >
            {trans('__sendMessage')}
          </Button>
        </Box>
      </Box>
    </StyledPrivateMessageModal>
  );
};

PrivateMessageModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  targetRole: PropTypes.string,
  placeholder: PropTypes.string,
};
