import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { Roles } from 'constants/index';
import { t } from 'utils/i18n';
import { SendButton, CancelButton } from '../../../common/MessageButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.privateMessageButton.${key}`,
    '',
    params
  );
};

const messageMaxLength = 512;

const StyledPrivateMessageModal = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
`;

const ContentTextarea = styled.textarea`
  width: 100%;
  height: 160px;
  padding: 16px;
  border-radius: 12px;
  outline: none;
  border: 1px solid #919eab52;
  resize: none;
  &:focus {
    border: 1px solid #ec7963;
  }
`;

const Tip = styled.div`
  margin-top: 4px;
  text-align: right;
  font-size: 12px;
  color: #8a94a6;
`;

const StyledSendButton = styled(SendButton)`
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PrivateMessageContent = ({
  className,
  onClose,
  targetRole,
  placeholder,
  messageValue,
  setMessageValue,
  sendHandler,
}) => {
  const changeHandler = (e) => {
    if (messageValue.length > messageMaxLength) return;
    const textAreaValue = e.target.value;
    setMessageValue(textAreaValue);
  };

  const enterHandler = (evt) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode === 13) {
      sendHandler();
    }
  };

  return (
    <StyledPrivateMessageModal
      className={className}
      data-testid='PrivateMessageModal'
    >
      <>
        <ContentTextarea
          placeholder={placeholder}
          maxLength={messageMaxLength}
          onChange={changeHandler}
          onKeyDown={enterHandler}
          autoFocus
          value={messageValue}
        ></ContentTextarea>
        <Tip>
          {trans('__worldCount', {
            length: messageValue.length,
            maxLength: messageMaxLength,
          })}
        </Tip>
      </>
    </StyledPrivateMessageModal>
  );
};

PrivateMessageContent.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  targetRole: PropTypes.string,
  placeholder: PropTypes.string,
  messageValue: PropTypes.string,
  setMessageValue: PropTypes.func,
  sendHandler: PropTypes.func,
};

export const PrivateMessageButton = ({ advisorIsExist, currentRole }) => {
  const { isFooterOpen } = useFooterStatus();
  const [privateMessageModalState, setPrivateMessageModalState] =
    useState(false);
  const [messageValue, setMessageValue] = useState('');
  const { sendPrivateMessage } = useChatMessage();

  const onPrivateMessageOpen = () => setPrivateMessageModalState(true);
  const onPrivateMessageClose = () => setPrivateMessageModalState(false);

  const sendHandler = () => {
    const roleToSend =
      currentRole === Roles.Advisor ? Roles.Teacher : Roles.Advisor;
    const trimmedMessage = messageValue.trim();

    sendPrivateMessage(trimmedMessage, roleToSend);
    setMessageValue('');
    onPrivateMessageClose();
  };

  useEffect(() => {
    return () => {
      if (!advisorIsExist) {
        onPrivateMessageClose();
      }
    };
  }, [advisorIsExist]);

  return (
    <>
      <Tooltip
        title={
          currentRole === Roles.Advisor
            ? trans('__privateMessageTeacher')
            : trans('__privateMessagingConsultant')
        }
      >
        <div>
          <ToolButton
            onClick={onPrivateMessageOpen}
            icon={<Icon name='WalkieTalkie' size='md' color='#649DFF' />}
          ></ToolButton>
        </div>
      </Tooltip>
      <ToolModal
        active={privateMessageModalState}
        onClose={onPrivateMessageClose}
        title={t('mainPage.teacher.syncSingle.promptMessage', '提示訊息')}
        width='600px'
        height='380px'
        footerHeight='100px'
        zIndex={1000}
        footerContent={
          <ButtonContainer>
            <CancelButton onClick={onPrivateMessageClose}>
              {trans('__cancel')}
            </CancelButton>
            <StyledSendButton
              type='primary'
              disabled={messageValue.length === 0}
              onClick={sendHandler}
            >
              {trans('__send')}
            </StyledSendButton>
          </ButtonContainer>
        }
      >
        <PrivateMessageContent
          onClose={onPrivateMessageClose}
          targetRole={Roles.Advisor}
          placeholder={
            currentRole === Roles.Advisor
              ? trans('__enterPromptMessagePlaceholder')
              : trans('__enterPromptMessagePlaceholderHere')
          }
          messageValue={messageValue}
          setMessageValue={setMessageValue}
          sendHandler={sendHandler}
        />
      </ToolModal>
    </>
  );
};

PrivateMessageButton.propTypes = {
  advisorIsExist: PropTypes.bool,
  currentRole: PropTypes.string,
};
