import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  FormField,
  Input,
  Select,
  PrimaryButton,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingDispatch } from '@oneboard/meeting';
import { StyledLoginPage } from './LoginPage.style';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`views.loginPage.${key}`, '', params);
};

export const LoginPage = ({ className }) => {
  const location = useLocation();
  const history = useHistory();
  const { joinMeeting } = useMeetingDispatch();

  const [roomId, setRoomId] = useState('');
  const [userName, setUserName] = useState('');
  const [role, setRole] = useState('student');

  const submitHandler = async (e) => {
    e.preventDefault();
    await joinMeeting({
      roomId,
      userName,
      role,
    });
    history.push({
      pathname: `${role}/setup`,
      search: location.search,
    });
  };

  return (
    <StyledLoginPage className={className} data-testid="LoginPage">
      <form onSubmit={submitHandler}>
        <FormField
          field={Input}
          label={trans('__enterRoomName')}
          value={roomId}
          fieldProps={{ name: 'firstName', placeholder: 'ex: MyEnglishClass' }}
          onChange={(e) => setRoomId(e.target.value)}
          layout="stack"
        />
        <FormField
          field={Input}
          label={trans('__enterUsername')}
          value={userName}
          fieldProps={{
            name: 'firstName',
            placeholder: t(
              'views.loginPage.placeholder.exampleName',
              'ex: 王小明'
            ),
          }}
          onChange={(e) => setUserName(e.target.value)}
          layout="stack"
        />
        <FormField
          field={Select}
          label={trans('__pleaseSelectIdentity')}
          value={role}
          fieldProps={{
            name: 'role',
            options: [
              { value: 'student', label: trans('__student') },
              { value: 'teacher', label: trans('__teacher') },
            ],
          }}
          onChange={(e) => setRole(e.target.value)}
          layout="stack"
        />
        <PrimaryButton label={trans('__enterTheCourse')} type="submit" />
      </form>
    </StyledLoginPage>
  );
};
