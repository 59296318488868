const queryString = require('query-string');
import React, { createContext, useContext, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useMachine } from '@xstate/react';
import { t } from "utils/i18n";
import { ThemeProvider } from 'styled-components';
import {
  VoiceFocusProvider,
  MeetingProvider as ChimeMeetingProvider,
  darkTheme as ChimeDarkTheme,
  useMeetingManager,
  useAudioVideo,
  useToggleLocalMute,
  useContentShareControls,
  useContentShareState,
  useVideoInputs,
  useLocalVideo,
  DeviceLabels,
  useAudioInputs,
  LoggerProvider,
  LocalVideoProvider,
  BackgroundBlurProvider,
  useBackgroundBlur,
  BackgroundReplacementProvider,
  useBackgroundReplacement,
  useLogger,
} from 'amazon-chime-sdk-component-library-react';
import {
  LogLevel,
  ConsoleLogger,
  MeetingSessionConfiguration,
  POSTLogger,
  isVideoTransformDevice,
} from 'amazon-chime-sdk-js';
import { message } from 'antd';
import { shortId } from 'utils';
import { useQuery } from 'utils/hooks/useQuery';
import { machine, ActionTypes, Roles, BreakoutRoomActionTypes } from './machine';
import { getRandomColor } from 'utils/color';
import { useNotification } from 'utils/hooks/useNotification';
import { NOTIFICATION_TYPE, NOTIFICATION_TYPE_KEY } from 'constants/index';
import { Modal, Button, Box } from '@oneboard/ui-components';
import {
  useMeetingModalContext,
  MeetingModalProvider,
  useRedirectRoomModalContext,
  RedirectRoomModalProvider,
} from 'providers/MeetingModalProvider';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { useCheckDevice } from 'utils/hooks/useCheckDevice';
import { SocketProvider } from './SocketProvider';
import { LocalToolboxAuthProvider, useLocalToolboxAuth } from '../LocalToolboxAuthProvider';
import { MeetingFailurePage } from 'components/MeetingFailurePage/MeetingFailurePage';

// disabled for issue #8903
// import { HandposeProcessor } from '../../processor/HandposeProcessor';
import { sendUserEvent, plusReward } from '../../services/userRecord';

const SelectedVideoDeviceStorageKey = 'SelectedVideoDevice';

const darkTheme = {
  ...ChimeDarkTheme,
  inputs: {
    ...ChimeDarkTheme.inputs,
    border: '1px solid #33353A',
  },
};

const StateContext = createContext();
const DispatchContext = createContext(() => {});

const defaultConfig = {
  PROJECT_NAME: '',
  CHIME_API_URL: '',
  LOG_URL: '',
};

const DATA_MESSAGE_TOPIC_FROM_TEACHER = 'meetingEventFromTeacher';
const DATA_MESSAGE_TOPIC_FROM_STUDENT = 'meetingEventFromStudent';
const DATA_MESSAGE_TOPIC_FROM_ADVISOR = 'meetingEventFromAdvisor';
const DATA_MESSAGE_TOPIC_TO_ALL = 'meetingEventToAll';
const DATA_MESSAGE_LIFETIME_MS = 10000;

export const Provider = ({ children, config }) => {
  const trans = (key, params) => {
    return t(`packages.meeting.providers.meetingProvider.${key}`, "", params);
  };
  const query = useQuery();
  const meetingManager = useMeetingManager();
  const meetingId = meetingManager.meetingId;
  const audioVideo = useAudioVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const mutedRef = useRef(muted);
  const { isVideoEnabled, toggleVideo, tileId } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { isLocalUserSharing } = useContentShareState();
  const { openNotification } = useNotification();
  const { openModal, meetingModalState } = useMeetingModalContext();
  const {
    closeModal: redirectRoomModalClose,
    meetingModalState: redirectRoomModalState,
    redirect,
    redirectUrl,
  } = useRedirectRoomModalContext();
  const { selectedDevice: deviceId, devices: videoDevices } = useVideoInputs();
  const { devices: audioDevices } = useAudioInputs();
  const { createBackgroundBlurDevice } = useBackgroundBlur();
  const { createBackgroundReplacementDevice } = useBackgroundReplacement();
  const logger = useLogger();
  const { allNotCheck } = useCheckDevice();
  const { isToolboxEnabled, toggleLocalToolboxAuth } = useLocalToolboxAuth();

  const joinMeetingService = async ({ roomId, userName, role, userId }) => {
    if (!roomId) {
      alert('join meeting error');
      return;
    }
    try {
      const url = `${config.CHIME_API_URL}/join`;
      const body = JSON.stringify({
        courseId: roomId,
        role,
        userId,
        userName,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });

      const { data } = await response.json();

      const meetingSessionConfiguration = new MeetingSessionConfiguration(data.meeting, data.attendee);

      logger.metadata.meetingId = data.meeting.MeetingId;
      logger.metadata.attendeeId = data.attendee.AttendeeId;
      let hasDevice = false;
      let options = {
        deviceLabels: DeviceLabels.AudioAndVideo,
      };

      if (allNotCheck) {
        if (!navigator.mediaDevices?.enumerateDevices) {
          console.log('enumerateDevices() not supported.');
        } else {
          await navigator.mediaDevices
            .enumerateDevices()
            .then(function (devices) {
              const mainDevices = devices.map((device) => {
                return device.kind === 'videoinput' || device.kind === 'audioinput';
              });
              if (mainDevices.length > 0) {
                hasDevice = true;
              }
              options = {
                deviceLabels: hasDevice ? DeviceLabels.AudioAndVideo : DeviceLabels.None,
              };
            })
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
            });
        }
      }

      await meetingManager.join(meetingSessionConfiguration, options);

      return data;
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    mutedRef.current = muted;
  }, [muted]);

  const enableWhiteboardWritableHandler = () => {
    if (!isToolboxEnabled) toggleLocalToolboxAuth();
  };

  const disableWhiteboardWritableHandler = () => {
    if (isToolboxEnabled) toggleLocalToolboxAuth();
  };

  const muteHandler = () => {
    if (!mutedRef.current) toggleMute();
  };

  const unMuteHandler = () => {
    if (mutedRef.current) toggleMute();
  };

  const openVideoHandler = () => {
    if (!isVideoEnabled) toggleVideo();
  };

  const closeVideoHandler = () => {
    if (isVideoEnabled) toggleVideo();
  };

  const noFocusHandler = (ctx, evt) => {
    const userName = evt.payload.userName;
    const key = evt.payload.key;

    openNotification({
      name: userName,
      key: NOTIFICATION_TYPE_KEY.NO_FOCUS,
      type: NOTIFICATION_TYPE.NO_FOCUS,
    });
  };

  const openSharingScreenHandler = () => {
    if (!isLocalUserSharing) {
      toggleContentShare();
    }
  };

  const closeSharingScreenHandler = () => {
    if (isLocalUserSharing) {
      toggleContentShare();
    }
  };

  const raiseHandAction = (attendeeId) => () => {
    openNotification({
      name: '你',
      key: NOTIFICATION_TYPE_KEY.HAND_POSE_RAISED_HAND,
      type: NOTIFICATION_TYPE.HAND_POSE_RAISED_HAND,
    });
    send({
      type: ActionTypes.RaiseHand,
      payload: {
        attendeeId,
      },
    });
  };

  const generateCallback = (role, action) => {
    return role === Roles.Student ? action : () => {};
  };

  // const isOneByOneCourse = courseType => {
  //   return courseType.indexOf('single') > -1
  // }

  const videoBackgroundImageEffectHandler = async (ctx, evt) => {
    if (videoFilter === 'Replacement') return;

    try {
      let current = deviceId;

      if (videoFilter === null && !isVideoTransformDevice(deviceId)) {
        current = await createBackgroundReplacementDevice(deviceId);
      }

      if (videoFilter !== null && isVideoTransformDevice(deviceId)) {
        let intrinsicDevice = await deviceId.intrinsicDevice();
        deviceId.stop();
        current = await createBackgroundReplacementDevice(intrinsicDevice);
      }

      await meetingManager.startVideoInputDevice(current);
    } catch (error) {
      // Handle device selection failure here
      console.error('Failed to toggle BackgroundReplacement');
    }
  };

  const videoBlurBackgroundEffectHandler = async (ctx, evt) => {
    if (videoFilter === 'Blur') return;

    try {
      let current = deviceId;
      if (videoFilter === null && !isVideoTransformDevice(deviceId)) {
        current = await createBackgroundBlurDevice(deviceId);
      }

      if (videoFilter !== null && isVideoTransformDevice(deviceId)) {
        let intrinsicDevice = await deviceId.intrinsicDevice();
        deviceId.stop();
        current = await createBackgroundBlurDevice(intrinsicDevice);
      }

      await meetingManager.startVideoInputDevice(current);
    } catch (error) {
      // Handle device selection failure here
      console.error('Failed to toggle Background Blur');
    }
  };

  const clearVideoBackgroundEffectHandler = async (ctx, evt) => {
    try {
      let current = deviceId;
      if (isVideoTransformDevice(deviceId)) {
        let intrinsicDevice = await deviceId.intrinsicDevice();
        deviceId.stop();
        current = intrinsicDevice;
      }

      await meetingManager.startVideoInputDevice(current);
    } catch (error) {
      // Handle device selection failure here
      console.error('Failed to clear Background');
    }
  };

  const plusRewardUserEvent = async (ctx, evt) => {
    const { userId, studentName } = evt.payload;
    const { userName, role, userId: presenterId, courseType } = ctx;

    const res = await sendUserEvent({
      courseId: ctx.roomId,
      userName,
      eventName: 'trophy',
      userId,
      role,
    });

    if (courseType === 'single' || courseType === 'group') return;

    if (res.status === 'success' && !!studentName) {
      await plusReward({
        courseId: ctx.roomId,
        presenterId,
        studentName,
        role,
      });
    }
  };

  const plusRaiseHandUserEvent = async (ctx, evt) => {
    const { userId, roomId, userName } = ctx;
    sendUserEvent({
      courseId: roomId,
      userId,
      userName,
      eventName: 'raiseHand',
    });
  };

  const plusInattentiveUserEvent = async (ctx) => {
    const { userId, roomId, userName } = ctx;
    sendUserEvent({
      courseId: roomId,
      userId,
      userName,
      eventName: 'inattentive',
    });
  };

  const [state, send] = useMachine(machine, {
    context: {
      config,
      courseType: query.classType,
    },
    actions: {
      muteHandler,
      unMuteHandler,
      openVideoHandler,
      closeVideoHandler,
      noFocusHandler,
      openSharingScreenHandler,
      closeSharingScreenHandler,
      clearVideoBackgroundEffectHandler,
      videoBackgroundImageEffectHandler,
      videoBlurBackgroundEffectHandler,
      plusRewardUserEvent,
      plusRaiseHandUserEvent,
      plusInattentiveUserEvent,
      enableWhiteboardWritableHandler,
      disableWhiteboardWritableHandler,
      broadcastEvent: (ctx, evt) => {
        let topic = '';

        switch (ctx.role) {
          case Roles.Student:
            topic = DATA_MESSAGE_TOPIC_FROM_STUDENT;
            break;
          case Roles.Advisor:
            topic = DATA_MESSAGE_TOPIC_FROM_ADVISOR;
            break;
          default:
            topic = DATA_MESSAGE_TOPIC_FROM_TEACHER;
            break;
        }

        audioVideo?.realtimeSendDataMessage(topic, evt, DATA_MESSAGE_LIFETIME_MS);
      },
      broadcastEventToAll: (ctx, evt) => {
        const topic = DATA_MESSAGE_TOPIC_TO_ALL;
        audioVideo?.realtimeSendDataMessage(topic, evt, DATA_MESSAGE_LIFETIME_MS);
      },
    },
    services: {
      joinMeeting: async (ctx, evt) => {
        return joinMeetingService(evt.payload);
      },
    },
  });

  const {
    context: { role, videoFilter },
  } = state;

  useEffect(() => {
    if (!role || !audioVideo) return;

    const handler = (dataMessage) => {
      const data = dataMessage.json();
      send(data);
    };

    if (role === Roles.Advisor) {
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_TEACHER, handler);
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_STUDENT, handler);
    } else if (role === Roles.Student || role === Roles.Observer) {
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_TEACHER, handler);
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_ADVISOR, handler);
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_TO_ALL, handler);
    } else {
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_ADVISOR, handler);
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_STUDENT, handler);
      audioVideo.realtimeSubscribeToReceiveDataMessage(DATA_MESSAGE_TOPIC_TO_ALL, handler);
    }

    return () => {
      if (role === Roles.Advisor) {
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_TEACHER);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_STUDENT);
      } else if (role === Roles.Student || role === Roles.Observer) {
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_TEACHER);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_ADVISOR);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_TO_ALL);
      } else {
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_STUDENT);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_FROM_ADVISOR);
        audioVideo.realtimeUnsubscribeFromReceiveDataMessage(DATA_MESSAGE_TOPIC_TO_ALL);
      }
    };
  }, [role, audioVideo]);

  useEffect(() => {
    if (!meetingId) return;
    meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
      const url = `${config.CHIME_API_URL}/get-member`;
      const body = JSON.stringify({
        courseId: roomId,
        userId: externalUserId,
      });
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      });

      const user = await response.json();
      const { data } = user;
      const color = getRandomColor({ saturation: 38, lightness: 59 });

      return {
        name: data.userName,
        role: data.role,
        userId: data.userId,
        color,
      };
    };
  }, [meetingId]);

  const processMessionEnd = (statusCode) => {
    switch (statusCode) {
      case 2:
        message.error(trans("__accountInUse"), 0);
        break;
      case 6:
        message.error(trans("__classEnded"), 0);
        break;
      case 18: //連線逾時中斷
      case 19: //不明原因中斷
        openModal();
        send({
          type: ActionTypes.Join,
          payload: {
            roomId: meetingId,
            userName: query.userName,
            role: query.role,
            userId: query.userId,
          },
        });
        break;
    }
  };

  const { sendMessage } = useChatMessage();

  const [deviceReconnecting, setDeviceReconnecting] = useState(false);
  const selectVideoDevice = async (deviceId) => {
    if (!deviceId) return;
    await meetingManager.startVideoInputDevice(deviceId);
    if (isVideoEnabled) {
      audioVideo.startLocalVideoTile();
    }
  };

  useEffect(() => {
    if (!deviceId) return;

    const isString = typeof deviceId === 'string';
    if (isString) {
      localStorage.setItem(SelectedVideoDeviceStorageKey, deviceId);
    } else {
      // 背景模糊 or 背景更換的時候 deviceId 物件型別
      localStorage.setItem(SelectedVideoDeviceStorageKey, deviceId.device);
    }
  }, [deviceId]);

  useEffect(() => {
    const selectedDeviceId = localStorage.getItem(SelectedVideoDeviceStorageKey);
    selectVideoDevice(selectedDeviceId);
  }, []);

  const reselectVideoInputDevice = async () => {
    const defaultDeviceId = videoDevices[0]?.deviceId;
    if (defaultDeviceId) {
      selectVideoDevice(defaultDeviceId);
    } else {
      message.warn(trans("__videoCameraFailed"));
    }
  };

  const reselectAudioInputDevice = async () => {
    const defaultDeviceId = audioDevices[0]?.deviceId;
    if (defaultDeviceId) {
      await meetingManager.startAudioInputDevice(defaultDeviceId);
    } else {
      message.warn(trans("__microphoneFailed"));
    }
  };

  useEffect(() => {
    if (!deviceReconnecting) return;
    reselectVideoInputDevice();
    reselectAudioInputDevice();
    setDeviceReconnecting(false);
  }, [deviceReconnecting]);

  const createSystemMessage = (msg) => `${trans("__systemMessage")}：${msg}`;

  const createErrorMessage = (meetingStatus) => {
    let msg = '';
    switch (meetingStatus) {
      case "AudioCallAtCapacity":
        msg = trans("__connectionLimitExceeded");
        break;
      case "MeetingEnded":
        msg = trans("__courseEnded");
        break;
      case "SignalingBadRequest":
        msg = trans("__meetingEnded");
        break;
      case "TaskFailed":
        msg = trans("__poorConnectionQuality");
        break;
      default:
        msg = trans("__unknownError");
        break;
    }

    return createSystemMessage(msg);
  };

  useEffect(() => {
    if (!audioVideo || !tileId) return;

    const observer = {
      audioVideoDidStart: () => {
        message.success(createSystemMessage(trans("__online")));
      },
      audioVideoDidStartConnecting: (reconnecting) => {
        if (reconnecting) {
          const msg = trans("__reconnecting");
          message.info(msg);
          sendMessage(msg);
          audioVideo.unpauseVideoTile(tileId);
        } else {
          message.info(trans("__connecting"));
        }
      },
      audioVideoDidStop: (sessionStatus) => {
        const sessionStatusCode = sessionStatus.statusCode();
        const msg = createSystemMessage(trans("__connectionInterrupted"));
        message.error(msg);
        sendMessage(msg);
        openModal();
        processMessionEnd(sessionStatusCode);
        meetingManager.leave();
      },
      connectionDidBecomeGood: () => {
        message.warn(createSystemMessage(trans("__normalConnectionQuality")));
        audioVideo.unpauseVideoTile(tileId);
      },
      connectionDidBecomePoor: () => {
        const msg = createSystemMessage(trans("__poorConnectionQuality"));
        message.warn(msg);
        sendMessage(msg);
      },
      connectionDidSuggestStopVideo: () => {
        const msg = createSystemMessage(
          trans("__closeVideoDueToPoorConnection")
        );
        message.error(msg);
        sendMessage(msg);
        audioVideo.pauseVideoTile(tileId);
      },
      videoAvailabilityDidChange: (videoAvailability) => {
        if (!videoAvailability.canStartLocalVideo) {
          const msg = createSystemMessage(
            trans("__opponentCannotSeeYourVideo")
          );
          message.error(msg);
          sendMessage(msg);
          audioVideo.pauseVideoTile(tileId);
        }
      },
      eventDidReceive: (name, attributes) => {
        switch (name) {
          case 'audioInputFailed':
          case 'videoInputFailed':
            setDeviceReconnecting(true);
            break;
          case 'meetingStartFailed':
          case 'meetingFailed':
            const { meetingStatus } = attributes;
            message.warn(createErrorMessage(meetingStatus));
            break;
          default:
            break;
        }
      },
      videoTileDidUpdate: (tileState) => {
        if (tileState.localTile) {
          if (tileState.paused) {
            send({
              type: ActionTypes.PausedTile,
            });
          } else {
            send({
              type: ActionTypes.ActiveTile,
            });
          }
        }
      },
    };

    audioVideo.addObserver(observer);
  }, [audioVideo, tileId]);

  const {
    context: { userId, roomId },
  } = state;

  return (
    <>
      {userId && roomId && <SocketProvider roomId={roomId} userId={userId} send={send} />}
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={send}>{children}</DispatchContext.Provider>
      </StateContext.Provider>
      {meetingModalState && <MeetingFailurePage />}

      {redirectRoomModalState && redirectUrl && role === Roles.Student && (
        <Modal header={trans("__notification")} onClose={redirectRoomModalClose}>
          {trans("__confirmEnterCollaborationRoom")}
          <Box display='flex' justifyContent='flex-end' mt={5}>
            <Button onClick={redirect}>{trans("__confirm")}</Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export const MeetingProvider = ({ children, config = defaultConfig }) => {
  const [bgReplacementOptions, setBgReplacementOptions] = useState();
  const searchParams = new URLSearchParams(window.location.href);
  const role = searchParams.get('role');

  const postLogger = new POSTLogger({
    batchSize: 10,
    intervalMs: 5000,
    url: config.LOG_URL,
    logLevel: LogLevel.WARN,
    metadata: {
      appName: config.PROJECT_NAME,
    },
  });

  const bgBlurOptions = {
    blurStrength: 15,
    logger: new ConsoleLogger('BackgroundBlurProvider', LogLevel.WARN),
    reportingPeriodMillis: 1000,
    filterCPUUtilization: 30,
  };

  const initialize = async () => {
    let options = {
      logger: new ConsoleLogger('BackgroundReplacementProcessor', LogLevel.WARN),
      reportingPeriodMillis: 1000,
      filterCPUUtilization: 30,
    };

    if (role !== Roles.Observer) {
      const imageBlob = await fetch('/images/video-background.svg')
        .then((res) => res.blob())
        .catch((err) => console.log('err:', err.message));
      options = {
        ...options,
        imageBlob,
      };
    }

    setBgReplacementOptions(options);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <LoggerProvider logger={postLogger}>
      <LocalToolboxAuthProvider>
        <ThemeProvider theme={darkTheme}>
          {bgReplacementOptions && (
            <BackgroundBlurProvider options={bgBlurOptions}>
              <BackgroundReplacementProvider options={bgReplacementOptions}>
                <VoiceFocusProvider>
                  <MeetingModalProvider>
                    <RedirectRoomModalProvider>
                      <ChimeMeetingProvider>
                        <LocalVideoProvider>
                          <Provider config={config}>{children}</Provider>
                        </LocalVideoProvider>
                      </ChimeMeetingProvider>
                    </RedirectRoomModalProvider>
                  </MeetingModalProvider>
                </VoiceFocusProvider>
              </BackgroundReplacementProvider>
            </BackgroundBlurProvider>
          )}
        </ThemeProvider>
      </LocalToolboxAuthProvider>
    </LoggerProvider>
  );
};

export const useMeetingState = () => useContext(StateContext);

export const useMeetingDispatch = () => {
  const dispatch = useContext(DispatchContext);

  const joinMeeting = useCallback(({ roomId, userName, role, userId }) => {
    dispatch({
      type: ActionTypes.Join,
      payload: {
        roomId,
        userName,
        role,
        userId,
      },
    });
  }, []);

  const stageAttendee = useCallback(({ attendeeId, userId }) => {
    dispatch({
      type: ActionTypes.OnStage,
      payload: {
        attendeeId,
        userId,
      },
    });
  }, []);

  const unstageAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.Unstage,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const disableChat = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.DisableChat,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const enableChat = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.EnableChat,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const stageAttendeeFromAdvisor = useCallback(({ attendeeId, userId }) => {
    dispatch({
      type: ActionTypes.OnStageFromAdvisor,
      payload: {
        attendeeId,
        userId,
      },
    });
  }, []);

  const unstageAttendeeFromAdvisor = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.UnstageFromAdvisor,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const updateStaged = useCallback(({ attendeeId, stagedAttendeeIds }) => {
    dispatch({
      type: ActionTypes.UpdateStaged,
      payload: {
        attendeeId,
        stagedAttendeeIds,
      },
    });
  }, []);

  const updateAppStaged = useCallback(({ stagedAttendeeIds }) => {
    dispatch({
      type: ActionTypes.UpdateAppStaged,
      payload: {
        stagedAttendeeIds,
      },
    });
  }, []);

  const clearStageAttendee = useCallback(() => {
    dispatch({
      type: ActionTypes.ClearStage,
    });
  }, []);

  const raiseHand = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.RaiseHand,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const lowerHand = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.LowerHand,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const lowerHandFromTeacher = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.LowerHandFromTeacher,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const lowerHandFromAdvisor = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.LowerHandFromAdvisor,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const lowerAllAttendeesHand = useCallback(() => {
    dispatch({
      type: ActionTypes.LowerAllAttendeesHand,
    });
  }, []);

  const lowerAllAttendeesHandFromAdvisor = useCallback(() => {
    dispatch({
      type: ActionTypes.LowerAllAttendeesHandFromAdvisor,
    });
  }, []);

  const muteAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.MuteAttendee,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const unMuteAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.UnMuteAttendee,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const muteAttendeeFromAdvisor = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.MuteAttendeeFromAdvisor,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const unMuteAttendeeFromAdvisor = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.UnMuteAttendeeFromAdvisor,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const muteAllAttendees = useCallback(() => {
    dispatch({
      type: ActionTypes.MuteAllAttendees,
    });
  }, []);

  const unMuteAllAttendees = useCallback(() => {
    dispatch({
      type: ActionTypes.UnMuteAllAttendees,
    });
  }, []);

  const startWhiteboard = useCallback(({ attendeeIds }) => {
    dispatch({
      type: ActionTypes.StartWhiteboard,
      payload: {
        attendeeIds,
      },
    });
  }, []);

  const endWhiteboard = useCallback(() => {
    dispatch({
      type: ActionTypes.EndWhiteboard,
    });
  }, []);

  const startAllWhiteboard = useCallback(() => {
    dispatch({
      type: ActionTypes.StartAllWhiteboard,
    });
  }, []);

  const sendReward = useCallback(({ attendeeId, userId, studentName }) => {
    dispatch({
      type: ActionTypes.SendReward,
      payload: {
        attendeeId,
        userId,
        studentName,
      },
    });
  }, []);

  const pauseReward = useCallback(({ userId }) => {
    dispatch({
      type: ActionTypes.PauseReward,
      payload: {
        userId,
      },
    });
  }, []);

  const updateReward = useCallback(({ userId, studentName }) => {
    dispatch({
      type: ActionTypes.UpdateReward,
      payload: {
        userId,
        studentName,
      },
    });
  }, []);

  const sendRewardFromAdvisor = useCallback(({ attendeeId, userId }) => {
    dispatch({
      type: ActionTypes.SendRewardFromAdvisor,
      payload: {
        attendeeId,
        userId,
      },
    });
  }, []);

  const pauseRewardFromAdvisor = useCallback(({ userId }) => {
    dispatch({
      type: ActionTypes.PauseRewardFromAdvisor,
      payload: {
        userId,
      },
    });
  }, []);

  const updateRewardFromAdvisor = useCallback(({ userId, studentName }) => {
    dispatch({
      type: ActionTypes.UpdateRewardFromAdvisor,
      payload: {
        userId,
        studentName,
      },
    });
  }, []);

  const openVideoAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.OpenVideoAttendee,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const closeVideoAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.CloseVideoAttendee,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const openVideoAttendeeFromAdvisor = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.OpenVideoAttendeeFromAdvisor,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const closeVideoAttendeeFromAdvisor = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.CloseVideoAttendeeFromAdvisor,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const noFocus = useCallback(({ userName, key }) => {
    dispatch({
      type: ActionTypes.NoFocus,
      payload: {
        userName,
        key,
      },
    });
  }, []);

  const toggleVideoIsMirroring = useCallback(({ videoIsMirroring }) => {
    dispatch({
      type: ActionTypes.VideoIsMirroring,
      payload: {
        videoIsMirroring,
      },
    });
  }, []);

  const videoBlurBackgroundEffect = useCallback(() => {
    dispatch({
      type: ActionTypes.VideoBlurBackgroundEffect,
    });
  }, []);

  const videoBackgroundImageEffect = useCallback(() => {
    dispatch({
      type: ActionTypes.VideoBackgroundImageEffect,
    });
  });

  const clearVideoBackgroundEffect = useCallback(() => {
    dispatch({
      type: ActionTypes.ClearVideoBackgroundEffect,
    });
  }, []);

  const setWorldWall = useCallback(({ url }) => {
    dispatch({
      type: ActionTypes.SetWorldWall,
      payload: {
        switch: true,
        url,
      },
    });
  }, []);

  const closeWorldWall = useCallback(() => {
    dispatch({
      type: ActionTypes.SetWorldWall,
      payload: {
        switch: false,
        url: '',
      },
    });
  }, []);

  const enableToolbox = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.EnableToolbox,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const disableToolbox = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.DisableToolbox,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const advisorEnableToolbox = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.AdvisorEnableToolbox,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const advisorDisableToolbox = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.AdvisorDisableToolbox,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const updateToolbox = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.UpdateToolbox,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const addToolboxAuthAttendeeIds = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.AddToolboxAuthAttendeeIds,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const removeToolboxAuthAttendeeIds = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.RemoveToolboxAuthAttendeeIds,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const breakoutMeetingInvite = useCallback(() => {
    dispatch({
      type: ActionTypes.BreakoutMeetingInvite,
    });
  }, []);

  const breakoutMeetingLeave = useCallback(() => {
    dispatch({
      type: ActionTypes.BreakoutMeetingLeave,
    });
  }, []);

  const breakoutMeetingEnd = useCallback(() => {
    dispatch({
      type: ActionTypes.BreakoutMeetingEnd,
    });
  }, []);

  const callTeacherGroups = useCallback(({ data }) => {
    dispatch({
      type: ActionTypes.CallTeacherGroups,
      payload: {
        data,
      },
    });
  }, []);

  const removeCallTeacherGroups = useCallback(({ breakoutRoomId }) => {
    dispatch({
      type: ActionTypes.RemoveCallTeacherGroups,
      payload: {
        breakoutRoomId,
      },
    });
  }, []);

  const removeAllCallTeacherGroups = useCallback(() => {
    dispatch({
      type: ActionTypes.RemoveAllCallTeacherGroups,
    });
  }, []);
  const objectedAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.OnObject,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const unObjectedAttendee = useCallback(({ attendeeId }) => {
    dispatch({
      type: ActionTypes.UnObject,
      payload: {
        attendeeId,
      },
    });
  }, []);

  const assignMultiUserRewards = useCallback(({ rewardsMap }) => {
    dispatch({
      type: ActionTypes.AssignMultiUserRewards,
      payload: {
        rewardsMap,
      },
    });
  }, []);

  const closeBroadcastModal = useCallback(() => {
    dispatch({
      type: ActionTypes.CloseBroadcastModal,
    });
  }, []);

  const updateVideoList = useCallback(({ videoList }) => {
    dispatch({
      type: ActionTypes.UpdateVideoList,
      payload: {
        videoList,
      },
    });
  }, []);

  const addOrderRosters = useCallback(({ roster }) => {
    dispatch({
      type: ActionTypes.AddOrderRosters,
      payload: {
        roster,
      },
    });
  }, []);

  const removeOrderRosters = useCallback(({ chimeAttendeeId }) => {
    dispatch({
      type: ActionTypes.RemoveOrderRosters,
      payload: {
        chimeAttendeeId,
      },
    });
  }, []);

  const updateOrderRosters = useCallback(({ orderRosters }) => {
    dispatch({
      type: ActionTypes.UpdateOrderRosters,
      payload: {
        orderRosters,
      },
    });
  }, []);

  const getSessionInfoSuccess = useCallback(({ sessionInfo }) => {
    dispatch({
      type: ActionTypes.GetSessionInfoSuccess,
      payload: {
        sessionInfo,
      },
    });
  }, []);

  const getSessionInfoFailure = useCallback(() => {
    dispatch({
      type: ActionTypes.GetSessionInfoFailure,
    });
  }, []);

  const getGroupInfo = useCallback(({ groupInfo }) => {
    dispatch({
      type: ActionTypes.GetGroupInfo,
      payload: {
        groupInfo,
      },
    });
  }, []);

  const initRoomState = useCallback(({ attendeeId, stagedAttendeeIds, rewards }) => {
    dispatch({
      type: ActionTypes.InitRoomState,
      payload: {
        attendeeId,
        stagedAttendeeIds,
        rewards,
      },
    });
  }, []);

  const teacherStartRecord = useCallback(() => {
    dispatch({
      type: ActionTypes.TeacherStartRecord,
    });
  }, []);

  const teacherStopRecord = useCallback(() => {
    dispatch({
      type: ActionTypes.TeacherStopRecord,
    });
  }, []);

  const advisorStartRecord = useCallback(() => {
    dispatch({
      type: ActionTypes.AdvisorStartRecord,
    });
  }, []);

  const advisorStopRecord = useCallback(() => {
    dispatch({
      type: ActionTypes.AdvisorStopRecord,
    });
  }, []);

  const observerStopRecord = useCallback(() => {
    dispatch({
      type: ActionTypes.ObserverStopRecord,
    });
  }, []);

  const openClassroomPerformance = useCallback(({ classroomPerformance }) => {
    dispatch({
      type: ActionTypes.OpenClassroomPerformance,
      payload: {
        classroomPerformance,
      },
    });
  }, []);

  const closeClassroomPerformance = useCallback(() => {
    dispatch({
      type: ActionTypes.CloseClassroomPerformance,
    });
  }, []);

  return {
    dispatch,
    joinMeeting,
    stageAttendee,
    unstageAttendee,
    disableChat,
    enableChat,
    stageAttendeeFromAdvisor,
    unstageAttendeeFromAdvisor,
    updateStaged,
    updateAppStaged,
    clearStageAttendee,
    raiseHand,
    lowerHand,
    lowerHandFromTeacher,
    lowerHandFromAdvisor,
    lowerAllAttendeesHand,
    lowerAllAttendeesHandFromAdvisor,
    muteAttendee,
    unMuteAttendee,
    muteAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
    muteAllAttendees,
    unMuteAllAttendees,
    startWhiteboard,
    startAllWhiteboard,
    endWhiteboard,
    sendReward,
    pauseReward,
    updateReward,
    sendRewardFromAdvisor,
    pauseRewardFromAdvisor,
    updateRewardFromAdvisor,
    openVideoAttendee,
    closeVideoAttendee,
    openVideoAttendeeFromAdvisor,
    closeVideoAttendeeFromAdvisor,
    noFocus,
    toggleVideoIsMirroring,
    videoBlurBackgroundEffect,
    videoBackgroundImageEffect,
    clearVideoBackgroundEffect,
    setWorldWall,
    closeWorldWall,
    enableToolbox,
    disableToolbox,
    advisorEnableToolbox,
    advisorDisableToolbox,
    updateToolbox,
    addToolboxAuthAttendeeIds,
    removeToolboxAuthAttendeeIds,
    breakoutMeetingInvite,
    breakoutMeetingLeave,
    breakoutMeetingEnd,
    callTeacherGroups,
    removeCallTeacherGroups,
    removeAllCallTeacherGroups,
    objectedAttendee,
    unObjectedAttendee,
    assignMultiUserRewards,
    closeBroadcastModal,
    updateVideoList,
    addOrderRosters,
    removeOrderRosters,
    updateOrderRosters,
    getSessionInfoSuccess,
    getSessionInfoFailure,
    getGroupInfo,
    initRoomState,
    teacherStartRecord,
    teacherStopRecord,
    advisorStartRecord,
    advisorStopRecord,
    observerStopRecord,
    openClassroomPerformance,
    closeClassroomPerformance,
  };
};

MeetingProvider.propTypes = {
  children: PropTypes.node,
  config: PropTypes.shape({
    CHIME_API_URL: PropTypes.string,
  }),
};
