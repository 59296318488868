import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {
  useLocalVideo,
  useRosterState,
  useContentShareState,
  useRemoteVideoTileState,
  ContentShare,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { useRoom } from 'providers/RoomProvider';
import Advisor from 'components/NewOneBoard/VideoInfo/Advisor';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
} from '@oneboard/meeting';
import {
  TeachingMaterialContainer,
  VideoListCarouselPanel,
  WorldWallModal,
  BreakoutRoomIntro,
  BreakoutRoomContainer,
  BreakoutRoomResult,
  SyncVideoListV2,
  PrivateMessageModal,
} from 'containers';
import {
  BreakoutRoomNotification,
  TeacherSubRoomNotification,
  BreakoutRoomResultNotification,
} from 'containers/BreakoutRoomNotification';
import MainLayout from 'layouts/MainLayoutV2';
import { SyncMainBlock, ResourceModal, InsertPictureModal } from 'components';
import {
  Roles,
  NOTIFICATION_TYPE,
  NOTIFICATION_TYPE_KEY,
} from 'constants/index';
import { useGroupContext } from 'providers/GroupProvider';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useNotification } from 'utils/hooks/useNotification';
import { useViewMode } from 'utils/hooks/useViewMode';
import { useIframeBridge } from 'utils/hooks/useIframeBridge';
import { usePrevious, useRequest } from 'ahooks';
import { complementarySet } from 'utils/array';
import { Box, Modal, FullButton } from '@oneboard/ui-components';
import { useWhiteboard } from '@oneboard/whiteboard';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useResourceModal } from 'providers/ResourceModalProvider';
import { LoadingOutlined } from '@ant-design/icons';
import {
  StyledSyncMultiple,
  Sidebar,
  BreakoutRoomNotificationLayout,
  ContentShareContainer,
  StyledBroadcastModal,
  StyledSyncVideoList,
} from './SyncMultiple.style';
import Whiteboard from 'components/NewOneBoard/Whiteboard';
import ToolBox from 'components/NewOneBoard/ToolBox';
import Footer from 'components/NewOneBoard/Footer';
import AdvisorContainer from 'components/NewOneBoard/VideoInfo/common/AdvisorContainer';
import OtherVideos from 'components/NewOneBoard/VideoInfo/common/WhiteboardVideo';
import { useInteractiveObjects } from 'utils/hooks/useInteractiveObjects';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`views.mainPage.advisor.syncMultipleV2.${key}`, '', params);
};

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const BottomContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const SyncMultiple = () => {
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const meetingState = useMeetingState();
  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const { role, courseType } = meetingState.context;
  const {
    callTeacherGroups,
    attendeeId: selfAttendeeId,
    videoList,
  } = meetingState.context;
  const {
    onstageFullModalState,
    toggleStageFullModal,
    usersModalState,
    toggleUsersModal,
    carouselPanelState,
    toggleCarouselPanel,
    worldWallModalState,
    toggleWorldWallModal,
    breakoutRoomSidebarState,
    BreakoutRoomState,
    toggleBreakoutRoomSidebar,
    breakoutRoomStep,
    changeBreakoutRoomStep,
    breakoutRoomEndNotice,
    closeBreakoutRoomEndNotice,
    toggleBreakoutRoomIsLeaving,
    breakoutRoomIntroState,
    breakoutRoomIntroActions,
    setBreakoutRoomResultData,
    breakoutRoomResultState,
    breakoutRoomResultActions,
    resultReviewState,
    resultReviewActions,
    breakoutRoomIsEndState,
    setBreakoutRoomIsEndState,
    broadcastModalState,
    setBroadcastModalActions,
  } = useGroupContext();
  const {
    videoListCarouselStart,
    videoListCarouselStop,
    videoList: localVideoList,
  } = useVideoListContext();
  const { breakoutMeetingLeave, removeAllCallTeacherGroups } =
    useMeetingDispatch();
  const {
    isBreakoutRoom,
    stopBreakout,
    getBreakoutLatest,
    goToBreakoutMeeting,
    broadcastMessageToAll,
  } = useBreakoutMeeting();
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false);
  const [iframeGroup, setIframeGroup] = useState([]);
  const { openNotification } = useNotification();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter(
    (attendee) => attendee.role === Roles.Student
  );
  const isTeacherJoined = attendees.find(
    (attendee) => attendee.role === Roles.Teacher
  );
  const studentsPrevious = usePrevious(students) || [];
  const { service: whiteboardService } = useWhiteboard();
  const currentViewMode = whiteboardService?.state?.broadcastState?.mode;
  useViewMode();
  useIframeBridge();
  useInteractiveObjects(currentViewMode);
  const breakoutRoomStorage = window.localStorage;
  const callTeacherGroupsPrevious = usePrevious(callTeacherGroups);
  const { muted, toggleMute } = useToggleLocalMute();
  const { resourceModalSwitch } = useResourceModal();
  const { insertPictureModalSwitch } = useRoom();
  const [privateMessageModalState, setPrivateMessageModalState] =
    useState(false);
  const mainRef = useRef(null);
  const teacher = Object.values(roster).find(
    (attendee) => attendee.role === 'teacher'
  );
  const advisors = Object.values(roster).filter(
    (attendee) => attendee.role === 'advisor'
  );
  const student = useMemo(
    () => attendees.find((attendee) => attendee.role === Roles.Student),
    [attendees]
  );
  let otherUsers = advisors.slice(1);
  if (teacher) {
    otherUsers = [teacher, ...otherUsers];
  }

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'iframe':
        setIframeGroup((prev) =>
          prev.filter((item) => item.sources !== material.sources)
        );
        break;
      default:
        break;
    }
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const isJoined = meetingState.matches(MeetingStates.Joined);

  useEffect(() => {
    if (!isVideoEnabled && query.role !== Roles.Advisor) {
      toggleVideo();
    }
  }, [isJoined]);

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);
    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  const cancelBreakoutRoomEndNoticeHandler = () => closeBreakoutRoomEndNotice();

  const closeBreakoutRoomEndNoticeHandler = async () => {
    closeBreakoutRoomEndNotice();
    toggleBreakoutRoomIsLeaving();
    breakoutMeetingLeave();
    await stopBreakout(meetingId);

    removeAllCallTeacherGroups();
    breakoutRoomStorage.removeItem('breakoutRoomData');
    breakoutRoomStorage.removeItem('callTeacherData');
    setBreakoutRoomIsEndState(true);
    resultReviewActions.setTrue();
  };

  const [groupInvitationModal, setGroupInvitationModal] = useState(false);
  const toggleGroupInvitationModal = () =>
    setGroupInvitationModal((prev) => !prev);

  const getResultData = async () => {
    const data = await getBreakoutLatest(meetingId);
    if (!data) return;
    setBreakoutRoomResultData(data);
    breakoutRoomResultActions.setTrue();
    resultReviewActions.setTrue();
  };

  useEffect(() => {
    if (breakoutRoomIsEndState) {
      getResultData();
    }
  }, [breakoutRoomIsEndState]);

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  const [breakoutRoomInfo, setBreakoutRoomInfo] = useState(null);
  const [breakoutRoomGrouping, setBreakoutRoomGrouping] = useState(false);

  const getLatestData = async () => {
    const data = await getBreakoutLatest(meetingId);
    if (!data) return;
    setBreakoutRoomResultData(data);
    const groupInfo = data.groups.filter(
      (group) => group.roomId === breakoutId
    );
    setBreakoutRoomInfo(groupInfo[0]);
    setBreakoutRoomGrouping(data.status === 'Start' ? true : false);
  };

  useEffect(() => {
    if (!breakoutRoomGrouping) return;
    changeBreakoutRoomStep(
      breakoutRoomGrouping
        ? BreakoutRoomState.grouping
        : BreakoutRoomState.setting
    );
  }, [breakoutRoomGrouping]);

  useEffect(() => {
    getLatestData();
  }, []);

  useEffect(() => {
    if (!callTeacherGroupsPrevious) return;
    if (callTeacherGroupsPrevious.length === 0) setGroupInvitationModal(true);
  }, [callTeacherGroups]);

  const goBreakoutInviteHandler = () => {
    goToBreakoutMeeting({ breakoutId: callTeacherGroups[0].breakoutRoomId });
  };

  const { sharingAttendeeId } = useContentShareState();
  const contentSharingAttendeeId = useMemo(
    () => sharingAttendeeId?.split('#')[0] || '',
    [sharingAttendeeId]
  );
  const isShowContentShare = useMemo(
    () =>
      contentSharingAttendeeId && contentSharingAttendeeId !== selfAttendeeId,
    [selfAttendeeId, contentSharingAttendeeId]
  );

  const [broadcastValue, setBroadcastValue] = useState('');

  const broadcastValueChangeHandler = (e) => {
    const textAreaValue = e.target.value.trim();
    if (textAreaValue.length > 280) return;

    setBroadcastValue(textAreaValue);
  };

  const broadcastModalCloseHandler = () => {
    setBroadcastModalActions.setFalse();
  };

  const { loading: broadcastModalLoading, run: broadcastMessageRun } =
    useRequest(
      () =>
        broadcastMessageToAll({ courseId: meetingId, message: broadcastValue }),
      {
        manual: true,
      }
    );

  const broadcastModalSubmitHandler = async () => {
    await broadcastMessageRun();
    setBroadcastValue('');
    setBroadcastModalActions.setFalse();
  };

  // 私訊彈窗
  const onPrivateMessageClose = () => setPrivateMessageModalState(false);

  /**
   * 老師不在時用自己的輪播列表
   */
  const nextVideoStudentList = useMemo(() => {
    return isTeacherJoined ? videoList : localVideoList;
  }, [isTeacherJoined, videoList, localVideoList]);

  useEffect(() => {
    if (!muted) {
      toggleMute();
    }
  }, []);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {!isBreakoutRoom && breakoutRoomStep === BreakoutRoomState.grouping && (
        <BreakoutRoomNotificationLayout>
          <BreakoutRoomNotification meetingId={meetingId} />
        </BreakoutRoomNotificationLayout>
      )}
      {isBreakoutRoom && (
        <BreakoutRoomNotificationLayout>
          <TeacherSubRoomNotification title={breakoutRoomInfo?.groupName} />
        </BreakoutRoomNotificationLayout>
      )}
      {breakoutRoomStep === BreakoutRoomState.setting &&
        resultReviewState &&
        whiteboardService && (
          <BreakoutRoomNotificationLayout>
            <BreakoutRoomResultNotification service={whiteboardService} />
          </BreakoutRoomNotificationLayout>
        )}

      <StyledSyncMultiple>
        <MainLayout
          main={
            <MainContainer>
              {!isBreakoutRoom &&
                breakoutRoomStep === BreakoutRoomState.grouping && (
                  <BreakoutRoomNotificationLayout>
                    <BreakoutRoomNotification meetingId={meetingId} />
                  </BreakoutRoomNotificationLayout>
                )}
              {isBreakoutRoom && (
                <BreakoutRoomNotificationLayout>
                  <TeacherSubRoomNotification
                    title={breakoutRoomInfo?.groupName}
                  />
                </BreakoutRoomNotificationLayout>
              )}
              {breakoutRoomStep === BreakoutRoomState.setting &&
                resultReviewState &&
                whiteboardService && (
                  <BreakoutRoomNotificationLayout>
                    <BreakoutRoomResultNotification
                      service={whiteboardService}
                    />
                  </BreakoutRoomNotificationLayout>
                )}
              <SyncMainBlock ref={mainRef}>
                <Whiteboard toolBox={<ToolBox />} userRole={role} />
                {nextVideoStudentList.length > 0 && (
                  <StyledSyncVideoList>
                    <SyncVideoListV2 videoList={nextVideoStudentList} />
                  </StyledSyncVideoList>
                )}
                {isShowContentShare && (
                  <ContentShareContainer>
                    <ContentShare />
                  </ContentShareContainer>
                )}
                <TeachingMaterialContainer
                  iframeGroup={iframeGroup}
                  onClose={closeTeachingMaterialHandler}
                />
                {worldWallModalState && (
                  <WorldWallModal onClose={toggleWorldWallModal} />
                )}
                {resourceModalSwitch && <ResourceModal />}
                {carouselPanelState && (
                  <VideoListCarouselPanel onClose={toggleCarouselPanel} />
                )}
                {onstageFullModalState && (
                  <Modal
                    onClose={toggleStageFullModal}
                    header={trans('__warningNotification')}
                  >
                    {trans('__numberOfPeopleIsFull')}
                  </Modal>
                )}
                {breakoutRoomStep === BreakoutRoomState.setting &&
                  breakoutRoomResultState && (
                    <BreakoutRoomResult service={whiteboardService} />
                  )}
                {insertPictureModalSwitch && <InsertPictureModal />}
                {resourceModalSwitch && <ResourceModal />}

                {breakoutRoomEndNotice && (
                  <Modal
                    onClose={cancelBreakoutRoomEndNoticeHandler}
                    header={trans('__endGroup')}
                  >
                    <Box>{trans('__afterGroupDiscussionEnd')}？</Box>
                    <Box display="flex" width="100%" pt={8}>
                      <Box width="100%" mr={2}>
                        <FullButton.Secondly
                          onClick={cancelBreakoutRoomEndNoticeHandler}
                        >
                          {trans('__cancel')}
                        </FullButton.Secondly>
                      </Box>
                      <Box width="100%" ml={2}>
                        <FullButton onClick={closeBreakoutRoomEndNoticeHandler}>
                          {trans('__endGroupDiscussion')}
                        </FullButton>
                      </Box>
                    </Box>
                  </Modal>
                )}
                {groupInvitationModal && callTeacherGroups.length === 1 && (
                  <Modal
                    width="600px"
                    onClose={toggleGroupInvitationModal}
                    header={trans('__inviteToJoinDiscussion')}
                  >
                    <Box>
                      {trans('__invitationToJoin', {
                        groupName: callTeacherGroups[0].groupName,
                      })}
                    </Box>
                    <Box display="flex" width="100%" pt={8}>
                      <Box width="100%" mr={2}>
                        <FullButton.Secondly
                          onClick={toggleGroupInvitationModal}
                        >
                          {trans('__joinLater')}
                        </FullButton.Secondly>
                      </Box>
                      <Box width="100%" ml={2}>
                        <FullButton onClick={goBreakoutInviteHandler}>
                          {trans('__joinNow')}
                        </FullButton>
                      </Box>
                    </Box>
                  </Modal>
                )}
                {broadcastModalState && (
                  <Modal width="600px" header={trans('__broadcastMessage')}>
                    <StyledBroadcastModal>
                      <div className="content">
                        <textarea
                          placeholder={trans('__enterBroadcastMessage')}
                          maxLength={280}
                          onChange={broadcastValueChangeHandler}
                        ></textarea>
                        <div className="tip">{broadcastValue.length}/280</div>
                      </div>
                    </StyledBroadcastModal>
                    <Box display="flex" width="100%" pt={8}>
                      <Box width="100%" mr={2}>
                        <FullButton.Secondly
                          onClick={broadcastModalCloseHandler}
                          disabled={broadcastModalLoading ? true : false}
                        >
                          {trans('__cancel')}
                        </FullButton.Secondly>
                      </Box>
                      <Box width="100%" ml={2}>
                        <FullButton
                          onClick={broadcastModalSubmitHandler}
                          disabled={broadcastValue.length === 0 ? true : false}
                        >
                          {broadcastModalLoading ? (
                            <LoadingOutlined />
                          ) : (
                            trans('__sendMessage')
                          )}
                        </FullButton>
                      </Box>
                    </Box>
                  </Modal>
                )}
              </SyncMainBlock>
              <AdvisorContainer isStudentList={nextVideoStudentList.length > 0}>
                {otherUsers.map((user) => (
                  <OtherVideos
                    key={user.chimeAttendeeId}
                    userType={user.role}
                    tileId={attendeeIdToTileId[user.chimeAttendeeId]}
                    attendeeId={user.chimeAttendeeId}
                    advisorName={user.name}
                  />
                ))}
              </AdvisorContainer>
            </MainContainer>
          }
          side={
            <RightContainer>
              <Advisor isSettingModalOpen={isSettingModalOpen} />
            </RightContainer>
          }
          footer={
            <BottomContainer>
              <Footer
                courseType={courseType}
                isSettingModalOpen={isSettingModalOpen}
                setIsSettingModalOpen={setIsSettingModalOpen}
                isTeacherHere={teacher !== undefined}
              />
            </BottomContainer>
          }
        ></MainLayout>
        <Sidebar isSidebar={breakoutRoomIntroState}>
          <BreakoutRoomIntro onClose={breakoutRoomIntroActions.setFalse} />
        </Sidebar>
        <Sidebar isSidebar={breakoutRoomSidebarState}>
          <BreakoutRoomContainer
            onClose={toggleBreakoutRoomSidebar}
            isGrouping={breakoutRoomGrouping}
          />
        </Sidebar>

        {breakoutRoomEndNotice && (
          <Modal
            onClose={cancelBreakoutRoomEndNoticeHandler}
            header={trans('__endGroup')}
          >
            <Box>{trans('__afterGroupDiscussionEnd')}</Box>
            <Box display="flex" width="100%" pt={8}>
              <Box width="100%" mr={2}>
                <FullButton.Secondly
                  onClick={cancelBreakoutRoomEndNoticeHandler}
                >
                  {trans('__cancel')}
                </FullButton.Secondly>
              </Box>
              <Box width="100%" ml={2}>
                <FullButton onClick={closeBreakoutRoomEndNoticeHandler}>
                  {trans('__endGroupDiscussion')}
                </FullButton>
              </Box>
            </Box>
          </Modal>
        )}
        {groupInvitationModal && callTeacherGroups.length === 1 && (
          <Modal
            width="600px"
            onClose={toggleGroupInvitationModal}
            header={trans('__inviteToJoinDiscussion')}
          >
            <Box>
              {trans('__invitationToJoin', {
                groupName: callTeacherGroups[0].groupName,
              })}
            </Box>
            <Box display="flex" width="100%" pt={8}>
              <Box width="100%" mr={2}>
                <FullButton.Secondly onClick={toggleGroupInvitationModal}>
                  {trans('__joinLater')}
                </FullButton.Secondly>
              </Box>
              <Box width="100%" ml={2}>
                <FullButton onClick={goBreakoutInviteHandler}>
                  {trans('__joinNow')}
                </FullButton>
              </Box>
            </Box>
          </Modal>
        )}

        {broadcastModalState && (
          <Modal width="600px" header={trans('__broadcastMessage')}>
            <StyledBroadcastModal>
              <div className="content">
                <textarea
                  placeholder={trans('__enterBroadcastMessage')}
                  maxLength={280}
                  onChange={broadcastValueChangeHandler}
                ></textarea>
                <div className="tip">{broadcastValue.length}/280</div>
              </div>
            </StyledBroadcastModal>
            <Box display="flex" width="100%" pt={8}>
              <Box width="100%" mr={2}>
                <FullButton.Secondly
                  onClick={broadcastModalCloseHandler}
                  disabled={broadcastModalLoading ? true : false}
                >
                  {trans('__cancel')}
                </FullButton.Secondly>
              </Box>
              <Box width="100%" ml={2}>
                <FullButton
                  onClick={broadcastModalSubmitHandler}
                  disabled={broadcastValue.length === 0 ? true : false}
                >
                  {broadcastModalLoading ? (
                    <LoadingOutlined />
                  ) : (
                    trans('__sendMessage')
                  )}
                </FullButton>
              </Box>
            </Box>
          </Modal>
        )}

        {isTeacherJoined && privateMessageModalState && (
          <Modal
            width="600px"
            header={trans('__promptMessage')}
            onClose={onPrivateMessageClose}
          >
            <PrivateMessageModal
              onClose={onPrivateMessageClose}
              targetRole={Roles.Teacher}
              placeholder={trans('__enterPromptMessageHere')}
            />
          </Modal>
        )}
      </StyledSyncMultiple>
    </Box>
  );
};
