import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'utils/hooks/useQuery';
import PropTypes from 'prop-types';
import { Loading, Box, Modal, Button } from '@oneboard/ui-components';
import { useWhiteboard } from '@oneboard/whiteboard';
import { RoomWhiteboard } from 'white-react-sdk';
import ToolBox from '@netless/tool-box';
import RedoUndo from '@netless/redo-undo';
import ZoomController from '@netless/zoom-controller';
import { Roles } from 'constants/index';
import {
  useMeetingManager,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
import { useMeetingState } from '@oneboard/meeting';
import { useTimeout } from 'ahooks';
import { StyledSyncWhiteboard, StyledToolBox } from './SyncWhiteboard.style';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.syncWhiteboard.${key}`, '', params);
};

const StudentDisabledToolIndexes = [6, 9];
const TeacherDisabledToolIndexes = [9];

export const SyncWhiteboard = ({ toolBox, disableToolbox, children }) => {
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const { attendeeIdToTileId } = useRemoteVideoTileState();

  const {
    service: whiteboardService,
    loading,
    joinWhiteboard,
    whiteboardError,
  } = useWhiteboard();
  const meetingManager = useMeetingManager();
  const role = query.role;
  const [errorCountdown, setErrorCountdown] = useState(null);

  const { context } = useMeetingState();
  const { attendeeId: selfAttendeeId } = context;

  useEffect(() => {
    if (role === Roles.Observer) {
      (async () => {
        const meetingRoomId = breakoutId ? breakoutId : meetingId;
        await joinWhiteboard({
          meetingId: meetingRoomId,
          role: query.role,
          userName: query.userName,
          userId: query.userId,
        });
      })();
    }
  }, []);

  useEffect(() => {
    if (!whiteboardService || !meetingManager) return;
    whiteboardService.plugins.setPluginContext('controllableVideo', {
      meetingManager,
      identity: role === Roles.Teacher ? 'host' : 'guest',
      attendeeIdToTileId: attendeeIdToTileId ? attendeeIdToTileId : {},
      selfAttendeeId,
    });
  }, [
    whiteboardService,
    meetingManager,
    role,
    attendeeIdToTileId,
    selfAttendeeId,
  ]);

  const toolboxRef = useRef();

  const hideTools = (disabledIndex) => {
    const toolboxCell = toolboxRef.current.querySelectorAll(
      '.tool-box-cell-box-left'
    );
    if (toolboxCell.length > 0) {
      toolboxCell.forEach((tool, index) => {
        if (disabledIndex.includes(index)) {
          tool.style.display = 'none';
        }
      });
    }
  };

  const hideStudentTools = (disableToolbox, disabledIndex) => {
    if (disableToolbox) {
      whiteboardService.disableDeviceInputs = true;
    } else {
      whiteboardService.disableDeviceInputs = false;
      hideTools(disabledIndex);
    }
  };

  useEffect(() => {
    if (!whiteboardService || !toolboxRef.current) return;
    if (query.role === Roles.Student) {
      hideStudentTools(disableToolbox, StudentDisabledToolIndexes);
    } else {
      hideTools(TeacherDisabledToolIndexes);
    }
  }, [disableToolbox, whiteboardService, toolboxRef.current]);

  useEffect(() => {
    if (!whiteboardService) return;
    if (query.role === Roles.Student) {
      whiteboardService.disableCameraTransform = true;
    }
  }, [whiteboardService]);

  useTimeout(() => {
    window.location.reload();
  }, errorCountdown);

  useEffect(() => {
    if (query.role === Roles.Observer && whiteboardError !== null) {
      setErrorCountdown(1000 * 15);
    }
  }, [whiteboardError]);

  // oneBook 教材 click 層級
  useEffect(() => {
    if (!whiteboardService) return;
    const syncWhiteboardDom = document.getElementById('SyncWhiteboard');
    whiteboardService.callbacks.on('onRoomStateChanged', function (state) {
      if (state.memberState) {
        if (state.memberState.currentApplianceName === 'clicker') {
          syncWhiteboardDom?.classList?.add('clicker');
        } else {
          syncWhiteboardDom?.classList?.remove('clicker');
        }
      }
    });
  }, [whiteboardService]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        navigator.wakeLock.request('screen');
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <StyledSyncWhiteboard id="SyncWhiteboard" data-testid="SyncWhiteboard">
      {loading ? (
        <div className="loadingWrap">
          <Loading />
        </div>
      ) : (
        <>
          {whiteboardService && query.role !== Roles.Observer && (
            <>
              <StyledToolBox ref={toolboxRef} show={!disableToolbox}>
                <ToolBox
                  room={whiteboardService}
                  customerComponent={[toolBox]}
                />
              </StyledToolBox>
              <Box
                display="flex"
                position="absolute"
                zIndex={5}
                bottom={2}
                left={2}
              >
                {query.role !== Roles.Student && (
                  <Box>
                    <RedoUndo room={whiteboardService} />
                  </Box>
                )}
                {query.role !== Roles.Student && (
                  <Box ml={2}>
                    <ZoomController room={whiteboardService} />
                  </Box>
                )}
              </Box>
              {children}
            </>
          )}
          {whiteboardService && (
            <RoomWhiteboard
              style={{ width: '100%', height: '100%' }}
              room={whiteboardService}
            />
          )}
          {whiteboardError && (
            <Modal header={trans('__warn')} closeIcon={false}>
              {trans('__whiteboardLinkFailed')}
              <br />
              {trans('__errorMessage')}：{whiteboardError}
              <Box display="flex" justifyContent="flex-end" mt={5}>
                <Button onClick={() => window.location.reload()}>
                  {trans('__refresh')}
                </Button>
              </Box>
            </Modal>
          )}
        </>
      )}
    </StyledSyncWhiteboard>
  );
};

SyncWhiteboard.propTypes = {
  toolBox: PropTypes.node,
  children: PropTypes.node,
  disableToolbox: PropTypes.bool,
};
