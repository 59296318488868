import React, { useRef } from 'react';
import {
  StyledWaitingAnimation,
  Animation,
  AnimationWrap,
  TextContainer,
  StyledHeader,
  StyledText,
} from './MeetingFailurePage.style';
import Time from './Time.gif';
import { SendButton } from 'components/NewOneBoard/common/MessageButton';
import { t } from 'utils/i18n';

export const MeetingFailurePage = () => {
  const trans = (key, params) => {
    return t(`components.meetingFailurePage.${key}`, '', params);
  };
  const ref = useRef();
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <StyledWaitingAnimation>
      <AnimationWrap ref={ref}>
        <Animation ref={ref}>
          <img width={'200px'} src={Time} alt="Time" />
        </Animation>
        <TextContainer>
          <StyledHeader>{trans('__header')}</StyledHeader>
          <StyledText>{trans('__text')}</StyledText>
        </TextContainer>
        <SendButton onClick={handleRefresh}>{trans('__button')}</SendButton>
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};
