import React, { useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  useLocalVideo,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import {
  useRosterState,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
  MeetingRoles,
} from '@oneboard/meeting';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import {
  StyleUsersModal,
  StyledUserCell,
  StyledModalBody,
  StyledName,
} from './UsersModal.style';
import { Roles, ClassType } from 'constants/index';
import { MAX_STUDENT_VIDEO_COUNT } from 'config';
import { useGroupContext } from 'providers/GroupProvider';
import { useToolboxAuth } from 'utils/hooks/useToolboxAuth';
import IconWrapper from 'components/NewOneBoard/common/IconWrapper';
import styled from 'styled-components';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useScrollbar } from 'utils/hooks/useScrollbar';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.usersModalV2.${key}`, '', params);
};

const ParticipantContainer = styled.div`
  padding: 18px 0 0 16px;
  height: 288px;
`;

const TrophyQuantity = styled.p`
  margin: 0;
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  width: 98px;
`;

const TrophyIconWrapper = styled(IconWrapper)`
  &:hover {
    background-color: #f9c74f;
  }
`;

const NonStudentIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 200px;
  border: 0;
  background: ${({ isEnabled }) => (isEnabled ? '#454f5b' : '#FF5630')};
  margin: 0;
  flex: 0 0 24px;
  cursor: ${({ isTeacher }) => (isTeacher ? 'pointer' : 'not-allowed')};
`;

const ToolStatusContainer = styled(Box)`
  display: flex;
  justify-content: ${({ isMultiple }) =>
    isMultiple ? 'flex-start' : 'flex-end'};
  gap: 8px;
`;

export const UsersModal = ({ onClose, currentRole }) => {
  const {
    stageAttendeeFromAdvisor,
    unstageAttendeeFromAdvisor,
    muteAttendeeFromAdvisor,
    unMuteAttendeeFromAdvisor,
    updateRewardFromAdvisor,
    sendRewardFromAdvisor,
    updateReward,
    sendReward,
    stageAttendee,
    openVideoAttendee,
    disableChat,
    enableChat,
  } = useMeetingDispatch();

  const { toggleStageFullModal, usersMuteHandler } = useGroupContext();
  const meetingState = useMeetingState();
  const {
    videoListCarouselStop,
    carouselSwitch,
    toggleCarouselSwitch,
    unstagedHandler,
  } = useVideoListContext();
  const { context, role } = meetingState;
  const { stagedAttendeeIds } = context;
  const { videoList: videoStudentList } = useVideoListContext();
  const { roster } = useRosterState();
  const allAttendees = useMemo(() => Object.values(roster), [roster]);
  const { muteAttendee, unMuteAttendee, unstageAttendee } =
    useMeetingDispatch();
  const [isScrollable, setScrollable] = useState(false);
  const [isOnstageFull, setOnStageFull] = useState(false);
  const { checkScrollable } = useScrollbar();
  // const isOnstageFull = stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT;
  const teacher = useMemo(
    () => allAttendees.find((attendee) => attendee.role === Roles.Teacher),
    [allAttendees]
  );
  const advisors = useMemo(
    () => allAttendees.filter((attendee) => attendee.role === Roles.Advisor),
    [allAttendees]
  );
  const students = useMemo(
    () => allAttendees.filter((attendee) => attendee.role === Roles.Student),
    [allAttendees]
  );
  const attendees = useMemo(() => {
    const arr = [];
    if (teacher) arr.push(teacher);
    arr.push(...advisors);
    arr.push(...students);
    return arr;
  }, [teacher, advisors, students]);
  const attendeesWithoutAdvisors = attendees.filter(
    (attendee) => attendee.role !== 'advisor'
  );

  const ref = useRef();
  const isRewardAnimateActive =
    meetingState.matches({
      [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
    }) ||
    meetingState.matches({
      [MeetingStates.Joined]: `${MeetingRoles.Advisor}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
    });

  const stageHandler = ({ attendeeId, isOnStaged }) => {
    if (isOnstageFull) {
      if (isOnStaged) {
        muteAttendee({ attendeeId });
        unstageAttendee({ attendeeId });
        return;
      } else {
        toggleStageFullModal();
        return;
      }
    }
    isOnStaged
      ? unstageAttendee({ attendeeId })
      : stageAttendee({ attendeeId });
    isOnStaged ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId });

    unstagedHandler({ attendeeId });
    openVideoAttendee({ attendeeId });
  };

  const AdvisorstageHandler = ({ attendeeId, isOnStaged }) => {
    if (isOnstageFull) {
      if (isOnStaged) {
        muteAttendeeFromAdvisor({ attendeeId });
        unstageAttendeeFromAdvisor({ attendeeId });
        return;
      } else {
        toggleStageFullModal();
        return;
      }
    }

    isOnStaged
      ? unstageAttendeeFromAdvisor({ attendeeId })
      : stageAttendeeFromAdvisor({ attendeeId });
    isOnStaged
      ? muteAttendeeFromAdvisor({ attendeeId })
      : unMuteAttendeeFromAdvisor({ attendeeId });
  };

  const rewardHandler = ({ attendeeId, userId, studentName }) => {
    const rewardInStage = videoStudentList.find(
      (video) => video.chimeAttendeeId === attendeeId
    );
    if (isRewardAnimateActive) return;
    if (role === Roles.Advisor) {
      updateRewardFromAdvisor({ userId, studentName });
    } else {
      updateReward({ userId, studentName });
    }
    if (rewardInStage) sendReward({ attendeeId, userId, studentName });
  };

  const AdvisorRewardHandler = ({ attendeeId, userId, studentName }) => {
    const rewardInStage = videoStudentList.find(
      (video) => video.chimeAttendeeId === attendeeId
    );

    if (isRewardAnimateActive) return;
    updateRewardFromAdvisor({ userId, studentName });
    if (rewardInStage) {
      sendRewardFromAdvisor({ attendeeId, userId });
    }
  };

  const muteHandler = ({ attendeeId, isMuted }) => {
    if (isMuted) {
      currentRole === Roles.Advisor
        ? unMuteAttendeeFromAdvisor({ attendeeId })
        : unMuteAttendee({ attendeeId });
    } else {
      currentRole === Roles.Advisor
        ? muteAttendeeFromAdvisor({ attendeeId })
        : muteAttendee({ attendeeId });
    }
    if (currentRole === Roles.Teacher) {
      usersMuteHandler({
        [attendeeId]: !isMuted,
      });
    }
  };

  const toggleStageHandler = ({ isOnStaged, attendeeId }) => {
    if (isOnstageFull) {
      if (isOnStaged) {
        muteAttendee({ attendeeId });
        unstageAttendee({ attendeeId });
        return;
      } else {
        toggleStageFullModal();
        return;
      }
    }
  };

  useEffect(() => {
    if (stagedAttendeeIds.length === MAX_STUDENT_VIDEO_COUNT) {
      setOnStageFull(true);
    } else {
      setOnStageFull(false);
    }
  }, [stagedAttendeeIds.length]);

  useEffect(() => {
    setScrollable(checkScrollable(ref.current));
  }, [attendees]);

  useEffect(() => {
    const lastCarouselSwitch = carouselSwitch;
    if (lastCarouselSwitch) {
      videoListCarouselStop();
      toggleCarouselSwitch();
    }
  }, []);

  return (
    <ParticipantContainer>
      <StyleUsersModal>
        <StyledModalBody ref={ref}>
          {attendeesWithoutAdvisors.length > 0 &&
            attendeesWithoutAdvisors.map((attendee) => (
              <UserCell
                key={attendee.chimeAttendeeId}
                user={attendee}
                stageHandler={stageHandler}
                rewardHandler={rewardHandler}
                AdvisorRewardHandler={AdvisorRewardHandler}
                muteHandler={muteHandler}
                toggleStageHandler={toggleStageHandler}
                AdvisorstageHandler={AdvisorstageHandler}
                currentRole={currentRole}
                disableChat={disableChat}
                enableChat={enableChat}
              />
            ))}
        </StyledModalBody>
      </StyleUsersModal>
    </ParticipantContainer>
  );
};

UsersModal.propTypes = {
  onClose: PropTypes.func,
};

const UserCell = ({
  user,
  role = Roles.Student,
  stageHandler,
  rewardHandler,
  AdvisorRewardHandler,
  muteHandler,
  AdvisorstageHandler,
  currentRole,
  disableChat,
  enableChat,
}) => {
  const meetingState = useMeetingState();
  const { context } = meetingState;
  const {
    stagedAttendeeIds,
    raisedHandAttendeeIds,
    chatDisabledAttendeeIds,
    rewards,
    courseType,
  } = context;
  const { chimeAttendeeId: attendeeId, userId, name } = user;
  const { muted: attendMuted, videoEnabled } = useAttendeeStatus(attendeeId);
  const { queryToolboxAuthById, toggleToolboxAuth } = useToolboxAuth();
  const [isOnstageFull, setOnStageFull] = useState(false);
  const { toggleStageFullModal } = useGroupContext();
  const {
    openVideoAttendee,
    closeVideoAttendee,
    muteAttendee,
    unMuteAttendee,
  } = useMeetingDispatch();
  const isOnStaged = stagedAttendeeIds.includes(attendeeId);
  const isRaiseHand = raisedHandAttendeeIds.includes(attendeeId);
  const isChatDisabled = chatDisabledAttendeeIds.includes(attendeeId);

  const toggleStage = () => {
    if (role === Roles.Advisor) {
      AdvisorstageHandler({ isOnStaged, attendeeId });
    } else {
      stageHandler({ isOnStaged, attendeeId });
    }
  };

  const toggleReward = () => {
    if (currentRole === Roles.Advisor) {
      AdvisorRewardHandler({ attendeeId, userId, studentName: name });
    } else {
      rewardHandler({ attendeeId, userId, studentName: name });
    }
  };

  const toggleMute = () => muteHandler({ attendeeId, isMuted: attendMuted });

  const toggleVideoHandler = ({ isShowVideo, attendeeId }) => {
    // if (isShowVideo) return;
    if (videoEnabled) {
      closeVideoAttendee({ attendeeId });
    } else {
      openVideoAttendee({ attendeeId });
    }

    if (isOnstageFull) {
      toggleStageFullModal();
      return;
    }
  };

  const chatAuthHandler = ({ attendeeId, isChatDisabled }) => {
    if (isChatDisabled) {
      enableChat({ attendeeId });
    } else {
      disableChat({ attendeeId });
    }
  };

  const { toggleMute: toggleOwnMicro } = useToggleLocalMute();
  const { toggleVideo: toggleOwnVideo } = useLocalVideo();

  return (
    <>
      <StyledUserCell>
        <StyledName>
          {name}
          {user.role === 'teacher'
            ? trans('__hostTeacher')
            : user.role === 'advisor'
            ? trans('__advisor')
            : trans('__student')}
        </StyledName>

        {courseType === ClassType.SyncMultiple && (
          <TrophyQuantity>
            {user.role !== Roles.Student ? '-' : rewards[userId]}
          </TrophyQuantity>
        )}
        <ToolStatusContainer
          className="iconGroup"
          flex={role === Roles.Student ? 2 : 1}
          isMultiple={courseType === ClassType.SyncMultiple}
        >
          {user.role === Roles.Teacher || user.role === Roles.Advisor ? (
            <NonStudentIconWrapper
              isTeacher={user.role === currentRole}
              isEnabled={!attendMuted}
              onClick={() => {
                if (user.role === currentRole) {
                  toggleOwnMicro();
                }
              }}
            >
              {attendMuted ? (
                <Icon name="MicrophoneAltSlashSolid" size="xs" color="#fff" />
              ) : (
                <Icon name="MicrophoneAltSolid" size="xs" color="#fff" />
              )}
            </NonStudentIconWrapper>
          ) : (
            <IconWrapper
              isEnabled={!attendMuted}
              onClick={toggleMute}
              userType={currentRole}
            >
              {attendMuted ? (
                <Icon name="MicrophoneAltSlashSolid" size="xs" color="#fff" />
              ) : (
                <Icon name="MicrophoneAltSolid" size="xs" color="#fff" />
              )}
            </IconWrapper>
          )}

          {user.role === Roles.Teacher || user.role === Roles.Advisor ? (
            <NonStudentIconWrapper
              isTeacher={user.role === currentRole}
              isEnabled={videoEnabled}
              onClick={() => {
                if (user.role === currentRole) {
                  toggleOwnVideo();
                }
              }}
            >
              {videoEnabled ? (
                <Icon name="VideoSolid" size="xxs" color="#fff" />
              ) : (
                <Icon name="VideoSlashSolid" size="xxs" color="#fff" />
              )}
            </NonStudentIconWrapper>
          ) : (
            <IconWrapper
              isEnabled={videoEnabled}
              style={{ cursor: 'not-allowed' }}
              // onClick={() => toggleVideoHandler({ attendeeId, isShowVideo: videoEnabled })}
            >
              {videoEnabled ? (
                <Icon name="VideoSolid" size="xxs" color="#fff" />
              ) : (
                <Icon name="VideoSlashSolid" size="xxs" color="#fff" />
              )}
            </IconWrapper>
          )}

          {user.role === Roles.Student && (
            <>
              <NonStudentIconWrapper
                isTeacher={currentRole === Roles.Teacher}
                onClick={() =>
                  currentRole === Roles.Teacher &&
                  toggleToolboxAuth({ attendeeId })
                }
                isEnabled={queryToolboxAuthById({ attendeeId })}
              >
                {queryToolboxAuthById({ attendeeId }) ? (
                  <Icon name="ChalkboardOutline" size="xs" color="#FFFFFF" />
                ) : (
                  <Icon name="ChalkboardSlashSolid" size="xs" color="#FFFFFF" />
                )}
              </NonStudentIconWrapper>
              {courseType === ClassType.SyncMultiple && (
                <NonStudentIconWrapper
                  isTeacher={currentRole === Roles.Teacher}
                  onClick={() =>
                    chatAuthHandler({ attendeeId, isChatDisabled })
                  }
                  isEnabled={!isChatDisabled}
                >
                  {isChatDisabled ? (
                    <Icon name="CommentSlashSolid" size="xs" color="#FFFFFF" />
                  ) : (
                    <Icon name="CommentSolid" size="xs" color="#FFFFFF" />
                  )}
                </NonStudentIconWrapper>
              )}
            </>
          )}

          {user.role === Roles.Student && (
            <TrophyIconWrapper
              onClick={toggleReward}
              isEnabled={true}
              userType={currentRole}
            >
              <Icon name="TrophySolid" size="xs" color="#FFFFFF" />
            </TrophyIconWrapper>
          )}

          {user.role === Roles.Student &&
            courseType === ClassType.SyncMultiple && (
              <IconWrapper
                onClick={toggleStage}
                isEnabled={!isOnStaged}
                isOnStaged={isOnStaged}
                isRaiseHand={isRaiseHand}
                userType={currentRole}
              >
                <Icon name="ThumbtackSolid" size="xs" color="#FFFFFF" />
              </IconWrapper>
            )}
        </ToolStatusContainer>
      </StyledUserCell>
    </>
  );
};

UserCell.propTypes = {
  role: PropTypes.string,
  user: PropTypes.object,
  stageHandler: PropTypes.func,
  rewardHandler: PropTypes.func,
  muteHandler: PropTypes.func,
};
