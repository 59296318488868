import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
  useEffect,
} from 'react';
import IVSBroadcastClient from 'amazon-ivs-web-broadcast';
import firebaseEnvConfig from 'firebaseOneClubConfig.json';
import firebase from 'firebase/compat/app';
import 'firebase/remote-config';
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config';

const firebaseConfig = firebaseEnvConfig;
const env = process.env.REACT_APP_ENV;

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const StreamingContext = createContext();

export const useStreaming = () => useContext(StreamingContext);

export const StreamingProvider = ({ children }) => {
  const [isBroadcasting, setIsBroadcasting] = useState(false);
  const [streamingCourseInfo, setStreamingCourseInfo] = useState({});
  const ivsClient = useRef(null);

  const handlePermissions = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      stream.getTracks().forEach((track) => track.stop());
      return { video: true, audio: true };
    } catch (error) {
      console.error('Failed to get media permissions:', error);
      return { video: false, audio: false };
    }
  };

  const setDevice = async (client) => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioDevice = devices.find((device) => device.kind === 'audioinput');

    if (!audioDevice) {
      console.error('No audio input device found.');
      return;
    }

    const videoStream = await navigator.mediaDevices.getDisplayMedia();
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: audioDevice.deviceId },
    });

    // Stop broadcasting when the screen sharing ends
    videoStream.getVideoTracks()[0].addEventListener('ended', () => {
      stopBroadcast();
    });

    client.addVideoInputDevice(videoStream, 'screen', { index: 0 });
    client.addAudioInputDevice(audioStream, 'microphone');
  };

  const startBroadcast = useCallback(async (streamingCourseInfo) => {
    const permissions = await handlePermissions();
    if (!permissions.video || !permissions.audio) {
      console.error('Insufficient permissions for broadcasting.');
      return;
    }

    ivsClient.current = IVSBroadcastClient.create({
      streamConfig: IVSBroadcastClient.BASIC_FULL_HD_LANDSCAPE,
      ingestEndpoint: streamingCourseInfo?.ingest_endpoint,
    });

    await setDevice(ivsClient.current);

    try {
      await ivsClient.current.startBroadcast(streamingCourseInfo?.stream_key);
      setIsBroadcasting(true);
    } catch (error) {
      console.error('Failed to start broadcasting:', error);
    }
  }, []);

  const stopBroadcast = useCallback(async () => {
    if (!ivsClient.current) return;

    try {
      await ivsClient.current.stopBroadcast();
      setIsBroadcasting(false);
    } catch (error) {
      console.error('Failed to stop broadcasting:', error);
    }
  }, []);

  useEffect(() => {
    const app = firebase.initializeApp(firebaseConfig);
    const remoteConfig = getRemoteConfig(app);
    remoteConfig.settings.minimumFetchIntervalMillis = 3 * 60 * 1000;

    fetchAndActivate(remoteConfig).then(() => {
      const val = getValue(remoteConfig, 'streaming_course_info');
      const parsedValue = JSON.parse(val._value);
      setStreamingCourseInfo(parsedValue[env]);
    });
  }, []);

  return (
    <StreamingContext.Provider
      value={{
        isBroadcasting,
        setIsBroadcasting,
        startBroadcast,
        stopBroadcast,
        streamingCourseInfo,
      }}
    >
      {children}
    </StreamingContext.Provider>
  );
};
