import React from 'react';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.openCloseButton.${key}`,
    '',
    params
  );
};

export const OpenCloseButton = () => {
  const { isFooterOpen, setIsFooterOpen } = useFooterStatus();
  const handleFooterOpen = () => {
    setIsFooterOpen(!isFooterOpen);
  };

  return (
    <Tooltip title={isFooterOpen ? trans('__close') : trans('__open')}>
      <div>
        <ToolButton
          icon={
            <Icon
              name={isFooterOpen ? 'ChevronDownOutline' : 'ChevronUpOutline'}
              size='md'
              color='#637381'
            />
          }
          onClick={handleFooterOpen}
        ></ToolButton>
      </div>
    </Tooltip>
  );
};
