import React, { useRef, useEffect } from 'react';
import { useBitrix } from 'utils/hooks/useBitrix';
import lottie from 'lottie-web';
import {
  StyledWaitingAnimation,
  Animation,
  AnimationWrap,
  Title,
  Text,
  TextConatiner,
} from './WaitingAnimation.style';
import oneBoardRocket from './OneBoard_Rocket_New.json';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`components.waitingAnimationV2.${key}`, '', params);
};

export const WaitingAnimation = ({ isInClassTime, countdown }) => {
  const { showCRMChat, hideCRMChat } = useBitrix();
  const ref = useRef();

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  useEffect(() => {
    showCRMChat();
    const animation = lottie.loadAnimation({
      container: ref.current,
      animationData: oneBoardRocket,
    });

    return () => {
      animation.destroy();
      hideCRMChat();
    };
  }, []);

  return (
    <StyledWaitingAnimation data-testid="WaitingAnimation">
      <AnimationWrap>
        <Animation ref={ref} />
        <TextConatiner>
          {!isInClassTime && countdown !== 0 ? (
            <>
              <Title>{trans('__areYouReadyClassIsAboutToStart')}</Title>
              {countdown && (
                <Text>
                  {trans('__minutesRemainingSecondsRemaining', {
                    minutes,
                    seconds,
                  })}
                </Text>
              )}
            </>
          ) : (
            <Title>{trans('__pleaseWaitTeacherIsOnTheWay')}</Title>
          )}
        </TextConatiner>
      </AnimationWrap>
    </StyledWaitingAnimation>
  );
};
