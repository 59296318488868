import React, { useState, useRef, useEffect } from 'react';
import { Modal, Box } from '@oneboard/ui-components';
import PropTypes from 'prop-types';
import { useMeetingDispatch } from '@oneboard/meeting';
import { StyledWorldWallModal, Button } from './WorldWallModal.style';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import styled from 'styled-components';
import {
  CancelButton,
  SendButton,
} from 'components/NewOneBoard/common/MessageButton';
import lottie from 'lottie-web';
import QuizJSON from './quiz.json';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.worldWallModal.${key}`, '', params);
};

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ModalText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
`;

const Backdrop = styled.div`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 6;
`;

const WorldwallLink = styled.a`
  color: #8a94a6;
`;

export const WorldWallModal = ({ className, onClose = () => {} }) => {
  const { setWorldWall, closeWorldWall } = useMeetingDispatch();
  const [testState, setTestState] = useState(false);
  const [linkValue, setLinkValue] = useState('');
  const [noticeState, setNoticeState] = useState(false);
  const animationRef = useRef(null); // 用於儲存 lottie 動畫實例的 ref

  const noticeHandler = () => setNoticeState((prev) => !prev);

  const endHandler = () => {
    closeWorldWall();
    setTestState(false);
    onClose();
  };

  const changeHandler = (e) => {
    const textAreaValue = e.target.value.trim();

    setLinkValue(textAreaValue);
  };

  const startHandler = () => {
    const urlPattern = /^http/;
    const iframePattern = /^<iframe/;
    const httpStart = urlPattern.test(linkValue);
    const iframeStart = iframePattern.test(linkValue);
    let url = '';

    if (!httpStart && !iframeStart) {
      noticeHandler();
      return;
    }

    if (httpStart) url = linkValue;

    if (iframeStart) {
      const pattern = /src="([^"]*)"/;
      url = pattern.exec(linkValue)[0].replace('src=', '').replace('"', '');
    }

    setWorldWall({ url });

    setTestState(true);
  };

  useEffect(() => {
    if (testState) {
      animationRef.current = lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: QuizJSON,
      });
    }
  }, [testState]);

  return (
    <>
      {!testState && (
        <ToolModal
          active={!testState}
          onClose={onClose}
          title={trans('header', 'Worldwall 測驗工具')}
          width="600px"
          height="400px"
          footerHeight="70px"
          footerContent={
            <ButtonContainer>
              <CancelButton block size="large" onClick={onClose}>
                {trans('cancel')}
              </CancelButton>
              <SendButton block size="large" onClick={startHandler}>
                {trans('startQuiz')}
              </SendButton>
            </ButtonContainer>
          }
        >
          <StyledWorldWallModal
            className={className}
            data-testid="WorldWallModal"
          >
            <div className="content">
              <textarea
                placeholder={t(
                  'containers.worldWallModal.enterQuizURL',
                  '請輸入測驗用HTML網址'
                )}
                onChange={changeHandler}
              ></textarea>
              <div className="tip">
                {trans('__supportServiceText')}
                <WorldwallLink
                  href="https://wordwall.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://wordwall.net/
                </WorldwallLink>
              </div>
            </div>
          </StyledWorldWallModal>
        </ToolModal>
      )}
      {testState && (
        <>
          <Backdrop active={noticeState} />
          <ToolModal
            active={testState}
            onClose={endHandler}
            title={t(
              'containers.worldWallModal.quizInProgress',
              '測驗進行中...'
            )}
            width="600px"
            height="550px"
            showCloseIcon={false}
            footerHeight="70px"
            footerContent={
              <ButtonContainer>
                <SendButton block size="large" onClick={endHandler}>
                  {t('containers.worldWallModal.endQuiz', '結束測驗')}
                </SendButton>
              </ButtonContainer>
            }
          >
            <Box display="flex" justifyContent="center" my={7} width="100%">
              <div ref={animationRef} />
            </Box>
          </ToolModal>
        </>
      )}
      {noticeState && (
        <>
          <Backdrop active={noticeState} />
          <ToolModal
            active={noticeState}
            onClose={noticeHandler}
            title={t('containers.worldWallModal.notice', '注意')}
            width="400px"
            showBottomContainer={false}
            justifyContent="space-around"
          >
            <ModalText>
              {t('containers.worldWallModal.inCorrectURL', '請輸入正確網址')}
            </ModalText>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="100%"
              pt={8}
              marginBottom="10px"
            >
              <Button className="primary" onClick={noticeHandler}>
                {t('containers.worldWallModal.close', '關閉')}
              </Button>
            </Box>
          </ToolModal>
        </>
      )}
    </>
  );
};

WorldWallModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};
