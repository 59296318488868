import React from 'react';
import PropTypes from 'prop-types';
import { useAttendeeStatus } from 'amazon-chime-sdk-component-library-react';
import { RemoteVideoV2 } from '@oneboard/meeting';
import VideoWrapper from './VideoWrapper';
import NotOpen from './NotOpen';
import styled from 'styled-components';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.videoInfo.common.whiteBoardVideo.${key}`,
    '',
    params
  );
};

const AdvisorVideoContainer = styled(VideoWrapper)`
  position: relative;
  height: 166px;
  width: 216px;
  padding: 8px;
  border: 1px solid #f4f6f8;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.16);
  border: #f4f6f8;
  background: #ffffff;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 10px;
  z-index: 1;
`;

const StyledRemoteVideo = styled(RemoteVideoV2)`
  border-radius: 16px;
  overflow: hidden;
`;

const NameContainer = styled.div`
  position: absolute;
  left: 8px;
  bottom: 8px;
  border-radius: 0px 8px;
  padding: 4px;
  background: #ec7963;
  color: #ffffff;
`;

export default function WhiteboardVideo({
  tileId,
  attendeeId,
  advisorName,
  userType,
}) {
  const { muted: attendMuted, videoEnabled } = useAttendeeStatus(attendeeId);

  return (
    <>
      {videoEnabled && (
        <AdvisorVideoContainer key={tileId}>
          {videoEnabled ? (
            <StyledRemoteVideo tileId={tileId} attendeeId={attendeeId} />
          ) : (
            <NotOpen />
          )}
          <NameContainer>
            {userType === 'advisor'
              ? trans('__consultant')
              : trans('__teacher')}{' '}
            {advisorName}
          </NameContainer>
        </AdvisorVideoContainer>
      )}
    </>
  );
}

WhiteboardVideo.propTypes = {
  userType: PropTypes.string.isRequired,
  tileId: PropTypes.number.isRequired,
  attendeeId: PropTypes.string.isRequired,
  advisorName: PropTypes.string.isRequired,
};
