import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCountDown } from 'utils/hooks/useCountDown';
import { useGroupContext } from 'providers/GroupProvider';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import Icon from '@onedesign/icon';
import { Box, Popover } from '@oneboard/ui-components';
import { Roles } from 'constants/index';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import {
  StyledBreakoutRoomNotification,
  StyledSubRoomNotification,
  StyledSubRoomInvite,
  StyledSingleInvite,
  StyledMultipleInvite,
  StyledBreakoutRoomResultNotification,
  StyledMemberList,
} from './BreakoutRoomNotification.style';
import { t } from 'utils/i18n';
const trans = (key, params) =>
  t(`containers.breakoutRoomNotification.${key}`, '', params);

export const BreakoutRoomNotification = ({ className, meetingId }) => {
  const {
    toggleBreakoutRoomSidebar,
    breakoutRoomIsLeaving,
    toggleBreakoutRoomIsLeaving,
    BreakoutRoomState,
    changeBreakoutRoomStep,
  } = useGroupContext();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();

  useEffect(() => {
    if (breakoutRoomIsLeaving) {
      startCountDown();
    }
  }, [breakoutRoomIsLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    changeBreakoutRoomStep(BreakoutRoomState.setting);
    toggleBreakoutRoomIsLeaving();
  }, [countDownNumber]);

  return (
    <StyledBreakoutRoomNotification
      className={className}
      isLeaving={breakoutRoomIsLeaving}
      data-testid="BreakoutRoomNotification"
    >
      {!breakoutRoomIsLeaving && (
        <div className="title">{trans('__groupDiscussionInProgress')}</div>
      )}
      {breakoutRoomIsLeaving && (
        <div className="title">
          {trans('__groupDiscussionClosingSoon', { countDownNumber })}
        </div>
      )}
      <div className="action">
        <div className="btn" onClick={toggleBreakoutRoomSidebar}>
          {trans('__viewGroupDiscussion')}
        </div>
      </div>
    </StyledBreakoutRoomNotification>
  );
};

BreakoutRoomNotification.propTypes = {
  className: PropTypes.string,
  meetingId: PropTypes.string,
};

const SubRoomInvite = ({ inviteData = [] }) => {
  const [contentSwitch, setContentSwitch] = useState(false);
  const { redirectToMainMeeting, goToBreakoutMeeting } = useBreakoutMeeting();
  const meetingState = useMeetingState();
  const { callTeacherGroups } = meetingState.context;
  const { removeCallTeacherGroups } = useMeetingDispatch();
  const breakoutRoomStorage = window.localStorage;

  const toggleContentSwitch = () => setContentSwitch((prev) => !prev);

  const backToMainMeetingHandler = () => {
    breakoutRoomStorage.setItem(
      'callTeacherData',
      JSON.stringify(callTeacherGroups)
    );
    redirectToMainMeeting();
  };

  const goToBreakoutMeetingHandler = (breakoutId) => {
    removeCallTeacherGroups({ breakoutRoomId: breakoutId });
    const callTeacherGroupsData = callTeacherGroups.filter(
      (group) => group.breakoutRoomId !== breakoutId
    );
    breakoutRoomStorage.setItem(
      'callTeacherData',
      JSON.stringify(callTeacherGroupsData)
    );
    goToBreakoutMeeting({ breakoutId });
  };

  return (
    <StyledSubRoomInvite>
      {inviteData.length === 1 && (
        <StyledSingleInvite>
          <div className="title">
            {trans('__groupDiscussionClosingSoon', {
              groupName: inviteData[0].groupName,
            })}
          </div>
          <div className="action">
            <div className="btn primary" onClick={backToMainMeetingHandler}>
              {trans('__returnToMainClassroom')}
            </div>
            <div
              className="btn"
              onClick={() =>
                goToBreakoutMeetingHandler(inviteData[0].breakoutRoomId)
              }
            >
              {trans('__joinDiscussion')}
            </div>
          </div>
        </StyledSingleInvite>
      )}
      {inviteData.length > 1 && (
        <StyledMultipleInvite isOpen={contentSwitch}>
          <div className="head">
            <div className="title">
              {trans('__receivedMultipleJoinRequests')}
            </div>
            <div className="action">
              <div className="btn primary" onClick={backToMainMeetingHandler}>
                {trans('__returnToMainClassroom')}
              </div>
              <div className="btn" onClick={toggleContentSwitch}>
                {contentSwitch ? trans('__hide') : trans('__show')}
              </div>
            </div>
          </div>
          {contentSwitch && (
            <div className="content">
              {inviteData.map((group) => {
                return (
                  <div className="inviteList" key={group.breakoutRoomId}>
                    <div className="listTitle">
                      {group.groupName}
                      {trans('__groupDiscussionInvitation')}
                    </div>
                    <div className="listAction">
                      <div
                        className="btn"
                        onClick={() =>
                          goToBreakoutMeetingHandler(group.breakoutRoomId)
                        }
                      >
                        {trans('__joinDiscussion')}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </StyledMultipleInvite>
      )}
    </StyledSubRoomInvite>
  );
};

SubRoomInvite.propTypes = {
  inviteData: PropTypes.array,
};
export const TeacherSubRoomNotification = ({ className, title }) => {
  const { redirectToMainMeeting, isBreakoutRoom } = useBreakoutMeeting();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();
  const meetingState = useMeetingState();
  const { callTeacherGroups } = meetingState.context;
  const { breakoutRoomIsLeaving } = useGroupContext();
  const breakoutRoomStorage = window.localStorage;

  const backToMainMeetingHandler = () => {
    breakoutRoomStorage.setItem(
      'callTeacherData',
      JSON.stringify(callTeacherGroups)
    );
    redirectToMainMeeting();
  };

  useEffect(() => {
    if (breakoutRoomIsLeaving) {
      startCountDown();
    }
  }, [breakoutRoomIsLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    if (isBreakoutRoom) {
      redirectToMainMeeting();
    }
  }, [countDownNumber]);

  return (
    <>
      {!breakoutRoomIsLeaving && callTeacherGroups.length === 0 && (
        <StyledSubRoomNotification
          className={className}
          isLeaving={breakoutRoomIsLeaving}
        >
          <div className="title">{title}</div>
          <div className="action">
            <div className="btn primary" onClick={backToMainMeetingHandler}>
              {trans('__returnToMainClassroom')}
            </div>
          </div>
        </StyledSubRoomNotification>
      )}
      {breakoutRoomIsLeaving && (
        <StyledSubRoomNotification
          className={className}
          isLeaving={breakoutRoomIsLeaving}
        >
          <div className="title">
            {trans('__groupDiscussionClosingSoon', { countDownNumber })}
          </div>
          <div className="action">
            <div className="btn primary" onClick={backToMainMeetingHandler}>
              {trans('__returnToMainClassroom')}
            </div>
          </div>
        </StyledSubRoomNotification>
      )}
      {!breakoutRoomIsLeaving && callTeacherGroups.length > 0 && (
        <SubRoomInvite inviteData={callTeacherGroups} />
      )}
    </>
  );
};

TeacherSubRoomNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export const StudentMainRoomNotification = ({
  className,
  roomInfo,
  isLeaving = false,
}) => {
  const { redirectToBreakoutMeeting } = useBreakoutMeeting();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();
  const { breakoutMeetingEnd } = useMeetingDispatch();
  const { BreakoutRoomState, changeBreakoutRoomStep } = useGroupContext();

  useEffect(() => {
    if (isLeaving) {
      startCountDown();
    }
  }, [isLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    breakoutMeetingEnd();
    changeBreakoutRoomStep(BreakoutRoomState.setting);
  }, [countDownNumber]);

  return (
    <StyledBreakoutRoomNotification className={className} isLeaving={isLeaving}>
      {!isLeaving && (
        <>
          <div className="title">
            {trans('__youHaveBeenInvited')}
            {roomInfo?.groupName}
          </div>
          <div className="action">
            <div className="btn primary" onClick={redirectToBreakoutMeeting}>
              {trans('__join')}
            </div>
          </div>
        </>
      )}
      {isLeaving && (
        <div className="title">
          {trans('__groupDiscussionClosingSoon', { countDownNumber })}
        </div>
      )}
    </StyledBreakoutRoomNotification>
  );
};

StudentMainRoomNotification.propTypes = {
  className: PropTypes.string,
  roomInfo: PropTypes.object,
  onGroupInvite: PropTypes.func,
  isLeaving: PropTypes.bool,
};

export const StudentSubRoomNotification = ({
  className,
  title,
  onGroupInvite,
  isLeaving = false,
}) => {
  const { redirectToMainMeeting } = useBreakoutMeeting();
  const { countDownNumber, startCountDown, endCountDown } = useCountDown();
  const { breakoutMeetingEnd } = useMeetingDispatch();

  useEffect(() => {
    if (isLeaving) {
      startCountDown();
    }
  }, [isLeaving]);

  useEffect(() => {
    if (countDownNumber !== 0) return;
    endCountDown();
    breakoutMeetingEnd();
  }, [countDownNumber]);

  return (
    <StyledSubRoomNotification className={className} isLeaving={isLeaving}>
      {!isLeaving && <div className="title">{title}</div>}
      {isLeaving && (
        <div className="title">
          {trans('__groupDiscussionClosingSoon', { countDownNumber })}
        </div>
      )}
      <div className="action">
        {!isLeaving && (
          <div className="btn" onClick={onGroupInvite}>
            {trans('__inviteTeacherToJoin')}
          </div>
        )}
        <div className="btn primary" onClick={redirectToMainMeeting}>
          {trans('__returnToMainClassroom')}
        </div>
      </div>
    </StyledSubRoomNotification>
  );
};

StudentSubRoomNotification.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  onGroupInvite: PropTypes.func,
  isLeaving: PropTypes.bool,
};

const MemberList = ({ members, onClick }) => {
  const meetingState = useMeetingState();
  const { stagedAttendeeIds } = meetingState.context;
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = useMemo(
    () => attendees.filter((attendee) => attendee.role === Roles.Student),
    [attendees]
  );

  return (
    <StyledMemberList>
      {members &&
        members
          .filter((member) => member.chimeAttendeeId)
          .map((member) => {
            const { userId, userName, chimeAttendeeId } = member;
            const isOnStaged = stagedAttendeeIds.includes(chimeAttendeeId);
            const isExist = students
              .map((student) => student.chimeAttendeeId)
              .includes(chimeAttendeeId);

            return (
              <div className="list" key={userId}>
                <div className="content">{userName}</div>
                <div className="action">
                  {isExist && (
                    <button
                      onClick={() =>
                        onClick({ isOnStaged, attendeeId: chimeAttendeeId })
                      }
                      className={`btn ${isOnStaged ? 'isOnStaged' : ''}`}
                    >
                      {isOnStaged ? trans('__onStage') : trans('__goOnStage')}
                    </button>
                  )}
                </div>
              </div>
            );
          })}
    </StyledMemberList>
  );
};

MemberList.propTypes = {
  members: PropTypes.array,
  onClick: PropTypes.func,
};

export const BreakoutRoomResultNotification = ({ className, service }) => {
  const {
    breakoutRoomResultState,
    breakoutRoomResultActions,
    breakoutRoomResultData,
    resultReviewActions,
    setBreakoutRoomIsEndState,
    breakoutRoomResultGroup,
    setBreakoutRoomResultGroup,
    breakoutRoomResultGroupInfo,
    setBreakoutRoomResultGroupInfo,
    removeSceneQueue,
    sceneTabsData,
  } = useGroupContext();
  const { roster } = useRosterState();
  const { stageAttendee, unstageAttendee, muteAttendee, unMuteAttendee } =
    useMeetingDispatch();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const students = useMemo(
    () => attendees.filter((attendee) => attendee.role === Roles.Student),
    [attendees]
  );
  const [membersData, setMembersData] = useState([]);
  const [sceneStateIndex, setSceneStateIndex] = useState(0);
  const { scaleToFit } = useZoomControl();

  const closeHandler = () => {
    const { queue, sceneMap } = sceneTabsData;
    const resultIndex = queue.findIndex(() => breakoutRoomResultData.courseId);

    const isExistQueue = queue.find(() => breakoutRoomResultData.courseId);

    if (isExistQueue) {
      let nextBookId = '';

      if (queue.length === 1) {
        nextBookId = 'init';
      } else if (resultIndex !== queue.length - 1) {
        nextBookId = queue[resultIndex + 1];
        const nextPage = sceneMap[nextBookId].page;
        service.setScenePath(`/${nextBookId}/${nextPage}`);
      } else {
        nextBookId = queue[resultIndex - 1];
        const nextPage = sceneMap[nextBookId].page;
        service.setScenePath(`/${nextBookId}/${nextPage}`);
      }
      removeSceneQueue({ bookId: breakoutRoomResultData.courseId, nextBookId });
      scaleToFit();
    }

    service.removeScenes(`/${breakoutRoomResultData.courseId}`);
    resultReviewActions.setFalse();
    breakoutRoomResultActions.setFalse();
    setBreakoutRoomIsEndState();
    setBreakoutRoomResultGroup([]);
    setBreakoutRoomResultGroupInfo({});
  };

  const backTotalResultHandler = () => {
    breakoutRoomResultActions.setTrue();
  };

  const combineMembers = (members) => {
    return members.map((member) => {
      const { userId } = member;
      return {
        ...member,
        chimeAttendeeId:
          students.filter((student) => student.userId === userId)[0]
            ?.chimeAttendeeId || '',
      };
    });
  };

  const toggleStageHandler = ({ isOnStaged, attendeeId }) => {
    if (isOnStaged) {
      muteAttendee({ attendeeId });
      unstageAttendee({ attendeeId });
      return;
    }
    isOnStaged ? muteAttendee({ attendeeId }) : unMuteAttendee({ attendeeId });
    isOnStaged
      ? unstageAttendee({ attendeeId })
      : stageAttendee({ attendeeId });
  };

  useEffect(() => {
    if (!breakoutRoomResultGroupInfo) return;
    const members = breakoutRoomResultGroupInfo?.members || [];
    setMembersData(members);
  }, [breakoutRoomResultGroupInfo]);

  useEffect(() => {
    if (breakoutRoomResultGroup.length === 0) return;
    setBreakoutRoomResultGroupInfo(breakoutRoomResultGroup[sceneStateIndex]);
  }, [sceneStateIndex]);

  useEffect(() => {
    if (!service) return;
    service.callbacks.on('onRoomStateChanged', function (state) {
      if (state.sceneState) {
        setSceneStateIndex(state.sceneState.index);
      }
    });
  }, [service]);

  return (
    <StyledBreakoutRoomResultNotification className={className}>
      <Box display="flex" alignItems="center">
        {breakoutRoomResultState && (
          <div className="title">
            {trans('__groupDiscussionResults')}
            {` - ${breakoutRoomResultData.startTime}`}
          </div>
        )}
        {!breakoutRoomResultState && (
          <>
            <div className="title">
              {breakoutRoomResultGroupInfo?.groupName}{' '}
              {trans('__discussionResults')}
            </div>
            <Box ml={2}>
              <Popover
                arrow={true}
                trigger="click"
                placement="bottomLeft"
                content={
                  <MemberList
                    members={membersData}
                    onClick={toggleStageHandler}
                  />
                }
              >
                <div className="memberTip">
                  <Icon name="InfoSolid" color="#fff" size="xxs" />
                </div>
              </Popover>
            </Box>
          </>
        )}
      </Box>
      <div className="action">
        {!breakoutRoomResultState && (
          <div className="btn primary" onClick={backTotalResultHandler}>
            {trans('__viewAllGroupResults')}
          </div>
        )}
        {breakoutRoomResultState && (
          <div className="btn primary" onClick={closeHandler}>
            {trans('__closeDiscussionResults')}
          </div>
        )}
      </div>
    </StyledBreakoutRoomResultNotification>
  );
};

BreakoutRoomResultNotification.propTypes = {
  className: PropTypes.string,
  service: PropTypes.object,
};
