import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@onedesign/icon';
import { StyledNetworkNotification } from './NetworkNotification.style';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`components.networkNotification.${key}`, '', params);
};

const colorMap = {
  poor: '#FFAB00',
  stop: '#FF5630',
};

export const NetworkNotification = ({ networkStatus, onClose }) => {
  return (
    <StyledNetworkNotification
      data-testid="NetworkNotification"
      bgColor={colorMap[networkStatus]}
    >
      <div className="content">
        {networkStatus === 'poor' && trans('__theNetworkConnectionIsUnstable')}
        {networkStatus === 'stop' &&
          trans('__theNetworkConnectionIsUnstableAndVideoTurnedOff')}
      </div>
      <div className="close" onClick={onClose}>
        <Icon name="XmarkOutline" size="xs" color="#fff" />
      </div>
    </StyledNetworkNotification>
  );
};

NetworkNotification.propTypes = {
  networkStatus: PropTypes.string,
  onClose: PropTypes.func,
};
