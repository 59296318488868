import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { Roles, API_STATUS } from 'constants/index';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box } from '@oneboard/ui-components';
import Icon from '@onedesign/icon';
import {
  getGroupAlbums as getGroupAlbumsApi,
  getSessionInfo as getSessionInfoApi,
  getHomeworks as getHomeworksApi,
  getGroupAlbumEntries as getGroupAlbumEntriesApi,
} from 'services/live';
import { LoadingOutlined } from '@ant-design/icons';
import { Pagination } from 'antd';
import { useZoomControl } from 'utils/hooks/useZoomControl';
import { useGroupContext } from 'providers/GroupProvider';
import {
  StyledStudentInfoBox,
  StyledUserProfile,
  StyledHomework,
  StyledHomeworkList,
  StyledAlbum,
  StyledFirstAlbum,
  StyledStatusLabel,
} from './StudentInfoBox.style';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { t } from 'utils/i18n';

SwiperCore.use([Navigation]);

const trans = (key, params) => {
  return t(`containers.studentInfoBox.${key}`, '', params);
};
const LIVEONECLASS_URL = process.env.REACT_APP_LIVEONECLASS_DOMAIN;

const categoryText = {
  image: trans('__post', 'ảnh'),
  video: trans('__video', 'video'),
  profile: trans('__classImage', 'hình ảnh lớp học'),
};

const STEP_STATE = {
  IDLE: 'IDLE',
  HOMEWORK: 'HOMEWORK',
  ALBUM: 'ALBUM',
};

const sortKey = 'publishedAt';

const PaymentStatusLabel = ({ state = 'unpaid' }) => {
  return (
    <StyledStatusLabel>
      {state === 'paid' && (
        <div className="label paid">{trans('__submitted')}</div>
      )}
      {state === 'late' && (
        <div className="label late">{trans('__lateSubmission')}</div>
      )}
      {state === 'unpaid' && (
        <div className="label unpaid">{trans('__notSubmitted')}</div>
      )}
    </StyledStatusLabel>
  );
};

PaymentStatusLabel.propTypes = {
  state: PropTypes.string,
};

const createScenes = async (pages) => {
  const result = await Promise.all(
    pages.map(async (page, index) => {
      const img = new Image();
      img.src = page.attachmentUrl;
      await img.decode();

      return {
        name: '' + (index + 1),
        ppt: {
          src: page.attachmentUrl,
          width: img.width,
          height: img.height,
        },
      };
    })
  );
  return result;
};

const Homework = ({ id: studentId, data: homeworkData = {} }) => {
  return (
    <StyledHomework>
      <div className="swiper-btn button-prev homework">
        <Icon name="AngleLeftSolid" size="xs" color="#8A94A6" />
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop
        navigation={{
          nextEl: '.button-next.homework',
          prevEl: '.button-prev.homework',
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
      >
        {homeworkData.missions.map((data) => {
          const paymentStatus = data.submittedUsers.find(
            (id) => id === studentId
          )
            ? 'paid'
            : 'unpaid';
          const workUrl = `${LIVEONECLASS_URL}/home/${data.groupId}/homeworks/${data.id}/submitted`;

          return (
            <SwiperSlide key={data.id}>
              <a href={workUrl} target="_blank" rel="noreferrer noopener">
                <div className="workCard">
                  <div className="title">{data.title}</div>
                  <div className="paymentStatus">
                    <PaymentStatusLabel state={paymentStatus} />
                    <div className="icon">
                      <Icon
                        name="UpRightFromSquareSolid"
                        size="xs"
                        color="#8A94A6"
                      />
                    </div>
                  </div>
                </div>
              </a>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="swiper-btn button-next homework">
        <Icon name="AngleRightSolid" size="xs" color="#8A94A6" />
      </div>
    </StyledHomework>
  );
};
Homework.propTypes = {
  id: PropTypes.string,
  data: PropTypes.object,
};

const HomeworkList = ({
  id: studentId,
  sessionData = {},
  data: homeworkData,
  onError,
}) => {
  const [listData, setListData] = useState(homeworkData.missions);

  const onPaginationChange = async (page, pageSize) => {
    const { data, status, error } = await getHomeworksApi(sessionData.groupId)({
      nowPage: page - 1,
      rowsPage: 10,
      sortKey,
    });

    try {
      if (status === API_STATUS.FAILURE) throw error.message;
      setListData(data.missions);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <StyledHomeworkList>
      <div className="listbox">
        <div className="main">
          {listData.map((data) => {
            const paymentStatus = data.submittedUsers.find(
              (id) => id === studentId
            )
              ? 'paid'
              : 'unpaid';
            const workUrl = `${LIVEONECLASS_URL}/home/${data.groupId}/homeworks/${data.id}/submitted`;

            return (
              <a
                className="workItem"
                href={workUrl}
                target="_blank"
                rel="noreferrer noopener"
                key={data.id}
              >
                <div className="title">
                  <div className="text">{data.title}</div>
                  <div className="icon">
                    <Icon
                      name="UpRightFromSquareSolid"
                      size="xs"
                      color="#8A94A6"
                    />
                  </div>
                </div>
                <div className="paymentStatus">
                  <PaymentStatusLabel state={paymentStatus} />
                </div>
              </a>
            );
          })}
        </div>
        <div className="action">
          <Pagination
            defaultCurrent={1}
            total={homeworkData.total}
            onChange={onPaginationChange}
          />
        </div>
      </div>
    </StyledHomeworkList>
  );
};

HomeworkList.propTypes = {
  id: PropTypes.string,
  sessionData: PropTypes.object,
  data: PropTypes.object,
  onError: PropTypes.func,
};

const AlbumCard = ({ data, onClickHandler }) => {
  return (
    <div className="albumCard" onClick={onClickHandler}>
      <div className="cover">
        <img
          src={data.path ? data.path : '/organization.png'}
          alt={data.name}
        />
        <div className="importCover">
          <Icon name="PlusCircleSolid" size="sm" color="#EEF1F5" />
          <div className="text">{trans('__importWhiteboard')}</div>
        </div>
      </div>
      <div className="title">{data.name}</div>
      <div className="count">{data.totalEntryCount}</div>
    </div>
  );
};

AlbumCard.propTypes = {
  data: PropTypes.object,
  onClickHandler: PropTypes.func,
};

const FirstAlbum = ({ data: albumData, onClickHandler }) => {
  const importAlbumHandler = ({ id, label, total }) => {
    if (total === 0) return;

    onClickHandler({ id, label });
  };

  return (
    <StyledFirstAlbum>
      <div className="swiper-btn button-prev firstAlbum">
        <Icon name="AngleLeftSolid" size="xs" color="#8A94A6" />
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop
        navigation={{
          nextEl: '.button-next.firstAlbum',
          prevEl: '.button-prev.firstAlbum',
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          425: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
        }}
      >
        {albumData &&
          albumData.albums
            .filter((item) => !item.isBanned)
            .map((item) => {
              item.id =
                item.category && item.category !== undefined
                  ? item.category
                  : item.id;
              item.name = item.category
                ? categoryText[item.category]
                : item.name;
              item.path = item.cover
                ? item.category === 'video'
                  ? ''
                  : item.cover?.attachmentUrl || ''
                : '';

              return (
                <SwiperSlide key={item.id}>
                  <AlbumCard
                    data={item}
                    onClickHandler={() =>
                      importAlbumHandler({
                        id: item.id,
                        label: item.name,
                        total: item.totalEntryCount,
                      })
                    }
                  />
                </SwiperSlide>
              );
            })}
      </Swiper>
      <div className="swiper-btn button-next firstAlbum">
        <Icon name="AngleRightSolid" size="xs" color="#8A94A6" />
      </div>
    </StyledFirstAlbum>
  );
};

FirstAlbum.propTypes = {
  data: PropTypes.object,
  onClickHandler: PropTypes.func,
};

const AlbumList = ({ data: albumData, sessionData, onAlbumClick, onError }) => {
  const [listData, setListData] = useState(albumData.albums);

  const onPaginationChange = async (page, pageSize) => {
    const { data, status, error } = await getGroupAlbumsApi(
      sessionData.groupId
    )({ nowPage: page - 1, rowsPage: 10 });

    try {
      if (status === API_STATUS.FAILURE) throw error.message;
      setListData(data.albums);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <StyledAlbum>
      <div className="listbox">
        <div className="main">
          {listData &&
            listData
              .filter((item) => !item.isBanned)
              .map((item) => {
                item.id =
                  item.category && item.category !== undefined
                    ? item.category
                    : item.id;
                item.name = item.category
                  ? categoryText[item.category]
                  : item.name;
                item.path = item.cover
                  ? item.category === 'video'
                    ? ''
                    : item.cover?.attachmentUrl || ''
                  : '';

                return (
                  <AlbumCard
                    data={item}
                    onClickHandler={() =>
                      onAlbumClick({
                        id: item.id,
                        label: item.name,
                        total: item.totalEntryCount,
                      })
                    }
                    key={item.id}
                  />
                );
              })}
        </div>
        <div className="action">
          <Pagination
            defaultCurrent={1}
            total={albumData.total}
            onChange={onPaginationChange}
          />
        </div>
      </div>
    </StyledAlbum>
  );
};

AlbumList.propTypes = {
  data: PropTypes.object,
  sessionData: PropTypes.object,
  onAlbumClick: PropTypes.func,
  onError: PropTypes.func,
};

const UserProfile = ({
  sessionData,
  userInfo,
  albumData,
  homeworkData,
  onAlbumClick,
  goWork,
  goAlbum,
}) => {
  const { name, userId, color } = userInfo;

  return (
    <StyledUserProfile color={color}>
      <div className="profile">
        <Box>
          <div className="name">{name}</div>
          <div className="className">[{sessionData.groupName}]</div>
        </Box>
      </div>
      <div className="itemWrap">
        <div className="head">
          <div className="title">{trans('__assignment')}</div>
          <button className="more" onClick={goWork}>
            {trans('__viewAll')}
          </button>
        </div>
        <div className="content">
          <Homework id={userId} data={homeworkData} />
        </div>
      </div>
      <div className="itemWrap">
        <div className="head">
          <div className="title">{trans('__classAlbum')}</div>
          <button className="more" onClick={goAlbum}>
            {trans('__viewAll')}
          </button>
        </div>
        <div className="content">
          <FirstAlbum
            data={albumData}
            sessionData={sessionData}
            onClickHandler={onAlbumClick}
          />
        </div>
      </div>
    </StyledUserProfile>
  );
};

UserProfile.propTypes = {
  sessionData: PropTypes.object,
  userInfo: PropTypes.object,
  albumData: PropTypes.object,
  homeworkData: PropTypes.object,
  room: PropTypes.object,
  onAlbumClick: PropTypes.func,
  goWork: PropTypes.func,
  goAlbum: PropTypes.func,
};

export const StudentInfoBox = ({ className, id, room, onClose }) => {
  const { meetingId } = useParams();
  const { roster } = useRosterState();
  const attendees = useMemo(() => Object.values(roster), [roster]);
  const studentProfile = attendees.filter(
    (attendee) => attendee.role === Roles.Student && attendee.userId === id
  )[0];
  const { scaleToFit } = useZoomControl();
  const { addSceneQueue } = useGroupContext();
  const [sessionData, setSessionData] = useState(null);
  const [albumData, setAlbumData] = useState(null);
  const [missionData, setMissionData] = useState(null);
  const [step, setStep] = useState(STEP_STATE.IDLE);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const init = async () => {
    setLoading(true);
    const sessionInfo = await getSessionInfoApi({ sessionId: meetingId });

    try {
      const { data, status: sessionStatus, error: sessionError } = sessionInfo;
      if (sessionStatus === API_STATUS.FAILURE) throw sessionError.message;

      const sessionData = data[0];

      const {
        data: homeworksData,
        status: homeworkStatus,
        error: homeworkError,
      } = await getHomeworksApi(sessionData.groupId)({
        nowPage: 0,
        rowsPage: 10,
        sortKey,
      });

      if (homeworkStatus === API_STATUS.FAILURE) throw homeworkError.message;

      const {
        data: albumData,
        status: albumStatus,
        error: albumError,
      } = await getGroupAlbumsApi(sessionData.groupId)({
        nowPage: 0,
        rowsPage: 10,
      });

      if (albumStatus === API_STATUS.FAILURE) throw albumError.message;

      setSessionData(sessionData);
      setMissionData(homeworksData);
      setAlbumData(albumData);
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  const onAlbumIdChangeHandler = async ({ id: albumId, label }) => {
    const {
      data,
      status,
      error: groupAlbumEntriesError,
    } = await getGroupAlbumEntriesApi({
      classId: sessionData.groupId,
      albumId,
    })({ nowPage: 0, rowsPage: 100 });

    try {
      if (status === API_STATUS.FAILURE) throw groupAlbumEntriesError.message;
      const { entries } = data;
      const scenes = await createScenes(entries);

      room.putScenes(`/${albumId}`, scenes);
      room.setScenePath(`/${albumId}/1`);
      scaleToFit();
      room.dispatchMagixEvent('changeBookId');
      addSceneQueue({ bookId: albumId, label });
      onClose();
    } catch (error) {
      errorHandler(error);
    }
  };

  const goWorkListHandler = () => setStep(STEP_STATE.HOMEWORK);

  const goAlbumListHandler = () => setStep(STEP_STATE.ALBUM);

  const errorHandler = (msg) => setErrorMsg(msg);

  useEffect(() => {
    init();
  }, []);

  return (
    <StyledStudentInfoBox className={className} data-testid="StudentInfoBox">
      <div className="container">
        <div className="head">
          <div className="title">
            {step !== STEP_STATE.IDLE && (
              <button
                className="btn previous"
                onClick={() => setStep(STEP_STATE.IDLE)}
              >
                <Icon name="AngleLeftSolid" size="xs" color="#8A94A6" />
              </button>
            )}
            {step === STEP_STATE.IDLE && (
              <div className="text">{trans('__studentInfo')}</div>
            )}
            {step === STEP_STATE.HOMEWORK && (
              <div className="text">{trans('__assignment')}</div>
            )}
            {step === STEP_STATE.ALBUM && (
              <div className="text">{trans('__classAlbum')}</div>
            )}
          </div>
          <button className="btn close" onClick={onClose}>
            <Icon name="XmarkOutline" size="xs" color="#8A94A6" />
          </button>
        </div>
        <div className="content">
          {!errorMsg &&
            (loading ? (
              <Box
                display="flex"
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                <LoadingOutlined style={{ color: '#A1A4B1' }} />
              </Box>
            ) : (
              <>
                {step === STEP_STATE.IDLE && sessionData && missionData && (
                  <UserProfile
                    userInfo={studentProfile}
                    sessionData={sessionData}
                    homeworkData={missionData}
                    albumData={albumData}
                    room={room}
                    onAlbumClick={onAlbumIdChangeHandler}
                    goWork={goWorkListHandler}
                    goAlbum={goAlbumListHandler}
                  />
                )}

                {step === STEP_STATE.HOMEWORK && (
                  <div className="listContent">
                    <HomeworkList
                      id={studentProfile.userId}
                      sessionData={sessionData}
                      data={missionData}
                      onError={errorHandler}
                    />
                  </div>
                )}

                {step === STEP_STATE.ALBUM && (
                  <div className="listContent">
                    <AlbumList
                      sessionData={sessionData}
                      data={albumData}
                      onAlbumClick={onAlbumIdChangeHandler}
                      onError={errorHandler}
                    />
                  </div>
                )}
              </>
            ))}
          {errorMsg && (
            <Box
              display="flex"
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              style={{ color: '#fff' }}
            >
              {`${trans('__errorMessage')}：${errorMsg}`}
            </Box>
          )}
        </div>
      </div>
    </StyledStudentInfoBox>
  );
};

StudentInfoBox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  room: PropTypes.object,
  onClose: PropTypes.func,
};
