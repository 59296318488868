import {
  useContentShareControls,
  useContentShareState,
} from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.shareButton.${key}`,
    '',
    params
  );
};

export const ShareButton = ({ isTeacherHere }) => {
  const { toggleContentShare } = useContentShareControls();
  const { isLocalUserSharing } = useContentShareState();
  const color = isTeacherHere
    ? '#919EAB'
    : isLocalUserSharing
    ? '#FF9E5F'
    : '#80531E';

  return (
    <Tooltip placement='top' title={trans('__shareScreen')}>
      <div>
        <ToolButton
          isTeacherHere={isTeacherHere}
          icon={
            <Icon
              name={
                isLocalUserSharing ? 'CloseSquareSolid' : 'ArrowSquareUpSolid'
              }
              size='md'
              color={color}
            />
          }
          onClick={toggleContentShare}
        ></ToolButton>
      </div>
    </Tooltip>
  );
};
