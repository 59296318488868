import React, { useState } from 'react';
import Icon from '@onedesign/icon';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useCourseInfo } from 'providers/CourseInfoProvider';
import { BookList } from 'containers';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.materialButton.${key}`,
    '',
    params
  );
};

export const MaterialButton = () => {
  const { courseInfo } = useCourseInfo();
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = (e) => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={trans('__textBook')}>
        <div>
          <ToolButton
            icon={<Icon name='BooksSolid' size='md' color='#4246A8' />}
            onClick={showModal}
          ></ToolButton>
        </div>
      </Tooltip>
      <ToolModal
        active={open}
        onClose={handleCancel}
        title={trans('__textBook')}
        height='450px'
        justifyContent='flex-start'
      >
        <BookList courseInfo={courseInfo} width='auto' pd='0' />
      </ToolModal>
    </>
  );
};
