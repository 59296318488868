import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  useRemoteVideoTileState,
  useRosterState,
  useLocalVideo,
  useToggleLocalMute,
  useAttendeeStatus,
} from 'amazon-chime-sdk-component-library-react';
import { Box, Popover } from '@oneboard/ui-components';
import { Roles } from 'constants/index';
import {
  LocalVideo,
  RemoteVideo,
  useMeetingState,
  Reward,
  MeetingStates,
  MeetingRoles,
  useMeetingDispatch,
  useLocalToolboxAuth,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { VideoPopover } from 'components';
import { VideoBox } from 'containers';
import lottie from 'lottie-web';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useInsertPicture } from 'providers/InsertPictureProvider';
import {
  Insert_Picture_State,
  Insert_Picture_Event,
} from 'machines/InsertPictureMachine';
import {
  StyledStudentVideoContainer,
  PopoverIcon,
  StyledNameBox,
  StyledStagedAnimation,
  StyledStagedAnimationBox,
} from './StudentVideoContainer.style';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.studentVideoContainer.${key}`, '', params);
};

const StudentVideoPopoverContainer = () => {
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { isToolboxEnabled } = useLocalToolboxAuth();
  const { isBreakoutRoom } = useBreakoutMeeting();

  const videoEnabledHandler = () => {
    if (!isBreakoutRoom) return;
    toggleVideo();
  };

  const muteHandler = () => {
    if (!isBreakoutRoom) return;
    toggleMute();
  };

  return (
    <VideoPopover placement="left">
      <PopoverIcon onClick={videoEnabledHandler}>
        {isVideoEnabled ? (
          <Icon name="VideoSolid" />
        ) : (
          <Icon name="VideoSlashSolid" color="#F94144" />
        )}
      </PopoverIcon>
      <PopoverIcon onClick={muteHandler}>
        {muted ? (
          <Icon name="MicrophoneAltSlashSolid" color="#F94144" />
        ) : (
          <Icon name="MicrophoneAltSolid" />
        )}
      </PopoverIcon>
      {!isBreakoutRoom && (
        <PopoverIcon>
          {isToolboxEnabled ? (
            <Icon name="ChalkboardOutline" />
          ) : (
            <Icon name="ChalkboardSlashSolid" />
          )}
        </PopoverIcon>
      )}
    </VideoPopover>
  );
};

const TileBox = ({ roster, selfAttendeeId }) => {
  return (
    <div className="tileBox">
      <StyledNameBox bgColor={roster[selfAttendeeId]?.color}>
        {roster[selfAttendeeId]?.name?.slice(0, 1)}
      </StyledNameBox>
    </div>
  );
};

TileBox.propTypes = {
  roster: PropTypes.object,
  selfAttendeeId: PropTypes.string,
};

const StagedAnimation = () => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/student_stage.json',
    });
  }, [ref]);

  return <StyledStagedAnimation ref={ref} />;
};

export const StudentVideoContainer = ({ className }) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const { pauseReward } = useMeetingDispatch();

  const { attendeeIdToTileId } = useRemoteVideoTileState();
  const meetingState = useMeetingState();
  const {
    attendeeId: selfAttendeeId,
    objectedAttendeeIds,
    stagedAttendeeIds,
    videoIsMirroring,
    userId,
  } = meetingState.context;
  const { isVideoEnabled, toggleVideo } = useLocalVideo();
  const { isBreakoutRoom } = useBreakoutMeeting();
  const advisor = attendees.find((attendee) => attendee.role === Roles.Advisor);
  const teacher = attendees.find((attendee) => attendee.role === Roles.Teacher);
  const teacherIsExist = !!teacher;
  const advisorIsExist = !!advisor;
  const { videoEnabled: advisorVideoEnabled } = useAttendeeStatus(
    advisor && advisor?.chimeAttendeeId
  );

  const { state: insertPictureState, send: insertPictureSend } =
    useInsertPicture();
  const isEnd = insertPictureState.matches(Insert_Picture_State.End);

  const isRewardActive = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.RewardAnimationState}.${MeetingStates.Active}`,
  });

  const rewardCompletedHandler = useCallback(() => {
    pauseReward({ userId });
  }, []);

  useEffect(() => {
    if (isEnd && !isVideoEnabled) {
      toggleVideo();
      insertPictureSend({
        type: Insert_Picture_Event.Idle,
      });
    }
  }, [isEnd, isVideoEnabled]);
  return (
    <StyledStudentVideoContainer
      className={className}
      data-testid="StudentVideoContainer"
    >
      {((teacherIsExist && advisorIsExist) || teacherIsExist) && (
        <Box position="relative" className="videoBoxWrap">
          <VideoBox
            attendeeId={teacher.chimeAttendeeId}
            key={teacher.chimeAttendeeId}
            isRatio
          >
            <RemoteVideo
              isShowNameplate={objectedAttendeeIds.includes(
                teacher.chimeAttendeeId
              )}
              attendeeId={teacher.chimeAttendeeId}
              tileId={attendeeIdToTileId[teacher.chimeAttendeeId]}
              isRatio
            />
          </VideoBox>
        </Box>
      )}
      {!teacherIsExist && advisorIsExist && advisorVideoEnabled && (
        <Box position="relative" className="videoBoxWrap">
          <VideoBox
            attendeeId={advisor.chimeAttendeeId}
            key={advisor.chimeAttendeeId}
            isRatio
          >
            <RemoteVideo
              isShowNameplate={objectedAttendeeIds.includes(
                advisor.chimeAttendeeId
              )}
              attendeeId={advisor.chimeAttendeeId}
              tileId={attendeeIdToTileId[advisor.chimeAttendeeId]}
              isRatio
            />
          </VideoBox>
        </Box>
      )}
      {
        <Popover
          placement="left"
          className="customPopover"
          key={selfAttendeeId}
          content={<StudentVideoPopoverContainer />}
        >
          <Box
            position="relative"
            className={`videoBoxWrap ${isBreakoutRoom ? 'isBreakoutRoom' : ''}`}
            mt={(teacherIsExist || advisorIsExist) && 3}
          >
            {!isBreakoutRoom ? (
              stagedAttendeeIds.includes(selfAttendeeId) ? (
                <StyledStagedAnimationBox>
                  <div className="boxWrap">
                    <StagedAnimation />
                    <div className="text">{trans('__invitingToStage')}</div>
                  </div>
                </StyledStagedAnimationBox>
              ) : (
                <VideoBox
                  attendeeId={selfAttendeeId}
                  isStudent
                  userId={userId}
                  isRatio
                >
                  <LocalVideo
                    isShowNameplate={stagedAttendeeIds.includes(selfAttendeeId)}
                    className={!videoIsMirroring ? 'isFlip' : ''}
                    attendeeId={selfAttendeeId}
                    isRatio
                  />
                </VideoBox>
              )
            ) : (
              <VideoBox
                attendeeId={selfAttendeeId}
                key={selfAttendeeId}
                userId={userId}
                isRatio
              >
                <LocalVideo
                  isShowNameplate={
                    stagedAttendeeIds.includes(selfAttendeeId) ||
                    objectedAttendeeIds.includes(selfAttendeeId)
                  }
                  className={!videoIsMirroring ? 'isFlip' : ''}
                  attendeeId={selfAttendeeId}
                  isRatio
                />
              </VideoBox>
            )}
          </Box>
        </Popover>
      }
      {isRewardActive && (
        <Reward rewardType="Trophy" onComplete={rewardCompletedHandler} />
      )}
    </StyledStudentVideoContainer>
  );
};

StudentVideoContainer.propTypes = {
  className: PropTypes.string,
};
