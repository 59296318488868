import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useLocalVideo,
  useRosterState,
  useAudioVideo,
} from 'amazon-chime-sdk-component-library-react';
import {
  useMeetingDispatch,
  useMeetingState,
  ChatRoom,
} from '@oneboard/meeting';
import {
  Canvas,
  useCanvasState,
  useCanvasDispatch,
  CanvasStates,
} from '@oneboard/whiteboard';
import { Box, Modal, Popover } from '@oneboard/ui-components';
import {
  PaintToolBar,
  WhiteboardFrame,
  ViewToolBar,
  TeachingMaterialContainer,
  OnebookFrame,
  VideoListCarouselPanel,
  RandomModal,
  QuizModal,
  RecordButton,
} from 'containers';
import UsersModalMap from 'containers/UsersModal';
import { MainLayout } from 'layouts';
import {
  Roles,
  NOTIFICATION_TYPE,
  NOTIFICATION_TYPE_KEY,
} from 'constants/index';
import MainPageHeader from './Header';
import {
  ControlsGroup,
  UiChatRoomLayout,
  StyledGroupPage,
  StyleUsersModalLayout,
  ControlsBar,
  StyledOtherButton,
  OtherOption,
  MuteOption,
} from './Group.style';
import {
  VideoModeControlBar,
  StagedVideo,
  MediaMetrics,
  RaiseHandControl,
  CommentsIcon,
} from 'components';
import Icon from '@onedesign/icon';
import { RewardsProvider } from 'providers/RewardsProvider';
import { useGroupContext } from 'providers/GroupProvider';
import { useVideoListContext } from 'providers/VideoListProvider';
import { useNotification } from 'utils/hooks/useNotification';
import { usePrevious } from 'ahooks';
import { complementarySet } from 'utils/array';
import { useQuizService } from 'utils/hooks/useQuiz';
import { useBitrixService } from 'utils/hooks/useBitrix';
import { t } from 'utils/i18n';

const MuteAllContent = ({ muteDefaultControl }) => {
  const { usersMuteState, usersMuteHandler } = useGroupContext();
  const { muteAllAttendees, unMuteAllAttendees } = useMeetingDispatch();
  const unMuteAllHandler = () => {
    unMuteAllAttendees();
    muteDefaultControl(false);

    let usersKeys = Object.keys(usersMuteState);
    let newUsersState = {};
    usersKeys.map((user) => (newUsersState[user] = false));
    usersMuteHandler(newUsersState);
  };

  const muteAllHandler = () => {
    muteAllAttendees();
    muteDefaultControl(true);

    let usersKeys = Object.keys(usersMuteState);
    let newUsersState = {};
    usersKeys.map((user) => (newUsersState[user] = true));
    usersMuteHandler(newUsersState);
  };

  return (
    <MuteOption>
      <div className="optionList" onClick={unMuteAllHandler}>
        <div className="listIcon">
          <Icon name="MicrophoneAltSolid" />
        </div>
        <div className="listContent">
          {t('mainPage.teacher.group.turnOnAllMicrophone', '開啟全體麥克風')}
        </div>
      </div>
      <div className="optionList" onClick={muteAllHandler}>
        <div className="listIcon">
          <Icon name="MicrophoneAltSlashSolid" />
        </div>
        <div className="listContent">
          {t('mainPage.teacher.group.turnOffAllMicrophone', '關閉全體麥克風')}
        </div>
      </div>
    </MuteOption>
  );
};
MuteAllContent.propTypes = {
  muteDefaultControl: PropTypes.func,
};

const MuteAll = ({ muteDefault, muteDefaultControl }) => {
  return (
    <Popover
      content={<MuteAllContent muteDefaultControl={muteDefaultControl} />}
      trigger="click"
      placement="topLeft"
    >
      <Box className="controlButton">
        {muteDefault ? (
          <Icon name="UserVolumeMuteSolid" size="sm" />
        ) : (
          <Icon name="UserVolumeSolid" size="sm" />
        )}
      </Box>
    </Popover>
  );
};

MuteAll.propTypes = {
  muteDefault: PropTypes.bool,
  muteDefaultControl: PropTypes.func,
};

const OtherButton = () => {
  const { toggleCarouselPanel } = useGroupContext();
  const { url: bitrixUrl } = useBitrixService();

  return (
    <StyledOtherButton>
      <Popover
        content={
          <OtherOption>
            <div className="otherList" onClick={toggleCarouselPanel}>
              <div className="listIcon">
                <Icon name="UserRotationSolid" />
              </div>
              <div className="listContent">
                {t('mainPage.teacher.group.takeTurns', '輪流上台')}
              </div>
            </div>
            <a
              className="otherList"
              href={bitrixUrl}
              target="_blank"
              rel="noreferrer"
            >
              <div className="listIcon">
                <Icon name="HeadsetSolid" />
              </div>
              <div className="listContent">
                {t('mainPage.teacher.group.contactCustomerService', '聯絡客服')}
              </div>
            </a>
          </OtherOption>
        }
        trigger="click"
      >
        <Box className="controlButton">
          <Icon name="EllipsisVMediumSolid" size="sm" />
        </Box>
      </Popover>
    </StyledOtherButton>
  );
};

export const Group = ({ role = Roles.Teacher }) => {
  const [bookId, setBookId] = useState('');
  const meetingState = useMeetingState();
  const audioVideo = useAudioVideo();
  const {
    stagedAttendeeIds,
    roomId,
    whiteboardingAttendeeIds,
    raisedHandAttendeeIds,
    userName,
  } = meetingState.context;
  const {
    clearStageAttendee,
    unstageAttendee,
    unMuteAttendee,
    muteAttendee,
    endWhiteboard,
  } = useMeetingDispatch();
  const { openNotification } = useNotification();
  const {
    onstageFullModalState,
    toggleStageFullModal,
    usersModalState,
    toggleUsersModal,
    carouselPanelState,
    toggleCarouselPanel,
    usersMuteHandler,
    removeUsersMute,
    isRandomModalShow,
    toggleRandomModal,
  } = useGroupContext();
  const { videoListCarouselStart, videoListCarouselStop } =
    useVideoListContext();
  const canvasState = useCanvasState();
  const { teachingMaterialState, chatRoomState } = canvasState.context;

  const [stagedVideoObjectMode, setStagedVideoObjectMode] = useState(false);

  const [videos, setVideos] = useState([]);
  const [audios, setAudios] = useState([]);
  const [iframeGroup, setIframeGroup] = useState([]);

  const { loadTeachingMaterials, toggleChatRoom } = useCanvasDispatch();
  const loadTeachingMaterialsHandler = (materials) => {
    switch (materials.type) {
      case 'image':
        loadTeachingMaterials({ materials: materials.sources });
        break;
      case 'video':
        setVideos((prev) => [...prev, ...[materials]]);
        break;
      case 'audio':
        setAudios((prev) => [...prev, ...[materials]]);
        break;
      case 'iframe':
        setIframeGroup((prev) => [...prev, ...[materials]]);
        break;
      case 'onebook':
        setBookId(materials.bookId);
        break;
      default:
        break;
    }
  };

  const changeVideoModeHandler = (mode) =>
    setStagedVideoObjectMode(mode === 'object' ? true : false);

  const closeTeachingMaterialHandler = (material) => {
    switch (material.type) {
      case 'video':
        setVideos((prev) =>
          prev.filter((item) => item.sources !== material.sources)
        );
        break;
      case 'audio':
        setAudios((prev) =>
          prev.filter((item) => item.sources !== material.sources)
        );
        break;
      case 'iframe':
        setIframeGroup((prev) =>
          prev.filter((item) => item.sources !== material.sources)
        );
        break;
      default:
        break;
    }
  };

  const { isVideoEnabled, toggleVideo } = useLocalVideo();

  useEffect(() => {
    if (!isVideoEnabled && role === Roles.Teacher) {
      toggleVideo();
    }
  }, []);

  const { roster } = useRosterState();

  const whiteboardTargetId =
    whiteboardingAttendeeIds.length === 1 ? whiteboardingAttendeeIds[0] : '';

  const mainPageRef = useRef();

  const attendees = Object.values(roster);
  const students = attendees.filter(
    (attendee) => attendee.role === Roles.Student
  );

  const raisedHandPrevious = usePrevious(raisedHandAttendeeIds) || [];
  const studentsPrevious = usePrevious(students) || [];

  const notificationRaisedHandHandler = () => {
    if (raisedHandAttendeeIds.length === 0) return;
    if (raisedHandAttendeeIds.length < raisedHandPrevious.length) return;

    const complementarySetResult = complementarySet(
      raisedHandAttendeeIds,
      raisedHandPrevious
    );

    complementarySetResult.map((raisedHandAttendeeId) =>
      openNotification({
        name: roster[raisedHandAttendeeId]?.name,
        type: NOTIFICATION_TYPE.RAISED_HAND,
      })
    );
  };

  const notificationStudentNumberChangeHandler = () => {
    const complementarySetResult = complementarySet(students, studentsPrevious);

    if (students.length > studentsPrevious.length) {
      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.ENTER,
          key: NOTIFICATION_TYPE_KEY.ENTER,
        })
      );
    } else {
      const isStudentOnStaged = stagedAttendeeIds.includes(
        complementarySetResult[0]?.chimeAttendeeId
      );

      if (isStudentOnStaged) {
        unstageAttendee({
          attendeeId: complementarySetResult[0].chimeAttendeeId,
        });
      }

      complementarySetResult.map((student) =>
        openNotification({
          name: student.name,
          type: NOTIFICATION_TYPE.LEAVE,
          key: NOTIFICATION_TYPE_KEY.LEAVE,
        })
      );
    }
  };

  const toggleChatRoomHandler = useCallback(
    () => toggleChatRoom(chatRoomState),
    [chatRoomState]
  );

  const toggleUsersModalHandler = () => {
    toggleUsersModal();
    usersModalState ? videoListCarouselStart() : videoListCarouselStop();
  };

  const [muteDefault, setMuteDefault] = useState(true);
  const muteDefaultHandler = (bool) => setMuteDefault(bool);

  useEffect(() => {
    notificationRaisedHandHandler();
  }, [raisedHandAttendeeIds.length]);

  useEffect(() => {
    notificationStudentNumberChangeHandler();
  }, [students.length]);

  useEffect(() => {
    if (!audioVideo) {
      return;
    }
    const rosterUpdateCallback = (chimeAttendeeId, present) => {
      if (!present) {
        removeUsersMute(chimeAttendeeId);
        return;
      }

      muteDefault
        ? muteAttendee({ attendeeId: chimeAttendeeId })
        : unMuteAttendee({ attendeeId: chimeAttendeeId });

      usersMuteHandler({
        [chimeAttendeeId]: muteDefault,
      });
    };

    audioVideo.realtimeSubscribeToAttendeeIdPresence(rosterUpdateCallback);

    return () => {
      audioVideo.realtimeUnsubscribeToAttendeeIdPresence(rosterUpdateCallback);
    };
  }, [audioVideo, muteDefault]);

  useEffect(() => {
    const unStagedVideoList = stagedAttendeeIds.filter((stagedAttendeeId) =>
      attendees
        .map((attendee) => !attendee.chimeAttendeeId)
        .includes(stagedAttendeeId)
    );
    if (unStagedVideoList.length === 0) return;

    unstageAttendee({ attendeeId: unStagedVideoList[0] });
  }, [roster]);

  const { teacherQuizStateMatches, States } = useQuizService();

  const [isWhiteboardOpen, setIsWhiteboardOpen] = useState(false);
  const handleWhiteboardToolClick = () => {
    setIsWhiteboardOpen((prev) => {
      const isNextOpen = !prev;
      if (!isNextOpen) {
        endWhiteboard();
      }

      return isNextOpen;
    });
  };

  return (
    <RewardsProvider>
      <StyledGroupPage ref={mainPageRef}>
        <MainLayout
          top={<MainPageHeader />}
          left={
            canvasState.matches(CanvasStates.Initialized) ? (
              <PaintToolBar
                bookId={bookId}
                onInsertMedia={loadTeachingMaterialsHandler}
                handleWhiteboardToolClick={handleWhiteboardToolClick}
                isWhiteboardOpen={isWhiteboardOpen}
                loadTeachingMaterials={loadTeachingMaterialsHandler}
              />
            ) : null
          }
          mode="group"
          hasBookId={false}
        >
          {bookId && (
            <OnebookFrame bookId={bookId} onClose={() => setBookId('')} />
          )}
          {stagedAttendeeIds.length > 0 && (
            <StagedVideo
              stagedAttendeeIds={stagedAttendeeIds}
              objectMode={stagedVideoObjectMode}
            />
          )}
          {isWhiteboardOpen && (
            <WhiteboardFrame
              roomId={roomId}
              role={role}
              attendeeId={whiteboardTargetId}
              userName={userName}
              isShowClose={true}
              toggleWhiteboardShow={handleWhiteboardToolClick}
            />
          )}
          <TeachingMaterialContainer
            videos={videos}
            audios={audios}
            iframeGroup={iframeGroup}
            onClose={closeTeachingMaterialHandler}
          />
          {isRandomModalShow && <RandomModal onClose={toggleRandomModal} />}
          {[States.Setting, States.Starting, States.End, States.Reviewing].some(
            teacherQuizStateMatches
          ) && <QuizModal role={Roles.Teacher} />}
          <Canvas />
          <Box
            display="flex"
            alignItem="center"
            position="absolute"
            left="28px"
            bottom="28px"
          >
            <ViewToolBar />
            <Box ml="16px">
              <VideoModeControlBar
                onChangeMode={changeVideoModeHandler}
                clearStage={clearStageAttendee}
              />
            </Box>
          </Box>

          <UiChatRoomLayout active={chatRoomState}>
            <ChatRoom onClose={toggleChatRoomHandler} />
          </UiChatRoomLayout>

          <ControlsGroup>
            <RaiseHandControl />
            <ControlsBar>
              <MuteAll
                muteDefault={muteDefault}
                muteDefaultControl={muteDefaultHandler}
              />
              <Box className="controlButton" onClick={toggleChatRoomHandler}>
                <CommentsIcon isChatRoomOpen={chatRoomState} />
              </Box>
              <Box className="controlButton" onClick={toggleUsersModalHandler}>
                <Icon name="UsersSolid" />
              </Box>
              <MediaMetrics className="controlButton" />
              <Box className="controlButton">
                <OtherButton />
              </Box>
              <Box className="controlButton">
                <RecordButton
                  mode="dark"
                  classType="noSync"
                  placement="top"
                  content={t(
                    'mainPage.teacher.group.askToTurnOnVideo',
                    '開始上課了嗎?\n別忘了按下錄影按鈕，讓學生可以在課後複習本次課程哦！'
                  )}
                />
              </Box>
            </ControlsBar>
          </ControlsGroup>

          {carouselPanelState && (
            <VideoListCarouselPanel onClose={toggleCarouselPanel} />
          )}
          {onstageFullModalState && (
            <Modal
              onClose={toggleStageFullModal}
              header={t('mainPage.teacher.group.warningNotice', '警告通知')}
            >
              {t(
                'mainPage.teacher.group.stageFulled',
                '人數已滿無法再邀請人上台'
              )}
            </Modal>
          )}
          <StyleUsersModalLayout active={usersModalState}>
            <UsersModalMap.UsersModal onClose={toggleUsersModalHandler} />
          </StyleUsersModalLayout>
        </MainLayout>
      </StyledGroupPage>
    </RewardsProvider>
  );
};

Group.propTypes = {
  role: PropTypes.string,
};
