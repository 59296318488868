import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { MeetingProvider } from '@oneboard/meeting';
import { CanvasProvider, WhiteboardProvider } from '@oneboard/whiteboard';
import { DeviceDetectProvider } from '@oneboard/deviceDetect';
// import AuthProvider from 'providers/AuthProvider';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ThemeProvider from 'providers/ThemeProvider';
import { CourseInfoProvider } from 'providers/CourseInfoProvider';
import 'antd/dist/antd.css';
import './index.css';
import { TranslationProvider } from './services/translation';
import { MeetingProvider as ChimeMeetingProvider } from 'amazon-chime-sdk-component-library-react';
import { StreamingProvider } from 'providers/StreamingProvider';

if (
  process.env.REACT_APP_ENV === 'release' ||
  process.env.REACT_APP_ENV === 'uat'
) {
  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: 'https://b25fbcf784534b59a6c2bcb1942410c1@o1420729.ingest.sentry.io/6767407',
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

const meetingConfig = {
  PROJECT_NAME: `oneboard-${process.env.REACT_APP_ENV}`,
  CHIME_API_URL: process.env.REACT_APP_CHIME_API_URL,
  LOG_URL: process.env.REACT_APP_CHIME_LOG_API_URL,
};
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
const root = document.getElementById('root');
const Main = () => (
  // <Beforeunload>
  <BrowserRouter>
    {/* <AuthProvider> */}
    <ThemeProvider>
      <WhiteboardProvider>
        <TranslationProvider>
          <ChimeMeetingProvider>
            <MeetingProvider config={meetingConfig}>
              <CanvasProvider>
                <DeviceDetectProvider>
                  <CourseInfoProvider>
                    <StreamingProvider>
                    <App />
                    </StreamingProvider>
                  </CourseInfoProvider>
                </DeviceDetectProvider>
              </CanvasProvider>
            </MeetingProvider>
          </ChimeMeetingProvider>
        </TranslationProvider>
      </WhiteboardProvider>
    </ThemeProvider>
    {/* </AuthProvider> */}
  </BrowserRouter>
  // </Beforeunload>
);

ReactDOM.render(Main(), root);

reportWebVitals();
