import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMeetingState } from '@oneboard/meeting';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { Roles, ClassType } from 'constants/index';
import { useQuery } from 'utils/hooks/useQuery';
import { Box } from '@oneboard/ui-components';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import RecordButton from './component/RecordButton';
import ShareButton from './component/ShareButton';
import PerformanceButton from './component/PerformanceButton';
import MaterialButton from './component/MaterialButton';
import PrivateMessageButton from './component/PrivateMessageButton';
import ServiceButton from './component/ServiceButton';
import SettingButton from './component/SettingButton';
import RewardButton from './component/RewardButton';
import QuestionButton from './component/QuestionButton';
import ParticipantButton from './component/ParticipantButton';
import TakeTurnsButton from './component/TakeTurnsButton';
import { BreakoutRoomButton } from './component/BreakoutRoomButton/BreakoutRoomButton';
import RewardCount from './component/RewardCount';
import RaiseHandButton from './component/RaiseHandButton';
import OpenCloseButton from './component/OpenCloseButton';
import FooterImg from './footer.png';

const FooterContainer = styled.div`
  display: grid;
  grid-template-rows: 100%;
  grid-template-areas: 'left medium right';
  grid-gap: 8px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: ${({ isFooterOpen }) => (isFooterOpen ? '62px' : '44px')};
  flex-shrink: 0;
  background: #fdaa74;
  box-shadow: 0px 1px 0px 0px rgba(145, 158, 171, 0.24) inset;
  padding: 0 16px;
  background-image: url(${FooterImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 16px 16px 0px 0px;
  @media (max-width: 1180px) {
    grid-template-areas: 'medium right';
  }
`;

const MultipleFooterContainer = styled(FooterContainer)`
  grid-template-areas: 'medium right';
`;

const StudentFooterContainer = styled(FooterContainer)`
  @media (max-width: 1180px) {
    grid-template-areas: 'left medium right';
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
  &.medium {
    justify-content: center;
  }
  &.right {
    justify-content: flex-end;
  }
`;

const RewardCountContainer = styled(Box)`
  @media (max-width: 1180px) {
    display: ${({ currentRole }) =>
      currentRole !== 'student' ? 'none' : 'flex'};
  }
`;

export function Footer({
  isSettingModalOpen,
  setIsSettingModalOpen,
  isTeacherHere,
}) {
  const { roster } = useRosterState();
  const { isFooterOpen } = useFooterStatus();
  const meetingState = useMeetingState();
  const { role, courseType } = meetingState.context;
  const { isBreakoutRoom } = useBreakoutMeeting();
  const attendees = Object.values(roster);
  const teacher = attendees.find((attendee) => attendee.role === Roles.Teacher);
  const students = attendees.filter(
    (attendee) => attendee.role === Roles.Student
  );
  const advisor = attendees.find((attendee) => attendee.role === Roles.Advisor);
  const Content = (
    <>
      {role !== Roles.Observer && courseType === ClassType.SyncSingle && (
        <RewardCountContainer
          gridArea='left'
          currentRole={role}
          isFooterOpen={isFooterOpen}
        >
          <RewardCount />
        </RewardCountContainer>
      )}

      {role === Roles.Student &&
        (courseType === ClassType.SyncStreaming || ClassType.SyncMultiple) && (
          <RewardCountContainer
            gridArea='left'
            currentRole={role}
            isFooterOpen={isFooterOpen}
          >
            <RewardCount />
          </RewardCountContainer>
        )}

      <ButtonsContainer className='medium' gridArea='medium'>
        {(role === Roles.Teacher || role === Roles.Advisor) && (
          <>
            <RecordButton currentRole={role} />
            <ShareButton isTeacherHere={isTeacherHere} />
            <MaterialButton />
            <QuestionButton />
            <ParticipantButton attendees={attendees} currentRole={role} />
            <PerformanceButton students={students} />
            {courseType === ClassType.SyncSingle && <RewardButton />}
          </>
        )}

        {role === Roles.Teacher &&
          (courseType === ClassType.SyncMultiple ||
            courseType === ClassType.SyncStreaming) && (
            <>
              {!isBreakoutRoom && <TakeTurnsButton />}
              {/* <BreakoutRoomButton /> */}
            </>
          )}
      </ButtonsContainer>
      <ButtonsContainer className='right' gridArea='right'>
        {/* 一對一課程有顧問存在，老師顯示私訊顧問按鈕 */}
        {role === Roles.Teacher &&
          courseType === ClassType.SyncSingle &&
          advisor && (
            <PrivateMessageButton
              advisorIsExist={!!advisor}
              currentRole={role}
            />
          )}
        {/* 一對一課程有老師存在，顧問顯示私訊老師按鈕 */}
        {role === Roles.Advisor &&
          courseType === ClassType.SyncSingle &&
          teacher && (
            <PrivateMessageButton
              advisorIsExist={!!teacher}
              currentRole={role}
            />
          )}
        {/* 團課或直播課課程有老師存在，顧問顯示私訊老師按鈕 */}
        {role === Roles.Advisor &&
          courseType !== ClassType.SyncSingle &&
          teacher && (
            <PrivateMessageButton
              advisorIsExist={!!teacher}
              currentRole={role}
            />
          )}
        {role === Roles.Student && courseType !== ClassType.SyncSingle && (
          <RaiseHandButton />
        )}
        {role !== Roles.Observer && (
          <>
            <ServiceButton />
            <SettingButton
              isModalOpen={isSettingModalOpen}
              setIsModalOpen={setIsSettingModalOpen}
              currentRole={role}
            />
            <OpenCloseButton />
          </>
        )}
      </ButtonsContainer>
    </>
  );

  return role === Roles.Student ? (
    <StudentFooterContainer isFooterOpen={isFooterOpen}>
      {Content}
    </StudentFooterContainer>
  ) : courseType !== ClassType.SyncSingle ? (
    <MultipleFooterContainer isFooterOpen={isFooterOpen}>
      {Content}
    </MultipleFooterContainer>
  ) : (
    <FooterContainer isFooterOpen={isFooterOpen}>{Content}</FooterContainer>
  );
}
