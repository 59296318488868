import React from 'react';
import { t } from "utils/i18n";
import { useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import { Steps } from 'antd';
import { StyledStepsBar } from './StepsBar.style';

const { Step } = Steps;

export const StepsBar = ({ className }) => {
  const deviceDetectState = useDeviceDetectState();
  const { Steps: StepsVal } = deviceDetectState.value;
  const currentStep = stepsItems.find(item => item.value === StepsVal)?.order;
  const trans = (key, params) => {
    return t(`packages.deviceDetect.components.stepsBar.${key}`, "", params);
  };
  const stepsItems = [
    {
      title: trans("__networkCheck"),
      value: "Network",
      order: 0,
    },
    {
      title: trans("__cameraCheck"),
      value: "Video",
      order: 1,
    },
    {
      title: trans("__microphoneCheck"),
      value: "Microphone",
      order: 2,
    },
    {
      title: trans("__audioPlaybackCheck"),
      value: "Audio",
      order: 3,
    },
    {
      title: trans("__checkResults"),
      value: "Result",
      order: 4,
    },
  ];

return (
    <StyledStepsBar 
      className={className}
      data-testid="StepsBar"
      >
      <Steps 
        current={currentStep} 
        labelPlacement='vertical'
        responsive={false}
        >
          {stepsItems.map(item => <Step title={item.title} key={item.value} />)}
      </Steps>
    </StyledStepsBar>
  );
};