import React from 'react';
import {
  StyledResourceBox,
  StyledResource,
  StyledResourceItem,
} from '../QuestionTypeList.style';
import { t } from 'utils/i18n';

const questionTypes = [
  {
    code: 'FL',
    name: t('containers.questionType.__fillInTheBlank', 'Điền vào chỗ trống'),
  },
  {
    code: 'GR',
    name: t('containers.questionType.__questionGroup', 'Nhóm câu hỏi'),
  },
  {
    code: 'MT',
    name: t(
      'containers.questionType.__multipleChoiceQuestion',
      'Câu hỏi nhiều lựa chọn'
    ),
  },
  {
    code: 'MX',
    name: t('containers.questionType.__mixedQuestion', 'Câu hỏi hỗn hợp'),
  },
  {
    code: 'SS',
    name: t(
      'containers.questionType.__singleChoiceQuestion',
      'Câu hỏi lựa chọn đơn'
    ),
  },
  {
    code: 'WR',
    name: t('containers.questionType.__openEndedQuestion', 'Câu hỏi tự luận'),
  },
];

const QuestionType = ({ selectedType, setSelectedType }) => {
  const clickHandler = (selectedType) => {
    setSelectedType(selectedType);
  };

  return (
    <StyledResource>
      {questionTypes.map((type) => (
        <StyledResourceBox key={type.code}>
          <div className="content">
            <div className="list">
              <StyledResourceItem
                key={type.code}
                onClick={() => clickHandler(type)}
                active={selectedType && selectedType.code === type.code}
              >
                {type.name}
              </StyledResourceItem>
            </div>
          </div>
        </StyledResourceBox>
      ))}
    </StyledResource>
  );
};

export default QuestionType;
