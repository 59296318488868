import React, { useRef, useState, useEffect } from 'react';
import {
  useAudioVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';
import styled from 'styled-components';
import TestSound from './assets/testSound.mp3';
import VolumeIndicator from 'components/NewOneBoard/VideoInfo/common/VolumeIndicator';
import TestContainer from './common/TestContainer';
import VolumeContainer from './common/VolumeContainer';
import FeatureContainer from './common/FeatureContainer';
import FeatureText from './common/FeatureText';
import OutputLevel from './common/OutputLevel';
import TestButton from './common/TestButton';
import DeviceSelection from './common/DeviceSelection';
import StyledVideoContentContainer from './common/StyledVideoContentContainer';
import {
  useAudioInputs,
  useAudioOutputs,
} from 'amazon-chime-sdk-component-library-react';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.settingButton.audioSetting.${key}`,
    '',
    params
  );
};

const StyledDeviceSelection = styled(DeviceSelection)`
  margin: 8px 0;
  width: 100%;
  align-items: center;
`;

const MicroText = styled.p`
  margin: 0;
  color: #919eab;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  margin-top: 8px;
`;

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const audioInputConfig = {
  additionalDevices: false,
};

const AudioSetting = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef();
  const audioVideo = useAudioVideo();
  const meetingManager = useMeetingManager();
  const { devices: audioInputs, selectedDevice: selectedAudioInput } =
    useAudioInputs(audioInputConfig);
  const { devices: audioOutputs, selectedDevice: selectedAudioOutput } =
    useAudioOutputs();

  useEffect(() => {
    audioRef.current = new Audio(TestSound);
  }, []);

  const handleAudioTest = async () => {
    setIsPlaying(true);
    try {
      if (typeof audioRef.current.setSinkId === 'function') {
        await audioRef.current.setSinkId(selectedAudioOutput);
      }
      await audioRef.current.play();
    } catch (error) {
      console.error('Failed to play the audio:', error);
    }
  };

  useEffect(() => {
    const handleAudioEnded = () => {
      setIsPlaying(false);
    };

    audioRef.current.addEventListener('ended', handleAudioEnded);
    return () => {
      audioRef.current.removeEventListener('ended', handleAudioEnded);
    };
  }, []);

  return (
    <FeatureContainer>
      <div>
        <FeatureText>{trans('__microPhone')}</FeatureText>
        <TestContainer>
          <StyledDeviceSelection
            onChange={(event) =>
              meetingManager.startAudioInputDevice(event.target.value)
            }
            value={selectedAudioInput}
          >
            {audioInputs.map((device) => (
              <option key={device.deviceId} value={device.deviceId}>
                {device.label}
              </option>
            ))}
          </StyledDeviceSelection>
        </TestContainer>
        <VolumeContainer>
          <OutputLevel>{trans('__outputLevel')}</OutputLevel>
          <StyledVideoContentContainer>
            <VolumeIndicator
              totalVolumeLevel={13}
              attendeeId={
                audioVideo.audioVideoController.configuration.credentials
                  .attendeeId
              }
            />
          </StyledVideoContentContainer>
        </VolumeContainer>
        <MicroText>{trans('__ifYouCantSeeTheColor')}</MicroText>
      </div>
      <div>
        <FeatureText>{trans('__trumpet')}</FeatureText>
        <TestContainer>
          <TestButton onClick={handleAudioTest} isPlaying={isPlaying}>
            {isPlaying ? trans('__playing') : trans('__testSpeaker')}
          </TestButton>
          <StyledDeviceSelection
            onChange={(event) =>
              meetingManager.startAudioOutputDevice(event.target.value)
            }
            value={selectedAudioOutput}
          >
            {audioOutputs.length === 0 ? (
              <option value="none">Device selection unavailable</option>
            ) : (
              audioOutputs.map((device) => (
                <option key={device.deviceId} value={device.deviceId}>
                  {device.label}
                </option>
              ))
            )}
          </StyledDeviceSelection>
        </TestContainer>
      </div>
    </FeatureContainer>
  );
};
export default AudioSetting;
