import React, { useState, useRef, useCallback, useEffect } from 'react';

import { useDeviceDetectDispatch } from '../../providers/DeviceDetectProvider';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { SpeedTest } from '../../components/SpeedTest';
import { Input } from '../../components/Input';
import { StyledNetworkStep } from './NetworkStep.style';
import {t} from 'utils/i18n';
const networkType = [
  '請選擇連線方式',
  '有線',
  'WiFi',
  '手機連線',
  '不清楚'
];

const networkSource = [
  '請選擇網路來源',
  '自宅專用固定網路',
  '社區&租屋分享網路',
  '電視網路(第四台網路)',
  '學校網路',
  '手機&行動裝置網路',
  '其他',
  '不清楚'
];

export const NetworkStep = ({ className }) => {

  const trans = (key, params) => {
    return t(`packages.deviceDetect.containers.networkStep.${key}`, '', params);
  };
  const { goVideo, updateNetwork } = useDeviceDetectDispatch();
  const networkData = useRef(null);
  const typeVal = useRef(null);
  const otherVal = useRef(null);

  const [sourceVal, setSourceVal] = useState(null);
  const [speedTestState, setSpeedTestState] = useState(false);

  const updateNetworkData = (data) => networkData.current = data;

  const goNextHandler = useCallback(() => {
    if (networkData === null) return

    const { ip, speedDown, speedUp, speedJitter, speedPing } = networkData.current;
    let data = {
      ip,
      speedDown,
      speedUp,
      speedJitter,
      speedPing
    };

    if (typeVal !== null) {
      data = {
        ...data,
        type: typeVal.current
      };
    };

    if (sourceVal !== null) {
      data = {
        ...data,
        source: sourceVal
      };
    };

    if (sourceVal === '其他') {
      data = {
        ...data,
        other: otherVal.current
      };
    };

    updateNetwork(data);
    goVideo();

  }, [sourceVal]);

  const otherInputHandler = e => otherVal.current = e.target.value;

  const nextBtnHandler = () => setSpeedTestState(true);

  useEffect(()=>{
    if (sourceVal === null) return
    if (sourceVal !== '其他') {
      otherVal.current = null;
    }
  },[sourceVal])

  return (
    <StyledNetworkStep className={className} data-testid="NetworkStep">
      <div className="content">
        <div className="itemWrap iframe">
          <SpeedTest nextHandler={nextBtnHandler} updateHandler={updateNetworkData} />
        </div>
        <div className="itemWrap form">
          <Box>
            <Select className="select" defaultValue='請選擇連線方式' onChange={val => typeVal.current = val}>
              {networkType.map((item) => {
                let currentNetworkType = '';
                switch (currentNetworkType) {
                  case '請選擇連線方式':
                    currentNetworkType = trans('__selectConnectionType')
                    break;
                  case '有線':
                    currentNetworkType = trans('__wired')
                    break;
                  case '手機連線':
                    currentNetworkType = trans('__mobileConnection')
                    break;
                  case '不清楚':
                    currentNetworkType = trans('__uncertainConnection')
                    break;
                  default:
                    currentNetworkType = item;
                    break;
                }

                return (
                    <Select.Option
                    value={item}
                    disabled={item === '請選擇連線方式'}
                    key={item}>
                  {currentNetworkType}
                </Select.Option>
                )
              })}
            </Select>
          </Box>
          <Box mt={4}>
            <Select className="select" defaultValue='請選擇網路來源' onChange={setSourceVal}>
              {networkSource.map((item) =>{
                let currentNetworkSource = '';
                switch (currentNetworkSource) {
                  case '請選擇網路來源':
                    currentNetworkSource = trans('__selectNetworkSource')
                    break;
                  case '自宅專用固定網路':
                    currentNetworkSource = trans('__homeFixedNetwork')
                    break;
                  case '社區&租屋分享網路':
                    currentNetworkSource = trans('__communitySharedNetwork')
                    break;
                  case '電視網路(第四台網路)':
                    currentNetworkSource = trans('__tvNetwork')
                    break;
                  case '手機&行動裝置網路':
                    currentNetworkSource = trans('__mobileDeviceNetwork')
                    break;
                  case '其他':
                    currentNetworkSource = trans('__otherNetwork')
                    break;
                  case '不清楚':
                    currentNetworkSource = trans('__uncertainNetwork')
                    break;
                  default:
                    currentNetworkSource = item;
                    break;
                }
                return (
                    <Select.Option
                        value={item}
                        disabled={item === '請選擇網路來源'}
                        key={item}>
                      {currentNetworkSource}
                    </Select.Option>
                )
              })}
            </Select>
          </Box>
          {sourceVal === '其他' && <Box mt={4}>
          <Input onChange={otherInputHandler} />
        </Box>}
        <Box mt={4}>
          <div className="notice">
            {trans('__clickStartNetworkTest')}<br />
            {trans('__afterTestClickNext')}
          </div>
        </Box>
        </div>
      </div>
      <Box mt={4}>
        <div className="action">
          <FullButton onClick={goNextHandler} disabled={!speedTestState}>{trans('__next')}</FullButton>
        </div>
      </Box>
    </StyledNetworkStep>
  );
};