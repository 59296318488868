import React from 'react';
import {
  StyledTestList,
  StyledResourceBox,
  StyledResource,
  StyledResourceItem,
} from './ChapterSectionList.style';
import { Box } from '@oneboard/ui-components';
import { t } from 'utils/i18n';

export const ChapterSectionList = ({
  section,
  setKnowledge,
  selectedSection,
  setSelectedSection,
  width = '332px',
  pd = '16px',
}) => {
  const chapters = section?.chapter;

  const clickHandler = (selectedChapter) => {
    setSelectedSection(selectedChapter);
    if (!selectedChapter.chapter) {
      setKnowledge(selectedChapter.knowledges);
      return;
    }
  };

  return (
    <StyledTestList width={width} padding={pd}>
      <div>
        <StyledResource>
          {chapters.map((sec) => (
            <StyledResourceBox key={sec.code}>
              <div className="content">
                <div className="list">
                  <StyledResourceItem
                    key={sec.code}
                    onClick={() => clickHandler(sec)}
                    active={selectedSection?.code === sec.code}
                  >
                    {sec.name}
                  </StyledResourceItem>
                </div>
              </div>
            </StyledResourceBox>
          ))}
          {!section && (
            <Box display="flex" justifyContent="center">
              {t('containers.chapterSectionList.noTextbookTestAvailable')}
            </Box>
          )}
        </StyledResource>
      </div>
    </StyledTestList>
  );
};
