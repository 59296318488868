import React, { useState, useRef, useEffect } from 'react';
import Icon from '@onedesign/icon';
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { useDeviceDetectDispatch } from '../../providers/DeviceDetectProvider';
import { Input } from '../../components/Input';
import { StyledIntro } from './Intro.style';


const deviceTypes = [
  '請選擇使用裝置',
  '筆記型電腦',
  '桌上型電腦',
  '蘋果電腦（筆電、桌機）',
  '平板',
  '手機',
  '不清楚'
];

const boardItems = ['請選擇品牌', 'Apple', '三星', '華為', '小米', '其他'];

const osItems = ['請選擇系統', 'ios系統', '安卓系統'];

export const Intro = ({ className }) => {
    const trans = (key, params) => {
      return t(`packages.deviceDetect.containers.intro.${key}`, "", params);
    };

  const { startDetect, updateDevice } = useDeviceDetectDispatch();

  const [deviceVal, setDeviceVal] = useState(null);
  const [tableVal, setTableVal] = useState(null);
  const [mobileVal, setMobileVal] = useState(null);
  const otherVal = useRef(null);
  

  const detectItems = [
    {
      icon: "WifiSolid",
      name: trans("__network"),
    },
    {
      icon: "VideoSolid",
      name: trans("__video"),
    },
    {
      icon: "MicrophoneAltSolid",
      name: trans("__microphone"),
    },
    {
      icon: "HeadsetSolid",
      name: trans("__headphonesSpeakers"),
    },
  ];

  const otherInputHandler = e => otherVal.current = e.target.value
  
  const startDetectHandler = () => {
    const data = {};

    if (deviceVal === '手機') data.mobileSystem = mobileVal;
    
    if (deviceVal === '平板') data.tableBoard = tableVal;

    if (deviceVal === '平板' && tableVal === '其他') data.otherBoard = otherVal.current;

    if (deviceVal !== null) {
      data.type = deviceVal
      updateDevice(data)
    };

    startDetect();
  }

  useEffect(() => {
    if (deviceVal === null) return
    if (deviceVal !== '平板') {
      otherVal.current = '';
      setTableVal('');
    }
  }, [deviceVal]);

  useEffect(() => {
    if (tableVal === null) return
    if (tableVal !== '其他') otherVal.current = ''
  }, [tableVal]);

return (
  <StyledIntro className={className} data-testid="Intro">
    <div className="desc">
      {trans("__deviceCheckInstructions")}
      <br />
      {trans("__ifYourDeviceReady")}
    </div>
    <div className="detectBlock">
      {detectItems.map((obj) => (
        <div className="detectItems" key={obj.name}>
          <div className="item">
            <div className="icon">
              <Icon name={obj.icon} size="xl" color="#8A94A6" />
            </div>
            <div className="name">{obj.name}</div>
          </div>
        </div>
      ))}
    </div>
    <div className="content">
      <Select
        className="select"
        defaultValue="請選擇使用裝置"
        onChange={setDeviceVal}
      >
        {deviceTypes.map((item) => {
          let currentDeviceTypes = "";
          switch (currentDeviceTypes) {
            case "請選擇使用裝置":
              currentDeviceTypes = trans("__selectDeviceUsage");
              break;
            case "筆記型電腦":
              currentDeviceTypes = trans("__laptop");
              break;
            case "桌上型電腦":
              currentDeviceTypes = trans("__desktop");
              break;
            case "蘋果電腦（筆電、桌機）":
              currentDeviceTypes = trans("__appleComputer");
              break;
            case "平板":
              currentDeviceTypes = trans("__tablet");
              break;
            case "手機":
              currentDeviceTypes = trans("__phone");
              break;
            case "不清楚":
              currentDeviceTypes = trans("__uncertain");
              break;
            default:
              currentDeviceTypes = item;
              break;
          }

          return (
            <Select.Option
              value={item}
              disabled={item === "請選擇使用裝置"}
              key={item}
            >
              {currentDeviceTypes}
            </Select.Option>
          );
        })}
      </Select>
      {deviceVal === "平板" && (
        <Box mt={3}>
          <Select
            className="select"
            defaultValue="請選擇品牌"
            onChange={setTableVal}
          >
            {boardItems.map((item) => {
              let currentBoardItems = "";
              switch (currentBoardItems) {
                case "請選擇使用裝置":
                  currentBoardItems = trans("__selectDeviceUsage");
                  break;
                case "三星":
                  currentBoardItems = trans("__samsung");
                  break;
                case "華為":
                  currentBoardItems = trans("__huawei");
                  break;
                case "小米":
                  currentBoardItems = trans("__xiaomi");
                  break;
                case "其他":
                  currentBoardItems = trans("__otherBrand");
                  break;
                default:
                  currentBoardItems = item;
                  break;
              }
              return (
                <Select.Option
                  value={item}
                  disabled={item === "請選擇品牌"}
                  key={item}
                >
                  {currentBoardItems}
                </Select.Option>
              );
            })}
          </Select>
        </Box>
      )}
      {deviceVal === "手機" && (
        <Box mt={3}>
          <Select
            className="select"
            defaultValue="請選擇系統"
            onChange={setMobileVal}
          >
            {osItems.map((item) => {
              let currentOsItems = "";
              switch (currentOsItems) {
                case "請選擇系統":
                  currentOsItems = trans("__selectSystem");
                  break;
                case "ios系統":
                  currentOsItems = trans("__ios");
                  break;
                case "安卓系統":
                  currentOsItems = trans("__android");
                  break;
                default:
                  currentOsItems = item;
                  break;
              }
              return (
                <Select.Option
                  value={item}
                  disabled={item === "請選擇系統"}
                  key={item}
                >
                  {currentOsItems}
                </Select.Option>
              );
            })}
          </Select>
        </Box>
      )}
      {deviceVal === "平板" && tableVal === "其他" && (
        <Box mt={3}>
          <Input onChange={otherInputHandler} />
        </Box>
      )}
      <Box mt={4}>
        <div className="action">
          <FullButton onClick={startDetectHandler}>
            {trans("__startCheck")}
          </FullButton>
        </div>
      </Box>
    </div>
  </StyledIntro>
);
};