import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
  MeetingRoles,
} from '@oneboard/meeting';
import { message, Tooltip } from 'antd';
import { Popover } from '@oneboard/ui-components';
import { useWhiteboard } from '@oneboard/whiteboard';
import { CleanConfirmModal } from 'containers';
import { useGroupContext } from 'providers/GroupProvider';
import { Roles, ClassType } from 'constants/index';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { StyledToolBox, IconBox } from './ToolBox.style';
import { useS3 } from 'utils/hooks/useS3';
import { useQuizService } from 'utils/hooks/useQuiz';
import { useRoom } from 'providers/RoomProvider';
import { API_STATUS } from 'constants/index';
import { getCourse } from 'api/oneClub';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`components.newOneBoard.toolBox.${key}`, '', params);
};

export const ToolBox = ({ className, meetingId }) => {
  const meetingState = useMeetingState();
  const { role, courseType, roomId } = meetingState.context;
  const { startWhiteboard, endWhiteboard } = useMeetingDispatch();
  const { toggleWorldWallModal } = useGroupContext();
  const isWhiteboarding = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.Whiteboard}.${MeetingStates.Whiteboarding}`,
  });
  const { isBreakoutRoom } = useBreakoutMeeting();

  const { roster } = useRosterState();
  const attendeeIds = Object.keys(roster);

  const { service: whiteboardService } = useWhiteboard();
  const { toggleInsertPictureModalSwitch } = useRoom();
  const [courseInfo, setCourseInfo] = useState();

  const audioFileRef = useRef();
  const videoFileRef = useRef();

  const openWhiteboardHandler = useCallback((attendeeIds) => {
    startWhiteboard({ attendeeIds });
  }, []);

  const openAllAttendeeWhiteboard = () => {
    openWhiteboardHandler(attendeeIds);
  };

  const { upload } = useS3(meetingId);

  const insertMediaHandler = (mediaType) => async (event) => {
    const [file] = event.target.files;
    if (!file) return;
    if (!file.type) return;

    message.loading({
      content: trans('__fileUploading'),
      duration: 0,
      key: file.name,
    });
    const filename = `${new Date().getTime()}${file.name.split('.').pop()}`;
    const uploadFile = await upload(file, file.type, filename);
    const { state } = uploadFile;

    if (state === 'success') {
      const { url } = uploadFile;
      message.destroy(file.name);
      message.success('Loading finished');
      if (mediaType === 'video') {
        const cameraState = whiteboardService.state.cameraState;
        const videoWidth = cameraState.width - 56;
        const videoHeight = (videoWidth * 9) / 16;
        whiteboardService.insertPlugin('video2', {
          originX: (cameraState.width / 2 - 56) * -1,
          originY: (videoHeight / 2) * -1,
          width: videoWidth,
          height: videoHeight,
          attributes: { src: url, isNavigationDisable: false },
        });
      } else {
        whiteboardService.insertPlugin('audio2', {
          originX: -240,
          originY: -43,
          width: 480,
          height: 86,
          attributes: { src: url, isNavigationDisable: false },
        });
      }
      event.target.value = null;
    } else {
      message.destroy(file.name);
      message.error(trans('__errorUpload'));
    }
  };

  const worldWallModalHandler = () => {
    if (role !== Roles.Teacher) return;
    toggleWorldWallModal();
  };

  useEffect(() => {
    if (isWhiteboarding) {
      openAllAttendeeWhiteboard();
    } else {
      endWhiteboard();
    }
  }, [roster]);

  const { openModal } = CleanConfirmModal.useCleanConfirmModal();

  const { setQuiz } = useQuizService();

  const getCourseInfo = async () => {
    try {
      const courseInfo = await getCourse(roomId);
      if (!courseInfo) return;

      const { status, data } = courseInfo;
      if (status === API_STATUS.SUCCESS && data) {
        setCourseInfo(data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  // 取得課程資訊
  useEffect(() => {
    if (role !== Roles.Teacher && role !== Roles.Advisor) return;
    if (
      courseType === ClassType.SyncMultiple ||
      courseType === ClassType.SyncSingle
    ) {
      getCourseInfo();
    }
  }, []);

  return (
    <StyledToolBox className={className} data-testid="SyncToolBox">
      {role !== Roles.Student && (
        <Tooltip
          title={t('containers.SyncToolBox.clearCanvas', '清除畫布')}
          placement="right"
        >
          <IconBox onClick={openModal}>
            <Icon name="TrashSolid" size="xs" />
          </IconBox>
        </Tooltip>
      )}
      <Popover
        placement="right"
        content={
          <div className="uploadPopover">
            <Tooltip
              title={t('containers.SyncToolBox.insertPicture', '插入圖片')}
              placement="right"
            >
              <IconBox onClick={toggleInsertPictureModalSwitch}>
                <Icon name="ImageSolid" size="xs" />
              </IconBox>
            </Tooltip>
            {role === Roles.Teacher && (
              <>
                <Tooltip
                  title={t('containers.SyncToolBox.insertAudio', '插入音檔')}
                  placement="right"
                >
                  <IconBox onClick={() => audioFileRef.current.click()}>
                    <Icon name="MusicSolid" size="xs" />
                    <input
                      hidden
                      type="file"
                      accept="audio/*"
                      ref={audioFileRef}
                      onChange={insertMediaHandler('audio')}
                    />
                  </IconBox>
                </Tooltip>
                <Tooltip
                  title={t('containers.SyncToolBox.insertVideo', '插入影片')}
                  placement="right"
                >
                  <IconBox onClick={() => videoFileRef.current.click()}>
                    <Icon name="FilmSolid" size="xs" />
                    <input
                      hidden
                      type="file"
                      accept="video/*"
                      ref={videoFileRef}
                      onChange={insertMediaHandler('video')}
                    />
                  </IconBox>
                </Tooltip>
              </>
            )}
          </div>
        }
      >
        <IconBox>
          <Icon name="PhotoVideoSolid" size="xs" />
        </IconBox>
      </Popover>
      {!isBreakoutRoom &&
        role === Roles.Teacher &&
        courseType === ClassType.SyncMultiple && (
          <>
            <Popover
              placement="right"
              content={
                <div className="uploadPopover">
                  {role === Roles.Teacher && (
                    <>
                      {/* <Tooltip title={t('containers.SyncToolBox.quizTool', '測驗工具')} placement='right'>
                      <IconBox onClick={worldWallModalHandler}>
                        <Icon name='BrowserSolid' size='xs' />
                      </IconBox>
                    </Tooltip> */}
                      {(courseType === ClassType.SyncMultiple ||
                        courseType === ClassType.Group) && (
                        <Tooltip
                          title={t(
                            'containers.SyncToolBox.answerWidget',
                            '答題小工具'
                          )}
                          placement="right"
                        >
                          <IconBox
                            onClick={setQuiz}
                            title={t(
                              'containers.SyncToolBox.answerWidget',
                              '答題小工具'
                            )}
                          >
                            <Icon name="QaToolSolid" />
                          </IconBox>
                        </Tooltip>
                      )}
                    </>
                  )}
                </div>
              }
            >
              <IconBox>
                <Icon name="ToolboxSolid" size="xs" />
              </IconBox>
            </Popover>
          </>
        )}
    </StyledToolBox>
  );
};

ToolBox.propTypes = {
  className: PropTypes.string,
  meetingId: PropTypes.string,
};
