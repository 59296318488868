import React from 'react';
import { Modal, Box, Button } from '@oneboard/ui-components';
import { t } from 'utils/i18n';

export const WarningModal = ({ isOpen, onClose, headerText, bodyText }) => {
  if (!isOpen) return null;

  return (
    <Modal header={headerText} closeIcon={true} onClose={onClose}>
      {bodyText}
      <Box display="flex" justifyContent="flex-end" mt={8}>
        <Button onClick={onClose}>
          {t('containers.insertPictureModal.understand', 'Tôi đã hiểu')}
        </Button>
      </Box>
    </Modal>
  );
};
