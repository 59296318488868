import React, { useState, useRef, useEffect } from "react";
import {
  useAudioOutputs,
  useMeetingManager,
} from "amazon-chime-sdk-component-library-react";
import { t } from "utils/i18n";
import { Box, FullButton, Select } from "@oneboard/ui-components";
import { Input } from "../../components/Input";
import Icon from "@onedesign/icon";
import {
  useDeviceDetectDispatch,
  useDeviceDetectState,
} from "../../providers/DeviceDetectProvider";
import lottie from "lottie-web";
import { useMediaDevices } from "../../utils/hooks/useMediaDevices";
import { StyledAudioStep, StyledAudioAnimationBox } from "./AudioStep.style";



const AudioAnimation = () => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/audioTest.json'
    });
  }, [ref]);

  return (
    <StyledAudioAnimationBox ref={ref} />
  );
};

export const AudioStep = ({ className }) => {
  const meetingManager = useMeetingManager();
  const { handleError, attachSinkId } = useMediaDevices();
  const { devices, selectedDevice } = useAudioOutputs();
  const { goResult, updateAudio, updateEquipment } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const state = deviceDetectState.context;

  const [playState, setPlayState] = useState(false);
  const [deviceVal, setDeviceVal] = useState(null);
  const otherVal = useRef(null);
  const audioRef = useRef();

  const trans = (key, params) => {
    return t(`packages.deviceDetect.containers.audioStep.${key}`, "", params);
  };

  const deviceTypes = [
    {
      label: trans("__selectDeviceType"),
      icon: "",
    },
    {
      label: trans("__headphones"),
      icon: "HeadphonesAltSolid",
    },
    {
      label: trans("__headsetMicrophone"),
      icon: "Earphone",
    },
    {
      label: trans("__headphonesWithMic"),
      icon: "HeadsetSolid",
    },
    {
      label: trans("__mobileTabletSpeaker"),
      icon: "TabletAltSolid",
    },
    {
      label: trans("__externalSpeaker"),
      icon: "Speaker",
    },
    {
      label: trans("__internalSpeaker"),
      icon: "Screen",
    },
    {
      label: trans("__bluetoothHeadphones"),
      icon: "EarphoneBluetooth",
    },
    {
      label: trans("__bluetoothSpeaker"),
      icon: "SpeakerBluetooth",
    },
    {
      label: trans("__uncertain"),
      icon: "QuestionCircleOutline",
    },
    {
      label: trans("__other"),
      icon: "",
    },
  ];

  const abnormalHandler = () => {
    updateAudio({
      device: deviceVal,
      otherBoard: otherVal.current,
    });
    updateEquipment({
      audio: false,
    });
    goResult();
  };

  const normalHandler = () => {
    updateAudio({
      device: deviceVal,
      otherBoard: otherVal.current,
    });
    updateEquipment({
      audio: true,
    });
    goResult();
  };

  const otherInputHandler = (e) => (otherVal.current = e.target.value);

  const playSound = () => {
    setPlayState(true);
    audioRef.current.play();
  };

  const startAudio = () => {
    const constraints = { audio: true };

    navigator.mediaDevices.getUserMedia(constraints).catch(handleError);

    // navigator.mediaDevices.ondevicechange = () => updateDeviceList();
  };

  const deviceChangeHandler = (deviceId) => {
    meetingManager.startAudioOutputDevice(deviceId);
    changeAudioDestination(deviceId);
  };

  const deviceTypeChangeHandler = (val) => setDeviceVal(val);

  // 處理音訊改變的方法
  const changeAudioDestination = (id) => attachSinkId(audioRef.current, id);

  useEffect(() => {
    startAudio();
  }, []);

  useEffect(() => {
    if (deviceVal === null) return;

    if (deviceVal !== "其他") otherVal.current = "";
  }, [deviceVal]);

  return (
    <StyledAudioStep className={className} data-testid="AudioStep">
      <div className="audioBlock">
        <div className="itemWrap playBlock">
          <audio ref={audioRef} src="/testSound.mp3" loop></audio>
          {playState ? (
            <AudioAnimation />
          ) : (
            <button className="playBth" onClick={playSound}>
              <Icon name="PlaySolid" size="lg" color="#2F3746" />
            </button>
          )}
          <div className="desc">{trans("__listenToAudio")}</div>
        </div>
        <div className="itemWrap">
          <Box>
            {devices.length > 0 && (
              <Select
                className="select"
                defaultValue={selectedDevice}
                onChange={deviceChangeHandler}
              >
                {devices.map((item) => (
                  <Select.Option value={item.deviceId} key={item.deviceId}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Box>
          <Box mt={3}>
            <Select
              className="select"
              defaultValue="請選擇裝置類型"
              onChange={deviceTypeChangeHandler}
            >
              {deviceTypes.map((item) => (
                <Select.Option
                  value={item.label}
                  label={item.label}
                  disabled={item.label === "請選擇裝置類型"}
                  key={item.label}
                >
                  <Box display="flex" alignItems="center">
                    <Icon name={item?.icon} size="lg" color="#8A94A6" />
                    <Box ml={3}>{item.label}</Box>
                  </Box>
                </Select.Option>
              ))}
            </Select>
          </Box>
          {deviceVal === "其他" && (
            <Box mt={3}>
              <Input onChange={otherInputHandler} />
            </Box>
          )}
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            {trans("__selectPlaybackDevice")}
            <br />
            {trans("__determineClarity")}
          </div>
          <div className="action">
            <Box mr={1} width="100%">
              <FullButton.Secondly onClick={abnormalHandler}>
                {trans("__notClear")}
              </FullButton.Secondly>
            </Box>
            <Box ml={1} width="100%">
              <FullButton onClick={normalHandler}>
                {trans("__clear")}
              </FullButton>
            </Box>
          </div>
        </div>
      </Box>
    </StyledAudioStep>
  );
};
