import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import { Select } from 'antd';
import { Roles } from 'constants/index';
import { Box } from '@oneboard/ui-components';
import { shuffle } from 'utils/array';
import { useGroupContext } from 'providers/GroupProvider';
import { useBreakoutMeeting } from 'providers/BreakoutMeetingProvider';
import { startRecordVideo as startRecordVideoApi } from 'services/recordVideo';
import { useMeetingDispatch, useMeetingState } from '@oneboard/meeting';
import {
  StyledBreakoutRoomContainer,
  BreakoutRoomSetting,
  SettingButtonBox,
  NumberInput,
  ActionButton,
  StyledRoomCell,
  PeopleList,
  CellButton,
} from './BreakoutRoomContainer.style';
import { t } from 'utils/i18n';
const trans = (key, params) =>
  t(`containers.breakoutRoomContainer.${key}`, '', params);

const { Option } = Select;

const NumberControlButton = ({ roomCount, onUpdate }) => {
  const minVal = 2;
  const maxVal = 99;
  const [inputVal, setInputVal] = useState(0);

  const minusHandler = () => {
    if (inputVal === minVal) return;
    onUpdate({ roomCount: inputVal - 1 });
  };

  const addHandler = () => {
    if (inputVal === maxVal) return;
    if (inputVal < minVal) {
      onUpdate({ roomCount: 2 });
      setInputVal(2);
    } else {
      onUpdate({ roomCount: inputVal + 1 });
    }
  };

  const changeHandler = (e) => {
    let val = e.target.value.replace(/\D/g, '');
    if (parseInt(val) < 2 || val === '') val = minVal;
    if (parseInt(val) > 99) val = maxVal;
    onUpdate({ roomCount: parseInt(val) });
  };

  useEffect(() => {
    setInputVal(roomCount);
  }, [roomCount]);

  return (
    <NumberInput>
      <div className="btn" onClick={minusHandler}>
        <Icon name="MinusSolid" size="xxs" />
      </div>
      <input type="text" value={inputVal} onChange={changeHandler} />
      <div className="btn" onClick={addHandler}>
        <Icon name="PlusSolid" size="xxs" />
      </div>
    </NumberInput>
  );
};

NumberControlButton.propTypes = {
  roomCount: PropTypes.number,
  onUpdate: PropTypes.func,
};

const combineLatestData = ({ latestData, students }) => {
  const nextData = latestData.groups.map((group) => {
    const { groupName, members, roomId } = group;
    return {
      groupName,
      roomId,
      isInvited: false,
      members: members.map((member) => {
        const studentMap =
          students.filter((student) => student.userId === member.userId)[0] ||
          {};

        return studentMap;
      }),
    };
  });
  return nextData;
};

const RoomSetting = ({ onClose }) => {
  const { meetingId } = useParams();
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter(
    (attendee) => attendee.role === Roles.Student
  );
  const teacher = attendees.filter(
    (attendee) => attendee.role === Roles.Teacher
  );
  const {
    BreakoutRoomState,
    breakoutRoomStep,
    changeBreakoutRoomStep,
    breakoutRoomSidebarState,
  } = useGroupContext();
  const [manualFlag, setManualFlag] = useState(false);
  const [roomCount, setRoomCount] = useState(0);
  const [mainStudents, setMainStudents] = useState([]);
  const [mainRoomPeople, setMainRoomPeople] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupingIds, setGroupingIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { createBreakout, startBreakout, isBreakoutRoom, getBreakoutLatest } =
    useBreakoutMeeting();
  const breakoutRoomStorage = window.localStorage;

  const groupHandler = ({ roomCount }) => {
    let tmpGroupData = [];
    const basePeopleCount = Math.floor(mainStudents.length / roomCount);
    const remainderPeopleCount = mainStudents.length % roomCount;
    const groupStudent = [];

    if (manualFlag) {
      tmpGroupData = [...groupData];
      const addRoom = roomCount - tmpGroupData.length > 0;

      if (addRoom) {
        tmpGroupData.push({
          groupName: `房間${roomCount}`,
          members: [],
        });
      } else {
        const deleteGroup = tmpGroupData.splice(tmpGroupData.length - 1)[0];
        if (deleteGroup.members.length > 0) {
          setMainRoomPeople([...mainRoomPeople, ...deleteGroup.members]);
        }
      }
    } else {
      if (basePeopleCount >= 1) {
        for (
          let index = 0;
          index < mainStudents.length;
          index += basePeopleCount
        ) {
          const sliceStudent = mainStudents.slice(
            index,
            index + basePeopleCount
          );
          groupStudent.push(sliceStudent);
        }
        if (remainderPeopleCount !== 0) {
          mainStudents
            .slice(remainderPeopleCount * -1)
            .forEach((student, index) => {
              groupStudent[index].push(student);
            });
        }
      } else {
        for (let index = 0; index < roomCount; index++) {
          if (index <= mainStudents.length) {
            groupStudent.push(mainStudents.slice(index, index + 1));
          } else {
            groupStudent.push([]);
          }
        }
      }

      for (let index = 0; index < roomCount; index++) {
        tmpGroupData[index] = {
          groupName: `房間${index + 1}`,
          members: groupStudent[index],
        };
      }
      setMainRoomPeople(teacher);
    }
    const flatGroups = [].concat(...groupStudent).map((obj) => obj.userId);
    const groupingStudents = students
      .filter((student) => flatGroups.includes(student.userId))
      .map((student) => student.userId);

    setGroupingIds(groupingStudents);
    setGroupData(tmpGroupData);
  };

  const initHandler = async () => {
    const breakoutLatestData = await getBreakoutLatest(meetingId);
    if (!breakoutLatestData) return;

    const { groups } = breakoutLatestData;
    const flatGroups = []
      .concat(...groups.map((group) => group.members))
      .map((obj) => obj.userId);
    const tempGroup = groups.map((group) => {
      return {
        ...group,
        members: group.members
          .filter((member) =>
            attendees.find((attendee) => attendee.userId === member.userId)
          )
          .map((member) => {
            const memberInfo = attendees.find(
              (attendee) => attendee.userId === member.userId
            );
            return {
              ...member,
              ...memberInfo,
            };
          }),
      };
    });

    const notGroupingStudents = students.filter(
      (student) => !flatGroups.includes(student.userId)
    );
    const groupingStudents = students
      .filter((student) => flatGroups.includes(student.userId))
      .map((student) => student.userId);

    setGroupingIds(groupingStudents);
    setMainRoomPeople([...teacher, ...notGroupingStudents]);
    setGroupData(tempGroup);
  };

  const shuffleHandler = () => {
    if (roomCount === 0) return;

    const tempArr = [...students];
    shuffle(tempArr);
    setMainStudents(tempArr);
    setManualFlag(false);

    groupHandler({ roomCount });
  };

  const roomCountHandler = ({ roomCount }) => {
    groupHandler({ roomCount });
  };

  const clearHandler = () => {
    const tmpGroupData = [...groupData];

    for (let index = 0; index < tmpGroupData.length; index++) {
      tmpGroupData[index].members = [];
    }
    setGroupData(tmpGroupData);
    setMainRoomPeople([...teacher, ...students]);
  };

  const addPeopleHandler = ({ groupName, members, selectMember }) => {
    const tempGroupData = [...groupData];
    const isInMainRoom =
      mainRoomPeople.filter(
        (student) => student.chimeAttendeeId === selectMember[0].chimeAttendeeId
      ).length > 0;
    const oldGroup = tempGroupData.filter(
      (group) =>
        group.members.filter(
          (member) => member.chimeAttendeeId === selectMember[0].chimeAttendeeId
        ).length > 0
    );

    if (isInMainRoom) {
      const nextMainRoomPeople = mainRoomPeople.filter(
        (student) => student.chimeAttendeeId !== selectMember[0].chimeAttendeeId
      );
      setMainRoomPeople(nextMainRoomPeople);
    } else {
      tempGroupData.forEach((group) => {
        if (group.groupName === oldGroup[0].groupName) {
          group.members = group.members.filter(
            (member) =>
              member.chimeAttendeeId !== selectMember[0].chimeAttendeeId
          );
        }
      });
    }

    tempGroupData.forEach((group) => {
      if (group.groupName === groupName) {
        group.members = members;
      }
    });

    setManualFlag(true);
    setGroupData(tempGroupData);
  };

  const deletePeopleHandler = ({ groupName, members, deleteMember }) => {
    const tempGroupData = [...groupData];
    tempGroupData.forEach((group) => {
      if (group.groupName === groupName) {
        group.members = members;
      }
    });
    setGroupData(tempGroupData);
    setMainRoomPeople((prev) => [...prev, ...deleteMember]);
    setManualFlag(true);
  };

  const startRecordVideo = (rooms) =>
    Promise.all([
      rooms.map((item) =>
        startRecordVideoApi({ meetingId, breakoutId: item.roomId })
      ),
    ]);

  const onSummitSettingHandler = async () => {
    if (isLoading) return;
    setIsLoading(true);

    await createBreakout({ groups: groupData });
    await startBreakout();

    const breakoutLatestData = await getBreakoutLatest(meetingId);
    const latestData = combineLatestData({
      latestData: breakoutLatestData,
      students,
    });

    startRecordVideo(latestData);

    breakoutRoomStorage.setItem('breakoutRoomData', JSON.stringify(latestData));
    breakoutRoomStorage.setItem('attendeesData', JSON.stringify(attendees));

    setIsLoading(false);
    changeBreakoutRoomStep(BreakoutRoomState.grouping);
  };

  useEffect(() => {
    const notGroupingStudents = students.filter(
      (student) => !groupingIds.includes(student.userId)
    );
    setMainRoomPeople([...teacher, ...notGroupingStudents]);
    setMainStudents(students);
  }, [roster]);

  useEffect(() => {
    if (breakoutRoomStep !== BreakoutRoomState.setting) return;
    initHandler();
  }, [breakoutRoomSidebarState]);

  useEffect(() => {
    if (isBreakoutRoom) {
      changeBreakoutRoomStep(BreakoutRoomState.grouping);
    }
  }, [isBreakoutRoom]);

  useEffect(() => {
    setRoomCount(groupData.length);
  }, [groupData]);

  return (
    <BreakoutRoomSetting>
      <div className="head">
        <div className="title">{trans('__groupActivity')}</div>
        <div className="close" onClick={onClose}>
          <Icon name="XmarkOutline" size="xs" />
        </div>
      </div>
      <div className="content">
        <div className="settingPanel">
          <SettingButtonBox>
            <div className="title">{trans('__groupQuantity')}</div>
            <div className="optionContent">
              <NumberControlButton
                roomCount={roomCount}
                onUpdate={roomCountHandler}
              />
            </div>
          </SettingButtonBox>
          <SettingButtonBox>
            <div className="title">{trans('__randomGrouping')}</div>
            <div className="optionContent">
              <button className="settingBtn" onClick={shuffleHandler}>
                <Icon name="RandomSolid" size="xs" />
              </button>
            </div>
          </SettingButtonBox>
          <SettingButtonBox>
            <div className="title">{trans('__regroup')}</div>
            <div className="optionContent">
              <button className="settingBtn" onClick={clearHandler}>
                <Icon name="RedoSolid" size="xs" />
              </button>
            </div>
          </SettingButtonBox>
        </div>
        <div className="roomBox">
          {mainRoomPeople && <MainRoomCell peopleData={mainRoomPeople} />}
          {groupData &&
            groupData.map((group) => {
              return (
                <RoomCell
                  roomInfo={group}
                  breakoutRoomStep={breakoutRoomStep}
                  onDelete={deletePeopleHandler}
                  onAdd={addPeopleHandler}
                  key={group.groupName}
                />
              );
            })}
        </div>
      </div>
      <div className="action">
        <ActionButton
          disabled={roomCount === 0 ? true : false}
          onClick={onSummitSettingHandler}
        >
          {isLoading ? <LoadingOutlined /> : trans('__setupComplete')}
        </ActionButton>
      </div>
    </BreakoutRoomSetting>
  );
};

RoomSetting.propTypes = {
  onClose: PropTypes.func,
};

const RoomGrouping = ({ onClose }) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const { meetingId, breakoutId } = useParams();
  const meetingState = useMeetingState();
  const { callTeacherGroups, breakoutRoomMemberStatus } = meetingState.context;
  const {
    BreakoutRoomState,
    breakoutRoomStep,
    changeBreakoutRoomStep,
    openBreakoutRoomEndNotice,
    setBroadcastModalActions,
  } = useGroupContext();
  const { isBreakoutRoom, getBreakoutLatest } = useBreakoutMeeting();
  const [latestGroupsInfo, setLatestGroupsInfo] = useState([]);
  const [mainRoomPeople, setMainRoomPeople] = useState([]);
  const [cellGroupsInfo, setCellGroupsInfo] = useState([]);
  const breakoutRoomStorage = window.localStorage;

  const onSummitEndHandler = () => {
    onClose();
    openBreakoutRoomEndNotice();
  };

  const initHandler = async () => {
    let breakoutRoomData = [];
    const attendeesDataIsExist = breakoutRoomStorage.getItem('attendeesData');

    if (isBreakoutRoom && attendeesDataIsExist) {
      const students = JSON.parse(
        breakoutRoomStorage.getItem('attendeesData')
      ).filter((attendee) => attendee.role === Roles.Student);
      const teacher = JSON.parse(
        breakoutRoomStorage.getItem('attendeesData')
      ).filter((attendee) => attendee.role === Roles.Teacher);
      const breakoutLatestData = await getBreakoutLatest(meetingId);
      breakoutRoomData = combineLatestData({
        latestData: breakoutLatestData,
        students,
      });
      const { _, ...rest } = teacher[0];
      breakoutRoomData = breakoutRoomData.map((group) => {
        if (group.roomId === breakoutId) {
          group.members = [
            {
              inRoom: true,
              ...rest,
            },
            ...group.members,
          ];
        }
        return group;
      });
    } else {
      breakoutRoomData = JSON.parse(
        breakoutRoomStorage.getItem('breakoutRoomData')
      );
    }

    setLatestGroupsInfo(breakoutRoomData);
  };

  const updateMainRoster = () => {
    let tempMainRoster = [];
    cellGroupsInfo.forEach((group) => {
      group.members.forEach((member) => {
        if (!member.inRoom) {
          tempMainRoster.push(member);
        }
      });
    });
    setMainRoomPeople(tempMainRoster);
  };

  useEffect(() => {
    initHandler();
  }, []);

  useEffect(() => {
    if (isBreakoutRoom) return;
    const mainPeople = attendees.filter(
      (attendee) =>
        attendee.role === Roles.Teacher || attendee.role === Roles.Student
    );
    setMainRoomPeople(mainPeople);
  }, [roster]);

  useEffect(() => {
    const isUpdate = Object.keys(breakoutRoomMemberStatus).length > 0;
    if (!isUpdate) return;

    const nextData = cellGroupsInfo.map((group) => {
      if (group.roomId === breakoutRoomMemberStatus.breakoutId) {
        group.members.map((member) => {
          if (member.userId === breakoutRoomMemberStatus.userId) {
            member.inRoom =
              breakoutRoomMemberStatus.type === 'enterBreakoutRoom'
                ? true
                : false;
          }
          return member;
        });
      }
      return group;
    });
    setCellGroupsInfo(nextData);
    breakoutRoomStorage.setItem('breakoutRoomData', JSON.stringify(nextData));
  }, [breakoutRoomMemberStatus]);

  useEffect(() => {
    if (!isBreakoutRoom) return;
    updateMainRoster();
  }, [cellGroupsInfo]);

  useEffect(() => {
    if (isBreakoutRoom) {
      changeBreakoutRoomStep(BreakoutRoomState.grouping);
    }
  }, [isBreakoutRoom]);

  useEffect(() => {
    if (!latestGroupsInfo || latestGroupsInfo?.length === 0) return;
    const nextData = latestGroupsInfo.map((group) => {
      const isInvited =
        callTeacherGroups.filter(
          (callGroup) => callGroup.breakoutRoomId === group.roomId
        ).length > 0;
      if (isInvited) {
        group.isInvited = true;
      } else {
        group.isInvited = false;
      }
      return group;
    });
    setCellGroupsInfo(nextData);
  }, [callTeacherGroups, latestGroupsInfo]);

  return (
    <BreakoutRoomSetting>
      <div className="head">
        <div className="title">{trans('__groupActivityInProgress')}</div>
        <div className="close" onClick={onClose}>
          <Icon name="XmarkOutline" size="xs" />
        </div>
      </div>
      <div className="content">
        <div className="settingPanel">
          <SettingButtonBox>
            <div className="title">{trans('__broadcastMessage')}</div>
            <div className="optionContent">
              <button
                className="settingBtn"
                onClick={setBroadcastModalActions.setTrue}
              >
                <Icon name="MegaphoneSolid" size="xs" />
              </button>
            </div>
          </SettingButtonBox>
          <SettingButtonBox>
            <div className="title">{trans('__endGrouping')}</div>
            <div className="optionContent">
              <button className="settingBtn close" onClick={onSummitEndHandler}>
                <Icon name="XmarkOutline" size="xs" />
              </button>
            </div>
          </SettingButtonBox>
        </div>
        <div className="roomBox">
          <MainRoomCell peopleData={mainRoomPeople} />
          {cellGroupsInfo.length > 0 &&
            cellGroupsInfo.map((group) => {
              return (
                <RoomCell
                  roomInfo={group}
                  breakoutRoomStep={breakoutRoomStep}
                  key={group.groupName}
                />
              );
            })}
        </div>
      </div>
    </BreakoutRoomSetting>
  );
};

RoomGrouping.propTypes = {
  onClose: PropTypes.func,
};

const MainRoomCell = ({ peopleData }) => {
  return (
    <StyledRoomCell>
      <div className="roomInfo">
        <div className="groupName">{trans('__mainClassroom')}</div>
        <div className="peopleCount">{peopleData.length}</div>
      </div>
      <div className="peopleBox">
        {peopleData.length > 0 &&
          peopleData.map((people) => {
            return (
              <PeopleList bgColor={people?.color} key={people.chimeAttendeeId}>
                <div className="peopleIcon">{people?.name?.slice(0, 1)}</div>
                <div className="peopleName">{people?.name}</div>
              </PeopleList>
            );
          })}
      </div>
    </StyledRoomCell>
  );
};

MainRoomCell.propTypes = {
  peopleData: PropTypes.array,
};

const RoomCell = ({ roomInfo, onDelete, onAdd }) => {
  const { breakoutId: paramsBreakoutId } = useParams();
  const { goToBreakoutMeeting } = useBreakoutMeeting();
  const { removeCallTeacherGroups } = useMeetingDispatch();
  const { groupName, members, roomId: breakoutId, isInvited } = roomInfo;
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees.filter(
    (attendee) => attendee.role === Roles.Student
  );
  const [addPeopleSelectState, setPeopleSelectState] = useState(false);
  const selectRef = useRef();
  const { BreakoutRoomState, breakoutRoomStep } = useGroupContext();
  const meetingState = useMeetingState();
  const { callTeacherGroups } = meetingState.context;
  const breakoutRoomStorage = window.localStorage;

  const togglePeopleSelectState = () => setPeopleSelectState((prev) => !prev);

  const selectChangeHandler = (id) => {
    const isExist =
      members.filter((member) => member.chimeAttendeeId === id).length > 0;
    if (isExist) return;

    const selectMember = students.filter(
      (student) => student.chimeAttendeeId === id
    );
    const nextMembers = [...members, ...selectMember];

    onAdd({
      groupName,
      members: nextMembers,
      selectMember,
    });
  };

  const deleteRoomPeople = (id) => {
    const nextMembers = members.filter(
      (member) => member.chimeAttendeeId !== id
    );
    const deleteMember = members.filter(
      (member) => member.chimeAttendeeId === id
    );
    onDelete({
      groupName,
      members: nextMembers,
      deleteMember,
    });
  };

  const goToBreakoutMeetingHandler = () => {
    if (paramsBreakoutId === breakoutId) return;
    removeCallTeacherGroups({ breakoutRoomId: breakoutId });
    const callTeacherGroupsData = callTeacherGroups.filter(
      (group) => group.breakoutRoomId !== breakoutId
    );

    // 存呼叫老師資料
    breakoutRoomStorage.setItem(
      'callTeacherData',
      JSON.stringify(callTeacherGroupsData)
    );
    goToBreakoutMeeting({ breakoutId });
  };

  // 觀看直播
  const goToLiveHandler = () =>
    window.open(`${process.env.REACT_APP_BS_DOMAIN}/player/${breakoutId}`);

  return (
    <StyledRoomCell>
      <div className="roomInfo">
        <Box display="flex" alignItems="center">
          <div className="groupName">{groupName}</div>
          {isInvited && (
            <div className="inviteLabel">{trans('__inviteToJoin')}</div>
          )}
        </Box>
        {breakoutRoomStep === BreakoutRoomState.setting && (
          <Box display="flex" mr={1}>
            <div className="peopleCount">{members?.length}</div>
          </Box>
        )}
        {breakoutRoomStep === BreakoutRoomState.grouping && (
          <Box display="flex" mr={1}>
            <Box mr={2}>
              <div className="peopleCount">
                {members?.filter((member) => member.inRoom).length}
              </div>
            </Box>
            <CellButton bg="#232A37" onClick={goToLiveHandler}>
              {trans('__patrolHallways')}
            </CellButton>
            <CellButton bg="#EC7963" onClick={goToBreakoutMeetingHandler}>
              {trans('__join')}
            </CellButton>
          </Box>
        )}
      </div>
      <div className="peopleBox">
        {!addPeopleSelectState &&
          breakoutRoomStep === BreakoutRoomState.setting && (
            <>
              <div className="addPeople" onClick={togglePeopleSelectState}>
                <div className="addPeopleIcon">
                  <Icon name="PlusSolid" size="xs" />
                </div>
                <div className="addPeopleTitle">{trans('__addMember')}</div>
              </div>
            </>
          )}
        {addPeopleSelectState &&
          breakoutRoomStep === BreakoutRoomState.setting && (
            <div className="addPeopleSelect" ref={selectRef}>
              <Select
                showSearch
                style={{
                  width: '100%',
                  heigh: '100%',
                }}
                autoFocus={true}
                onBlur={togglePeopleSelectState}
                onChange={selectChangeHandler}
                getPopupContainer={() => selectRef.current}
                dropdownStyle={{
                  backgroundColor: '#232A37',
                }}
              >
                {students.map((student) => {
                  return (
                    <Option
                      value={student.chimeAttendeeId}
                      className="option"
                      key={student.chimeAttendeeId}
                    >
                      {student.name}
                    </Option>
                  );
                })}
              </Select>
            </div>
          )}
        {breakoutRoomStep === BreakoutRoomState.setting &&
          members.length > 0 &&
          members.map((people) => {
            return (
              <PeopleList bgColor={people?.color} key={people?.chimeAttendeeId}>
                <div className="peopleIcon">{people?.name.slice(0, 1)}</div>
                <div className="peopleName">{people?.name}</div>
                <div
                  className="peopleDelete"
                  onClick={() => deleteRoomPeople(people.chimeAttendeeId)}
                >
                  <Icon name="XmarkOutline" width="10" />
                </div>
              </PeopleList>
            );
          })}
        {breakoutRoomStep === BreakoutRoomState.grouping &&
          members.length > 0 &&
          members
            .filter((member) => member.inRoom)
            .map((people) => {
              return (
                <PeopleList
                  bgColor={people?.color}
                  key={people?.chimeAttendeeId}
                >
                  <div className="peopleIcon">{people?.name.slice(0, 1)}</div>
                  <div className="peopleName">{people?.name}</div>
                </PeopleList>
              );
            })}
      </div>
    </StyledRoomCell>
  );
};

RoomCell.propTypes = {
  roomInfo: PropTypes.object,
  onDelete: PropTypes.func,
  onAdd: PropTypes.func,
};

export const BreakoutRoomContainer = ({ className, onClose }) => {
  const { BreakoutRoomState, breakoutRoomStep } = useGroupContext();

  return (
    <StyledBreakoutRoomContainer
      className={className}
      data-testid="BreakoutRoomContainer"
    >
      {breakoutRoomStep === BreakoutRoomState.setting && (
        <RoomSetting onClose={onClose} />
      )}
      {breakoutRoomStep === BreakoutRoomState.grouping && (
        <RoomGrouping onClose={onClose} />
      )}
    </StyledBreakoutRoomContainer>
  );
};
