import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';
import { useChatMessage } from 'utils/hooks/useChatMessage';
import { notification } from 'antd';
import { StyledPrivateMessageList } from './PrivateMessageList.style';
import Icon from '@onedesign/icon';
import styled from 'styled-components';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.privateMessageList.${key}`, '', params);
};

const MessageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

const StyledIcon = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
`;

export const PrivateMessageList = ({ className, filterRole, containerRef }) => {
  const audioVideo = useAudioVideo();
  const { receivePrivateMessage } = useChatMessage();
  const privateMessageListRef = useRef(null);
  const openNotification = useCallback(
    (data) => {
      if (privateMessageListRef.current === null) return;

      return notification.open({
        description: data.content,
        className: 'notificationList',
        style: {
          width: 855,
          height: 'auto',
        },
        duration: 30,
        placement: 'top',
        top: 60,
        getContainer: () => containerRef.current,
      });
    },
    [privateMessageListRef]
  );

  const [privateMessageData, setPrivateMessageData] = useState([]);

  useEffect(() => {
    if (!audioVideo) return;
    const receiveDataMessageHandler = (dataMessage) => {
      const data = dataMessage.json();
      const {
        targetRole,
        senderAttendeeId,
        content,
        timestamp,
        role,
        userName,
      } = data;

      if (targetRole !== filterRole) return;

      const receivedData = {
        targetRole,
        senderAttendeeId,
        content: (
          <MessageContainer>
            <StyledIcon>
              <Icon name="ExclamationCircleSolid" size="md" color="#FFFFFF" />
            </StyledIcon>
            {`${userName} ${trans('__whisper')}${content}`}
          </MessageContainer>
        ),
        timestamp,
        role,
      };

      setPrivateMessageData((prev) => [...prev, receivedData]);
    };

    receivePrivateMessage(receiveDataMessageHandler);
  }, [audioVideo, receivePrivateMessage]);

  useEffect(() => {
    notification.config({
      maxCount: 2,
    });
  }, []);

  useEffect(() => {
    if (privateMessageData.length === 0) return;
    openNotification(privateMessageData[privateMessageData.length - 1]);
  }, [privateMessageData.length]);

  return (
    <StyledPrivateMessageList
      className={className}
      data-testid="PrivateMessageList"
      ref={privateMessageListRef}
    >
      {/* Code Here */}
    </StyledPrivateMessageList>
  );
};

PrivateMessageList.propTypes = {
  className: PropTypes.string,
  filterRole: PropTypes.string,
  containerRef: PropTypes.object,
};
