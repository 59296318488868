import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  useAudioVideo,
  useMeetingManager,
  useRosterState,
} from 'amazon-chime-sdk-component-library-react';
import { useQuery } from 'utils/hooks/useQuery';
import { postMessage as postMessageApi } from 'services/chat';

const DATA_MESSAGE_TOPIC = 'chat';
const DATA_MESSAGE_PRIVATE_CHAT_TOPIC = 'privateChat';
const DATA_MESSAGE_LIFETIME_MS = 5000;

export const useChatMessage = () => {
  const { meetingId } = useParams();
  const meetingManager = useMeetingManager();
  const { attendeeId: selfAttendeeId } =
    meetingManager?.meetingSessionConfiguration?.credentials || {};
  const audioVideo = useAudioVideo();
  const { roster } = useRosterState();
  const query = useQuery();

  const createMessage = useCallback(
    (content) => {
      if (!selfAttendeeId || !content) return;

      const userName = query.userName || '';

      return {
        senderAttendeeId: selfAttendeeId,
        userName,
        content,
        role: query.role,
        timestamp: new Date().getTime(),
      };
    },
    [selfAttendeeId, roster]
  );

  const sendMessage = useCallback(
    async (message) => {
      if (!audioVideo) return;
      const dataMessage = createMessage(message);
      if (!meetingId) return;
      await postMessageApi({
        userId: query.userId,
        courseId: meetingId,
        message,
      });
      audioVideo?.realtimeSendDataMessage(
        DATA_MESSAGE_TOPIC,
        dataMessage,
        DATA_MESSAGE_LIFETIME_MS
      );
    },
    [audioVideo, createMessage, meetingId]
  );

  const receiveMessage = useCallback(
    (callback) => {
      if (!audioVideo) return;
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        DATA_MESSAGE_TOPIC,
        callback
      );
    },
    [audioVideo]
  );

  const createPrivateMessage = useCallback(
    (content, targetRole) => {
      if (!selfAttendeeId || !content) return;

      const userName = query.userName || '';

      return {
        targetRole,
        senderAttendeeId: selfAttendeeId,
        userName,
        content,
        role: query.role,
        timestamp: new Date().getTime(),
      };
    },
    [selfAttendeeId, roster]
  );

  const sendPrivateMessage = useCallback(
    async (message, targetRole) => {
      if (!audioVideo) return;
      const dataMessage = createPrivateMessage(message, targetRole);
      await postMessageApi({
        userId: query.userId,
        courseId: meetingId,
        message,
      });
      audioVideo?.realtimeSendDataMessage(
        DATA_MESSAGE_PRIVATE_CHAT_TOPIC,
        dataMessage,
        DATA_MESSAGE_LIFETIME_MS
      );
    },
    [audioVideo, createPrivateMessage]
  );

  const receivePrivateMessage = useCallback(
    (callback) => {
      if (!audioVideo) return;
      audioVideo.realtimeSubscribeToReceiveDataMessage(
        DATA_MESSAGE_PRIVATE_CHAT_TOPIC,
        callback
      );
    },
    [audioVideo]
  );

  const sendLog = async (message) => {
    await postMessageApi({
      userId: query.userId,
      courseId: meetingId,
      message,
    });
  };

  return {
    createMessage,
    sendMessage,
    receiveMessage,
    sendPrivateMessage,
    receivePrivateMessage,
    sendLog,
  };
};
