import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import styled from 'styled-components';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.videInfo.student.stagedAnimationBox.${key}`,
    '',
    params
  );
};

const StyledStagedAnimationBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding-top: 75%;
  border-radius: 4px;
  background-color: #232a37;
  height: 100%;
  .boxWrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .text {
    margin-top: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
  }
`;

const StagedAnimationBox = () => {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return;
    lottie.loadAnimation({
      container: ref.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/animations/student_stage.json',
    });
  }, [ref]);

  return (
    <StyledStagedAnimationBox>
      <div className="boxWrap">
        <div ref={ref} />
        <div className="text">{trans('__invitationTo')}</div>
      </div>
    </StyledStagedAnimationBox>
  );
};

export default StagedAnimationBox;
