import { useGroupContext } from 'providers/GroupProvider';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.takeTurnsButton.${key}`,
    '',
    params
  );
};

export const TakeTurnsButton = () => {
  const { toggleCarouselPanel } = useGroupContext();

  return (
    <Tooltip title={trans('__takeTurnOnStage')}>
      <div>
        <ToolButton
          icon={<Icon name='UserRotationSolid' size='md' color={'#FF8E8E'} />}
          onClick={toggleCarouselPanel}
        ></ToolButton>
      </div>
    </Tooltip>
  );
};
