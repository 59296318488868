import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledVideoModeControlBar } from './VideoModeControlBar.style';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`components.videoModeControlBar.${key}`, '', params);
};

export const VideoModeControlBar = ({
  className,
  onChangeMode,
  clearStage,
}) => {
  const [mode, setMode] = useState('video');
  const changeModeHandler = (mode) => {
    setMode(mode);
    onChangeMode(mode);
    clearStage();
  };

  return (
    <StyledVideoModeControlBar
      className={className}
      data-testid="VideoModeControlBar"
    >
      <div
        className={`btn ${mode === 'video' ? 'active' : null}`}
        onClick={() => changeModeHandler('video')}
      >
        {trans('__videoMode')}
      </div>
      <div
        className={`btn ${mode === 'object' ? 'active' : null}`}
        onClick={() => changeModeHandler('object')}
      >
        {trans('__objectMode')}
      </div>
    </StyledVideoModeControlBar>
  );
};

VideoModeControlBar.propTypes = {
  className: PropTypes.string,
  onChangeMode: PropTypes.func,
  clearStage: PropTypes.func,
};
