import styled from 'styled-components';
import React from 'react';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as Rocket } from 'assets/newOneBoard/svg/rocket.svg';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`components.newOneBoard.videInfo.common.notOpen.${key}`, '', params);
};

export default function NotOpen() {
  const NotOpen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    background: #bde3e4;
    border-radius: 16px;
  `;

  const NotOpenText = styled.p`
    color: #525252;
    font-size: 14px;
    font-weight: 500;
    line-height: 171.429%;
    letter-spacing: 0.75px;
    text-transform: uppercase;
  `;

  return (
    <NotOpen>
      <StyledIcon as={Rocket} size="67px" />
      <NotOpenText>{trans('__lensIsNotTurnedOn')}</NotOpenText>
    </NotOpen>
  );
}
