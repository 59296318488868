import React, { useRef } from 'react';
import { notification, Tooltip } from 'antd';
import styled from 'styled-components';
import Entry from './assets/entry.svg';
import Exit from './assets/exit.svg';
import Alert from './assets/alert.svg';
import RaiseHand from './assets/raisehand.svg';
import DoorChime from './assets/door_chime.mp3';
import { t } from 'utils/i18n';
import { NOTIFICATION_TYPE } from 'constants';
const trans = (key, params) => {
  return t(`views.utils.hooks.useNotification.${key}`, '', params);
};
const translate = (key, params) => {
  return t(`constants.${key}`, '', params);
};
const NamesContainer = styled.div`
  display: inline-flex;
  color: #212b36;

  .otherName {
    color: #1890ff;
    cursor: pointer;
  }
`;

const TypeText = styled.span`
  color: #212b36;
`;

const IconImage = styled.img`
  vertical-align: top;
  width: 100%;
  height: 100%;
`;

const NOTIFICATION_TYPE_ICONS = {
  進入教室: Entry,
  離開教室: Exit,
  舉手: RaiseHand,
  其他: Alert,
};

const convertNamesToMessage = (names = []) => {
  const [first, ...others] = names;
  const isShowOthers = others && others.length > 0;

  return (
    <NamesContainer>
      <div>{first}</div>
      {isShowOthers && (
        <div>
          {trans('__and')}

          <Tooltip
            title={others.map((other) => (
              <div>{other}</div>
            ))}
            color={'white'}
            overlayInnerStyle={{ color: '#637381' }}
          >
            <span className="otherName">
              {trans('__other', { length: others.length })}
            </span>
          </Tooltip>
        </div>
      )}
    </NamesContainer>
  );
};

const generateMessage = (type, message) => {
  return (
    <div style={{ fontSize: '14px' }}>
      {message} <TypeText>{type}</TypeText>
    </div>
  );
};

const notificationOption = {
  placement: 'bottomLeft',
  duration: 5,
  maxCount: 5,
  bottom: 80,
};

notification.config(notificationOption);

export const useNotification = () => {
  const keyMap = useRef({});
  const audioRef = useRef(new Audio(DoorChime));
  const openNotification = ({ name = 'xxx', type, option = {}, key }) => {
    const origin = keyMap.current[key] || [];
    keyMap.current[key] = [...new Set([...origin, name])];

    const message = convertNamesToMessage(keyMap.current[key]);
    const currentIcon =
      NOTIFICATION_TYPE_ICONS[type] || NOTIFICATION_TYPE_ICONS['其他'];

    if (type === '進入教室') {
      audioRef.current.play();
    }
    let typeAfterConvert = '';
    switch (type) {
      case NOTIFICATION_TYPE.ENTER:
        typeAfterConvert = translate('enter', 'Vào lớp học');

        break;
      case NOTIFICATION_TYPE.LEAVE:
        typeAfterConvert = translate('leave', 'Rời khỏi lớp học');

        break;
      case NOTIFICATION_TYPE.RAISED_HAND:
        typeAfterConvert = translate('raiseHand', 'Giơ tay');

        break;
      case NOTIFICATION_TYPE.NO_FOCUS:
        typeAfterConvert = translate('noFocus', 'Không chú ý');

        break;
      case NOTIFICATION_TYPE.HAND_POSE_RAISED_HAND:
        typeAfterConvert = translate(
          'handPoseRaisedHand',
          'Sử dụng tư thế tay giơ tay'
        );

        break;

      default:
        typeAfterConvert = type;
    }
    notification.open({
      ...option,
      key,
      className: 'customNotification',
      message: generateMessage(typeAfterConvert, message),
      icon: <IconImage src={currentIcon} alt="Check Icon" />,
      style: {
        background: 'white',
        color: '#212B36',
        borderRadius: '8px',
      },
      onClose: () => {
        keyMap.current[key] = [];
      },
    });
  };

  const closeNotification = ({ key }) => {
    notification.close(key);
  };

  return {
    openNotification,
    closeNotification,
  };
};
