import React, { useRef, useEffect, useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@oneboard/ui-components';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { Roles } from 'constants/index';
import { StyledRandomModal, StyledHeader } from './RandomModal.style';
import Icon from '@onedesign/icon';
import { ResizableRect } from 'components';
import { useSize } from 'ahooks';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.randomModal.${key}`, '', params);
};

export const RandomModal = ({ className, onClose }) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const students = attendees
    .filter((attendee) => attendee.role === Roles.Student)
    .map((attendee) => attendee.name);
  const [studentsData, setStudentsData] = useState(students);
  const viewRef = useRef(null);
  const resizableRef = createRef();
  const [parentDom, setParentDom] = useState(null);
  const parentSize = useSize(parentDom);

  let timeMax = 3000;
  let speeds;
  let r;
  let start;
  const nameHeight = 72;
  const nameAmount = studentsData.length;
  const height = nameHeight * nameAmount;

  const animate = (now) => {
    if (!start) start = now;
    const time = now - start || 0;

    viewRef.current.scrollTop =
      ((((speeds / timeMax / 2) * (timeMax - time) * (timeMax - time) + r) %
        height) +
        nameHeight / 2 +
        6) |
      0;

    if (time < timeMax) requestAnimationFrame(animate);
    else {
      start = undefined;
    }
  };

  const randomStartHandler = () => {
    if (start !== undefined) return;

    if (studentsData.length === 0) return;

    speeds = Math.random() + 0.5;
    r = (((Math.random() * nameAmount) | 0) * height) / nameAmount;
    animate();
  };

  useEffect(() => {
    if (students.length >= 2) return;

    setStudentsData([...students, ...students]);
  }, [students.length]);

  useEffect(() => {
    viewRef.current.scrollTop = nameHeight * nameAmount + 6 - nameHeight / 2;
  }, [studentsData.length]);

  useEffect(() => {
    if (!resizableRef.current) return;
    setParentDom(resizableRef.current.parentNode);
  }, [resizableRef]);

  return (
    <ResizableRect ref={resizableRef} resizable={false} isBorder={false}>
      {(ref) => (
        <StyledRandomModal
          ref={ref}
          className={className}
          size={parentSize}
          data-testid="RandomModal"
        >
          <Box className="wrapper">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <StyledHeader>{trans('__pickPersonTool')}</StyledHeader>
              <Box className="closeButton" onClick={onClose}>
                <Icon name="XmarkOutline" />
              </Box>
            </Box>
            <div className="body">
              <div className="viewWrap">
                <div className="randomView" ref={viewRef}>
                  {studentsData.map((student, index) => (
                    <div className="nameList" key={index}>
                      {student}
                    </div>
                  ))}
                  {studentsData.map((student, index) => (
                    <div className="nameList" key={index}>
                      {student}
                    </div>
                  ))}
                </div>
                <div className="chevron chevronRight">
                  <Icon name="ChevronRightOutline" size="xl" />
                </div>
                <div className="chevron chevronLeft">
                  <Icon name="ChevronLeftOutline" size="xl" />
                </div>
              </div>
              <Box display="flex" justifyContent="flex-end" width="100%" pt={4}>
                <Button className="button" onClick={randomStartHandler}>
                  {trans('__start')}
                </Button>
              </Box>
            </div>
          </Box>
        </StyledRandomModal>
      )}
    </ResizableRect>
  );
};

RandomModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};
