import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import Icon from '@onedesign/icon';
import { useCanvasDispatch, useCanvasState } from '@oneboard/whiteboard';
import {
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
  MeetingRoles,
  SettingPopoverButton,
} from '@oneboard/meeting';
import { Popover } from '@oneboard/ui-components';
import { RecordButton, TeachingMaterialModal } from 'containers';
import { MediaMetrics, CustomerService, ResizableRect } from 'components';
import { StyledSingleControlBar, ToolBtn } from './SingleControlBar.style';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.singleControlBar.${key}`, '', params);
};

export const SingleControlBar = ({
  className,
  bookId,
  onLinkToolClick,
  isWhiteboardOpen,
  toggleWhiteboardShow,
  loadTeachingMaterials,
}) => {
  const state = useCanvasState();
  const { toggleTeachingMaterial } = useCanvasDispatch();

  const {
    context: { teachingMaterialState },
  } = state;

  const meetingState = useMeetingState();

  const { startWhiteboard, endWhiteboard } = useMeetingDispatch();

  const isWhiteboarding = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Teacher}.${MeetingStates.Whiteboard}.${MeetingStates.Whiteboarding}`,
  });

  const toggleTeachingMaterialHandler = useCallback(
    () => toggleTeachingMaterial(teachingMaterialState),
    [teachingMaterialState]
  );

  const { roster } = useRosterState();
  const attendeeIds = Object.keys(roster);

  const openWhiteboardHandler = useCallback((attendeeIds) => {
    startWhiteboard({ attendeeIds });
  }, []);

  const openAllAttendeeWhiteboard = () => {
    openWhiteboardHandler(attendeeIds);
  };

  useEffect(() => {
    if (isWhiteboarding) {
      openAllAttendeeWhiteboard();
    } else {
      endWhiteboard();
    }
  }, [roster]);

  const changeTeachingMaterialHandler = (materials) => {
    loadTeachingMaterials(materials);
    toggleTeachingMaterialHandler();
  };

  return (
    <ResizableRect resizable={false} isBorder={false}>
      {(ref) => (
        <StyledSingleControlBar
          className={className}
          data-testid="SingleControlBar"
          ref={ref}
        >
          <ToolBtn>
            <RecordButton
              mode="dark"
              classType="noSync"
              placement="leftBottom"
              content={trans('__startClassReminder')}
            />
          </ToolBtn>
          <Popover
            trigger="click"
            visible={teachingMaterialState}
            placement="rightTop"
            content={
              <TeachingMaterialModal
                onClose={toggleTeachingMaterialHandler}
                onChange={changeTeachingMaterialHandler}
                currentBookId={bookId}
              />
            }
          >
            <ToolBtn
              title={trans('__addMaterial')}
              onClick={toggleTeachingMaterialHandler}
            >
              <Icon name="FolderOpenSolid" />
            </ToolBtn>
          </Popover>

          <ToolBtn
            onClick={onLinkToolClick}
            title={trans('__websiteResources')}
          >
            <Icon name="GlobeSolid" />
          </ToolBtn>

          <ToolBtn
            active={isWhiteboardOpen}
            onClick={toggleWhiteboardShow}
            title={trans('__whiteboard')}
          >
            <Icon name="ChalkboardSolid" />
          </ToolBtn>
          <ToolBtn title={trans('__customerService')}>
            <CustomerService />
          </ToolBtn>

          <ToolBtn>
            <SettingPopoverButton
              className="settingPopoverButton"
              placement="right"
            />
          </ToolBtn>
          <ToolBtn>
            <MediaMetrics placement="right" />
          </ToolBtn>
        </StyledSingleControlBar>
      )}
    </ResizableRect>
  );
};

SingleControlBar.propTypes = {
  className: PropTypes.string,
  bookId: PropTypes.string,
  onLinkToolClick: PropTypes.func,
  isWhiteboardOpen: PropTypes.bool,
  toggleWhiteboardShow: PropTypes.func,
  loadTeachingMaterials: PropTypes.func,
};
