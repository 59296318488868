import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'utils/hooks/useQuery';

export const useBitrix = () => {
  const insertCRMChat = useCallback(() => {
    (function (w, d, u) {
      var s = d.createElement('script');
      s.async = true;
      s.src = u + '?' + ((Date.now() / 60000) | 0);
      var h = d.getElementsByTagName('script')[0];
      h.parentNode.insertBefore(s, h);
    })(
      window,
      document,
      'https://cdn.bitrix24.com/b28621967/crm/site_button/loader_3_dm8rym.js'
    );
  }, []);

  const showCRMChat = useCallback(() => {
    window?.BX?.SiteButton?.show();
  }, []);

  const hideCRMChat = useCallback(() => {
    window?.BX?.SiteButton?.hide();
  }, []);

  const removeCRMChat = useCallback(() => {
    document
      .querySelector('div[data-b24-crm-button-shadow]')
      ?.parentNode.remove();
  }, []);

  return { insertCRMChat, showCRMChat, hideCRMChat, removeCRMChat };
};

const baseUrl = 'https://oneclassvn.bitrix24.com/online/board';

export const useBitrixService = () => {
  const query = useQuery();
  const [url, setUrl] = useState('');
  const meetingId = window.location.pathname.replace('/', '');

  const initUrl = () => {
    const nextUrl = `${baseUrl}?roomId=${meetingId}&classType=${query?.classType}&role=${query.role}&userId=${query.userId}&userName=${query.userName}`;
    setUrl(nextUrl);
  };

  useEffect(() => {
    initUrl();
  }, []);

  return {
    url,
  };
};
