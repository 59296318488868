import Icon from '@onedesign/icon';
import { useGroupContext } from 'providers/GroupProvider';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import { useMeetingState } from '@oneboard/meeting';
import { ClassType } from 'constants/index';
import UsersModalV2 from 'containers/UsersModalV2';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import styled from 'styled-components';
import { t } from 'utils/i18n';
import { Tooltip } from 'antd';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.participantButton.${key}`,
    '',
    params
  );
};

const TitleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 40px;
  align-self: stretch;
  padding: 18px 16px 0;
`;

const Title = styled.p`
  margin: 0;
  color: #919eab;
  font-size: 12px;
`;

const NameTitle = styled(Title)`
  width: 190px;
`;

const TrophyTitle = styled(Title)`
  width: 100px;
`;

export const ParticipantButton = ({ currentRole }) => {
  const { toggleUsersModal, usersModalState } = useGroupContext();
  const state = useMeetingState();
  const { context } = state;
  const { courseType } = context;
  const toggleUsersModalHandler = () => {
    toggleUsersModal();
  };

  return (
    <>
      <Tooltip title={trans('__participants')}>
        <div>
          <ToolButton
            icon={<Icon name='UsersSolid' size='md' color='#68B783' />}
            onClick={toggleUsersModalHandler}
          ></ToolButton>
        </div>
      </Tooltip>
      {usersModalState && (
        <ToolModal
          active={usersModalState}
          onClose={toggleUsersModal}
          title={trans('__participantsTitle')}
          height='419px'
        >
          {courseType === ClassType.SyncMultiple && (
            <TitleContainer>
              <NameTitle>{trans('__name')}</NameTitle>
              <TrophyTitle>{trans('__numberOfTrophies')}</TrophyTitle>
              <Title>{trans('__state')}</Title>
            </TitleContainer>
          )}

          <UsersModalV2.UsersModal
            onClose={toggleUsersModalHandler}
            currentRole={currentRole}
          />
        </ToolModal>
      )}
    </>
  );
};
