import React, { useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useMeetingManager,
  useAudioInputs,
  useVideoInputs,
  useDeviceLabelTriggerStatus,
} from 'amazon-chime-sdk-component-library-react';
import {
  CameraSelection,
  PreviewVideo,
  MicSelection,
  MicrophoneActivityPreview,
  SpeakerSelection,
  SpeakerTestButton,
  useMeetingDispatch,
  useMeetingState,
  MeetingStates,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from 'utils/hooks/useQuery';
import { useBitrix } from 'utils/hooks/useBitrix';
import { Box, Button, Modal, Loading } from '@oneboard/ui-components';
import { useCheckDevice } from 'utils/hooks/useCheckDevice';
import { DEVICE_PERMISSION_STATE, Roles } from 'constants/index';
import { useWhiteboard } from '@oneboard/whiteboard';
import { Exception } from 'components';
import {
  StyledDeviceSetupPage,
  DeviceSelectForm,
  StyledSettingGroup,
  StyledPreviewGroup,
  StyledWaitingPage,
  StyledCheckDevicePage,
} from './DeviceSetupPage.style';
import { t } from 'utils/i18n';

export const isOptionActive = (meetingManagerDeviceId, currentDeviceId) => {
  if (currentDeviceId === 'none' && meetingManagerDeviceId === null) {
    return true;
  }
  return currentDeviceId === meetingManagerDeviceId;
};

const RedirectButton = ({ startMeeting, startLoading }) => {
  const startHandler = async () => {
    if (startLoading) return;
    await startMeeting();
  };

  return (
    <Button className="primaryBtn" onClick={startHandler}>
      <Box display="flex" alignItems="center">
        {startLoading && (
          <Box mr={2}>
            <LoadingOutlined style={{ color: '#ffffff' }} />
          </Box>
        )}
        {t('deviceSetupPage.classBegin', '開始上課')}
      </Box>
    </Button>
  );
};

RedirectButton.propTypes = {
  startMeeting: PropTypes.func,
  startLoading: PropTypes.bool,
};

export const DeviceSetupPage = ({ className }) => {
  const location = useLocation();
  const history = useHistory();
  const meetingManager = useMeetingManager();
  const [deviceModal, setDeviceModal] = useState(false);
  const { meetingId, breakoutId } = useParams();
  const query = useQuery();
  const { joinMeeting } = useMeetingDispatch();
  const meetingState = useMeetingState();
  const { devices: audioDevices } = useAudioInputs();
  const { devices: videoDevices } = useVideoInputs();
  const isJoined = meetingState.matches(MeetingStates.Joined);
  const isJoining = meetingState.matches(MeetingStates.Joining);
  const permissionState = useDeviceLabelTriggerStatus();
  const { allNotCheck } = useCheckDevice();
  const { joinWhiteboard } = useWhiteboard();
  const [hasDevice, setHasDevice] = useState(false);
  const [startLoading, setStartLoading] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  let pathname = `/${meetingId}`;

  const toggleDeviceModal = () => setDeviceModal((prev) => !prev);

  const join = async () => {
    let roomId = meetingId;

    joinMeeting({
      roomId,
      userName: query.userName,
      role: query.role,
      userId: query.userId,
    });
  };

  const startMeeting = async () => {
    setStartLoading((prev) => !prev);
    await joinWhiteboard({
      meetingId,
      role: query.role,
      userName: query.userName,
      userId: query.userId,
    });
    await meetingManager.start();
    setStartLoading((prev) => !prev);
    history.push({
      pathname,
      search: `?${searchParams.toString()}`,
    });
  };

  const init = async () => {
    if (!meetingId) return;
    if (breakoutId) return;

    await join();
  };

  useEffect(async () => {
    await init();
  }, []);

  const { insertCRMChat, hideCRMChat } = useBitrix();
  useEffect(() => {
    insertCRMChat();
    return () => {
      if (query.role !== Roles.Student) {
        hideCRMChat();
      }
    };
  }, [query.role]);

  const isFailure = meetingState.matches(MeetingStates.Failure);

  const joinCondition = useMemo(() => {
    let condition = false;
    const checkCam = query.checkCam === 'true';
    const checkMic = query.checkMic === 'true';

    if (query.checkCam && query.checkMic) {
      if (checkCam && checkMic) {
        condition =
          isJoined && audioDevices.length > 0 && videoDevices.length > 0;
      } else if (!checkCam && checkMic) {
        condition = isJoined && audioDevices.length > 0;
      } else if (checkCam && !checkMic) {
        condition = isJoined && videoDevices.length > 0;
      } else {
        condition = isJoined;
      }
    } else {
      condition =
        isJoined && audioDevices.length > 0 && videoDevices.length > 0;
    }

    return condition;
  }, [audioDevices.length, videoDevices.length, isJoined]);

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log('enumerateDevices() not supported.');
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          if (devices.length > 0) {
            setHasDevice(true);
          }
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }
  }, []);

  if (!query?.verified) {
    history.push({
      pathname: '/notFound',
      search: `?${searchParams.toString()}`,
    });
  }

  return (
    <StyledDeviceSetupPage className={className} data-testid="DeviceCheckPage">
      {deviceModal && (
        <Modal
          header={t('deviceSetupPage.equipmentInspection', '設備檢查')}
          onClose={toggleDeviceModal}
        >
          <DeviceSelectForm>
            <StyledSettingGroup>
              <Box mb={3}>
                <CameraSelection />
              </Box>
              <Box mb={3}>
                <MicSelection />
              </Box>
              <Box mb={3}>
                <SpeakerSelection />
              </Box>
            </StyledSettingGroup>
            <StyledPreviewGroup>
              <Box mb={3}>
                <PreviewVideo role={query.role} />
              </Box>
              <Box mb={3}>
                <MicrophoneActivityPreview />
              </Box>
              <Box mb={3}>
                <SpeakerTestButton />
              </Box>
            </StyledPreviewGroup>
          </DeviceSelectForm>
        </Modal>
      )}
      {isJoining && permissionState === DEVICE_PERMISSION_STATE.UNTRIGGERED && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <LoadingOutlined style={{ color: '#ffffff' }} />
        </Box>
      )}

      {isJoining && permissionState === DEVICE_PERMISSION_STATE.IN_PROGRESS && (
        <StyledCheckDevicePage>
          <div className="pic">
            <img src="/allowDevice.svg" alt="" />
          </div>
          <div className="desc">
            {t(
              'deviceSetupPage.askToClickAllow',
              '在瀏覽器上選擇「允許」\n使用您的麥克風和相機繼續進行操作'
            )}
          </div>
          <div className="tip">
            <a
              href="https://www.google.com/intl/zh-TW/chrome/"
              className="downloadLink"
              target="_blank"
              rel="noreferrer"
            >
              {t(
                'deviceSetupPage.chromeRecommendation',
                '建議使用 <span>Google Chrome</span> 瀏覽器以獲得最佳瀏覽體驗'
              )}
            </a>
          </div>
        </StyledCheckDevicePage>
      )}

      {isJoined &&
        !deviceModal &&
        (permissionState === DEVICE_PERMISSION_STATE.UNTRIGGERED ||
          permissionState === DEVICE_PERMISSION_STATE.GRANTED) && (
          <StyledWaitingPage>
            <div className="wrapper">
              <div className="video">
                {
                  <div className="previewVideo">
                    {!deviceModal && (
                      <PreviewVideo label={false} role={query.role} />
                    )}
                  </div>
                }
              </div>
              {!allNotCheck && hasDevice && (
                <div className="setting">
                  <button className="settingBtn" onClick={toggleDeviceModal}>
                    <Icon name="CogSolid" />
                    <span>
                      {t(
                        'deviceSetupPage.checkAudioAndVideo',
                        '檢查音訊及視訊'
                      )}
                    </span>
                  </button>
                </div>
              )}
              <div className="control">
                {joinCondition ? (
                  <RedirectButton
                    startMeeting={startMeeting}
                    startLoading={startLoading}
                  />
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </StyledWaitingPage>
        )}

      {isJoined && permissionState === DEVICE_PERMISSION_STATE.DENIED && (
        <StyledCheckDevicePage>
          <div className="pic">
            <img src="/notAllowDevice.svg" alt="" />
          </div>
          <div className="desc">
            {t(
              'deviceSetupPage.resolveAudioAndVideoError',
              '尚未開啟音訊或視訊？\n請選取瀏覽器中網址列旁的相機圖示，然後選取「永遠允許」'
            )}
          </div>
          <div className="tip">
            <a
              href="https://www.google.com/intl/zh-TW/chrome/"
              className="downloadLink"
              target="_blank"
              rel="noreferrer"
            >
              {t(
                'deviceSetupPage.chromeRecommendation',
                '建議使用 Google Chrome 瀏覽器以獲得最佳瀏覽體驗'
              )}
            </a>
          </div>
        </StyledCheckDevicePage>
      )}

      {isFailure && (
        <Modal header={t('deviceSetupPage.warn', '警告')} closeIcon={false}>
          {t(
            'deviceSetupPage.connectToRoomFailed',
            '連結房間失敗，請點選「重新整理」再次連結'
          )}
          <Box display="flex" justifyContent="flex-end" mt={5}>
            <Button onClick={() => window.location.reload()}>
              {t('deviceSetupPage.checkAudioAndVideo', '檢查音訊及視訊')}
            </Button>
          </Box>
        </Modal>
      )}

      <div className="version">{process.env.REACT_APP_VERSION || '0.0.1'}</div>
    </StyledDeviceSetupPage>
  );
};

DeviceSetupPage.propTypes = {
  className: PropTypes.string,
};
