import React, { useRef } from 'react';
import { 
  useMeetingManager,
  useAudioInputs,
  useLocalAudioInputActivityPreview
} from 'amazon-chime-sdk-component-library-react';
import { t } from "utils/i18n";
import { Box, FullButton, Select } from '@oneboard/ui-components';
import { useDeviceDetectDispatch, useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import Icon from '@onedesign/icon';
import { StyledMicrophoneStep, StyledTestBar } from './MicrophoneStep.style';

const ActivityBar = () => {
  const activityBarRef = useRef();
  useLocalAudioInputActivityPreview(activityBarRef);

  return (
    <StyledTestBar>
      <div className="baseLayer"></div>
      <div className="bar" ref={activityBarRef}></div>
      <div className="coverBox"></div>
    </StyledTestBar>
  )
}

export const MicrophoneStep = ({ className }) => {
  const trans = (key, params) => {
    return t(
      `packages.deviceDetect.containers.microphoneStep.${key}`,
      "",
      params
    );
  };
  const { goAudio, updateEquipment } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const state = deviceDetectState.context;

  const meetingManager = useMeetingManager();
  const { devices, selectedDevice } = useAudioInputs();
  
  const deviceChangeHandler = (deviceId) => meetingManager.startVideoInputDevice(deviceId);
  
  const abnormalHandler = () => {
    updateEquipment({
      microphone: false
    });
    goAudio();
  }

  const normalHandler = () => {
    updateEquipment({
      microphone: true
    });
    goAudio();
  }
  
  return (
    <StyledMicrophoneStep className={className} data-testid="MicrophoneStep">
      <div className="microphoneBlock">
        <div className="microphoneStepWrap">
          {devices.length > 0 && <Select className="select" defaultValue={selectedDevice} onChange={deviceChangeHandler}>
            {devices.map((item) => <Select.Option 
              value={item.deviceId} 
              key={item.deviceId}>
                {item.label}
              </Select.Option>)}
          </Select>}
          <div className="microphoneIcon">
            <Icon name="MicrophoneAltSolid" size='xl' color="#2F3746" />
          </div>
          <ActivityBar />
          <div className="content">
            <div className="title">{trans('__speak')}</div>
            <div className="desc">{trans('__microphoneTestMessage')}</div>
          </div>
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            {trans('__observeColorMovement')}
          </div>
          <div className="action">
            <Box mr={1} width="100%">
              <FullButton.Secondly onClick={abnormalHandler}>{trans('__notClear')}</FullButton.Secondly>
            </Box>
            <Box ml={1} width="100%">
              <FullButton onClick={normalHandler}>{trans('__clear')}</FullButton>
            </Box>
          </div>
        </div>
      </Box>
    </StyledMicrophoneStep>
  );
};