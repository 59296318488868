import React, { useCallback } from 'react';
import { useFooterStatus } from 'providers/FooterStatusProvider';
import {
  useMeetingState,
  MeetingStates,
  MeetingRoles,
  useMeetingDispatch,
} from '@oneboard/meeting';
import Icon from '@onedesign/icon';
import ToolButton from 'components/NewOneBoard/common/ToolButton';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(
    `components.newOneBoard.footer.components.raiseHandButton.${key}`,
    '',
    params
  );
};

export const RaiseHandButton = () => {
  const meetingState = useMeetingState();
  const { isFooterOpen } = useFooterStatus();
  const { raiseHand, lowerHand } = useMeetingDispatch();
  const { attendeeId: selfAttendeeId } = meetingState.context;

  const isRaisingHand = meetingState.matches({
    [MeetingStates.Joined]: `${MeetingRoles.Student}.${MeetingStates.RaiseHand}.${MeetingStates.RaisingHand}`,
  });

  const toggleHand = useCallback(() => {
    const handler = isRaisingHand ? lowerHand : raiseHand;
    handler({ attendeeId: selfAttendeeId });
  }, [isRaisingHand, selfAttendeeId]);

  return (
    <ToolButton
      icon={
        <Icon
          name="RaisehandSolid"
          size="md"
          color={isRaisingHand ? '#07B2D8' : '#919EAB'}
        />
      }
      onClick={() => toggleHand()}
    >
      {isFooterOpen && trans('__raiseHand')}
    </ToolButton>
  );
};
