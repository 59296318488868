import React, { useEffect } from 'react';
import { useBitrix } from 'utils/hooks/useBitrix';
import {
  StyledNotFoundPage,
  StyledHead,
  StyledContent,
} from './NotFoundPage.style';
import { t } from 'utils/i18n';
const trans = (key, params) => {
  return t(`views.notFoundPage.${key}`, '', params);
};

export const NotFoundPage = ({ className }) => {
  const { insertCRMChat } = useBitrix();

  useEffect(() => {
    insertCRMChat();
  }, []);

  return (
    <StyledNotFoundPage className={className} data-testid="NotFoundPage">
      <StyledHead>
        <div className="logo">
          <img src="/oneClass.svg" alt="" />
        </div>
      </StyledHead>
      <StyledContent>
        <div className="notFoundPic">
          <img src="/notFound.svg" alt="" />
        </div>
        <div className="title">Page Not Found</div>
        <div className="desc">
          {trans('__webPageYouWantIsNotHere')}
          <br />
          {trans('__youCanGoTo')}
          <a
            href="https://tutor.oneclass.com.tw"
            className="highlight"
            target="_blank"
            rel="noreferrer"
          >
            {trans('__oneClassLiveTutoring')}
          </a>{' '}
          {trans('__takeALook')}
        </div>
      </StyledContent>
    </StyledNotFoundPage>
  );
};
