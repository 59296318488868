import React, { useState } from 'react';
import { Box, FullButton, Select } from '@oneboard/ui-components';

import {t} from 'utils/i18n';
import { useDeviceDetectDispatch, useDeviceDetectState } from '../../providers/DeviceDetectProvider';
import { InfoInput } from '../../components/InfoInput';
import Icon from '@onedesign/icon';
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { StyledResultStep } from './ResultStep.style';
const trans = (key, params) => {
  return t(`packages.deviceDetect.containers.resultStep.${key}`, '', params);
};

const deviceType = ['video', 'microphone', 'audio'];

const networkUnit = {
  speedDown: 'Mbps',
  speedUp: 'Mbps',
  speedPing: 'ms',
  speedJitter: 'ms',
};

const roleType = ['請選擇身份', '學生', '老師'];

const constantsMap = {
  user: {
    name: '姓名',
    phone: '聯絡電話',
    role: '身份'
  },
  equipment: {
    video: '視訊',
    microphone: '麥克風',
    audio: '播放設備'
  },
  device: {
    type: '使用設備',
    mobileSystem: '手機系統',
    tableBoard: '平板品牌',
    otherBoard: '其他品牌'
  },
  network: {
    ip: 'IP位置',
    speedDown: '網路下載',
    speedUp: '網路上傳',
    speedPing: '網路PING',
    speedJitter: '網路抖動',
    type: '連線方式',
    source: '網路來源',
    other: '其他'
  },
  audio: {
    device: '播放裝置',
    otherBoard: '其他品牌'
  },
}

const combineMessage = (storeData) => {
  const msgArray = [];
  const stepCategory = Object.keys(constantsMap);

  stepCategory.forEach(category => {
    const categoryItems = Object.keys(constantsMap[category]);

    categoryItems.forEach(item => {
      let data = '';

      switch (category) {
        case 'equipment':
          data = `${constantsMap[category][item]}：${storeData[category][item] ? trans('__normal') : trans('__abnormal')}`
          break;
        case 'network':
          data = `${constantsMap[category][item]}：${storeData[category][item] ? storeData[category][item] : ''} ${networkUnit[item] ? networkUnit[item] : ''}`
          break;
        default:
          data = `${constantsMap[category][item]}：${storeData[category][item] ? storeData[category][item] : ''}`
          break;
      }

      msgArray.push(data)
    })
  })
  msgArray.push(`userAgent：${navigator.userAgent}`);

  const bxMsg = msgArray.join('#BR#');

  return bxMsg
};

const formatMessage = (msg) => {
  return msg.replace(/#BR#/g, `\n`)
}

export const ResultStep = ({ className, postBitrix, postBitrixOrder, orderId }) => {
  const { resetState } = useDeviceDetectDispatch();
  const deviceDetectState = useDeviceDetectState();
  const {
    equipment: equipmentData,
    audio: audioData,
    network: networkData,
    device: deviceData
  } = deviceDetectState.context;

  const [nameVal, setNameVal] = useState('');
  const [phoneVal, setPhoneVal] = useState('');
  const [roleVal, setRoleVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  const submitHandler = async () => {
    if ( isLoading ) return

    setIsLoading(true);

    const userInfo = {
      name: nameVal,
      phone: phoneVal,
      role: roleVal
    };
    const bitrixData = {
      user: userInfo,
      equipment: equipmentData,
      audio: audioData,
      network: networkData,
      device: deviceData
    };

    const message = combineMessage(bitrixData);

    await postBitrix({ message }).catch(error => console.error(error));

    if (orderId) {
      const nextMessage = formatMessage(message);

      await postBitrixOrder({
        orderId,
        testTime: dayjs().format(),
        testData: nextMessage
      }).catch(error => console.error(error));
    }

    alert(trans('__resultsSubmitted'));
    setIsLoading(false);
    setIsSubmit(true);
  };

  return (
    <StyledResultStep className={className} data-testid="ResultStep">
      <div className="resultBlock">
        <div className="itemWrap info">
          <div className="title">{trans('__testResults')}</div>
          <div className="content">
            <Box mt={3}>
              <div className="resultTable">
                {deviceType.map(item => (
                  <div className="item" key={item}>
                    <div className="head">{constantsMap.equipment[item]}</div>
                    <div className="content">
                      {equipmentData[item] ? <Icon name="CheckCircleSolid" size='lg' color="#38CB89" /> :
                      <Icon name="XmarkCircleSolid" size='lg' color="#FF5630" />}
                    </div>
                  </div>
                ))}
              </div>
            </Box>
            <Box mt={3}>
              <div className="resultSingleTable">
                <div className="head">{trans('__deviceList')}</div>
                <div className="content">
                  <div className="list">{`${trans('__connectionType')}：${networkData.type ? networkData.type : ''}`}</div>
                  <div className="list">{`${trans('__networkSource')}：${networkData.source ? networkData.source : ''}`}</div>
                  <div className="list">{`${trans('__deviceInUse')}：${deviceData.type ? deviceData.type : ''}`}</div>
                  <div className="list">{`${trans('__playbackDevice')}：${audioData.device ? audioData.device : ''}`}</div>
                </div>
              </div>
            </Box>
            <Box mt={3}>
              <div className="resultSingleTable">
                <div className="head">{trans('__networkTestResults')}</div>
                <div className="content">
                  <div className="list">{`${trans('__ipLocation')}：${networkData.ip}`}</div>
                  <div className="list">{`${trans('__networkDownload')}：${networkData.speedDown} ${networkUnit.speedDown}`}</div>
                  <div className="list">{`${trans('__networkUpload')}：${networkData.speedUp} ${networkUnit.speedUp}`}</div>
                  <div className="list">{`${trans('__networkPing')}：${networkData.speedPing} ${networkUnit.speedPing}`}</div>
                  <div className="list">{`${trans('__networkJitter')}：${networkData.speedJitter} ${networkUnit.speedJitter}`}</div>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <div className="itemWrap">
          <Box>
            <InfoInput placeholder={trans('__name')}  disabled={isSubmit} onChange={e => setNameVal(e.target.value)} />
            <Box mt={3}>
              <InfoInput placeholder={trans('__contactPhoneNumber')} disabled={isSubmit} onChange={e => setPhoneVal(e.target.value)} />
            </Box>
            <Box mt={3}>
              <Select className="select" defaultValue='請選擇身份' disabled={isSubmit} onChange={setRoleVal}>
                {roleType.map((item) => {
                  let currentRoleType ;
                  if(item === '學生') {
                    currentRoleType = trans('__student')
                  } else if(item === '老師'){
                    currentRoleType = trans('__teacher')
                  } else {
                    currentRoleType = trans('__selectIdentity')
                  }

                  return (<Select.Option
                      value={item}
                      disabled={item === '請選擇身份'}
                      key={item}>
                    {currentRoleType}
                  </Select.Option>)
                })}
              </Select>
            </Box>
            {/* <Box mt={1}>
              <div className="errorMsg">請先填寫姓名與手機再提交</div>
            </Box> */}
          </Box>
          <div className="notice">

          </div>
        </div>
      </div>
      <Box mt={4}>
        <div className="question">
          <div className="title">
            {trans('__confirmResultsAndSubmit')}<br />
            {trans('__contactCustomerServiceIfIssue')}
          </div>
          <div className="action">
            {!isSubmit && <div className="btn primary">
              <FullButton onClick={submitHandler} disabled={(nameVal === '' || phoneVal === '')}>
                {isLoading ? <LoadingOutlined /> : trans('__submit')}
              </FullButton>
            </div>}
            <div className="btn secondly">
              <FullButton.Secondly onClick={resetState}>{trans('__retest')}</FullButton.Secondly>
            </div>
          </div>
        </div>
      </Box>
    </StyledResultStep>
  );
};