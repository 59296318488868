import React, { useState, useEffect, useMemo } from 'react';
import { Checkbox, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useRosterState } from 'amazon-chime-sdk-component-library-react';
import { StyledWhiteboardAttendeeList } from './WhiteboardAttendeeList.style';
import { Roles } from 'constants/index';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`containers.whiteboardAttendeeList.${key}`, '', params);
};

const CheckboxGroup = Checkbox.Group;

export const WhiteboardAttendeeList = ({ onChange = () => {} }) => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const options = useMemo(
    () =>
      attendees
        .filter((attendee) => attendee.role === Roles.Student)
        .map((attendee) => ({
          label: attendee?.name,
          value: attendee.chimeAttendeeId,
        })),
    [attendees]
  );

  const handleChange = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
    setCheckAll(list.length === options.length);
  };

  const handleAllChange = (e) => {
    const allValues = options.map((item) => item.value);
    setCheckedList(e.target.checked ? allValues : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  useEffect(() => {
    onChange(checkedList);
  }, [checkedList]);

  return (
    <StyledWhiteboardAttendeeList data-testid="WhiteboardAttendeeList">
      <div className="checkAll">
        <Checkbox
          indeterminate={indeterminate}
          onChange={handleAllChange}
          checked={checkAll}
        >
          {trans('__selectAll')}
        </Checkbox>
      </div>
      <Divider />
      <div className="group">
        <CheckboxGroup
          options={options}
          value={checkedList}
          onChange={handleChange}
        />
      </div>
    </StyledWhiteboardAttendeeList>
  );
};

WhiteboardAttendeeList.propTypes = {
  currentAttendeeId: PropTypes.string,
  onChange: PropTypes.func,
};
