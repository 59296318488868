import React, { createContext, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useWhiteboard } from '@oneboard/whiteboard';
import { Modal, Box, Button } from '@oneboard/ui-components';
import { useModal } from 'utils/hooks/useModal';
import { StyledCleanConfirmModal } from './CleanConfirmModal.style';
import { t } from '../../utils/i18n';
import ToolModal from 'components/NewOneBoard/common/ToolModal';
import styled from 'styled-components';
import {
  CancelButton,
  SendButton,
} from 'components/NewOneBoard/common/MessageButton';

const ButtonContainer = styled(Box)`
  display: flex;
  height: 68px;
  justify-content: flex-end;
  gap: 12px;
  width: 100%;
  align-items: center;
`;

const ModalText = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  color: #637381;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding-left: 8px;
`;

export const CleanConfirmModalContext = createContext({});
export const useCleanConfirmModal = () => useContext(CleanConfirmModalContext);

export const CleanConfirmModal = ({ children }) => {
  const { service: whiteboardService } = useWhiteboard();
  const { isOpen, closeModal, openModal } = useModal(false);

  const value = useMemo(
    () => ({
      isOpen,
      closeModal,
      openModal,
    }),
    [isOpen, closeModal, openModal]
  );

  const handleConfirm = useCallback(() => {
    if (!whiteboardService) return;
    whiteboardService.cleanCurrentScene();
    closeModal();
  }, [whiteboardService]);

  return (
    <CleanConfirmModalContext.Provider value={value}>
      {children}
      {isOpen && (
        <StyledCleanConfirmModal>
          <ToolModal
            active={isOpen}
            onClose={closeModal}
            title={t(
              'containers.cleanConfirmationModal.__clearCanvas',
              'Clear canvas'
            )}
            footerHeight="84px"
            footerContent={
              <ButtonContainer>
                <CancelButton block size="large" onClick={closeModal}>
                  {t('containers.cleanConfirmationModal.cancel', '取消')}
                </CancelButton>
                <SendButton block size="large" onClick={handleConfirm}>
                  {t('containers.cleanConfirmationModal.confirm', '確認')}
                </SendButton>
              </ButtonContainer>
            }
          >
            <ModalText>
              {t(
                'containers.cleanConfirmationModal.__confirmClearCanvas',
                'Do u want to clear canvas?'
              )}
            </ModalText>
          </ToolModal>
        </StyledCleanConfirmModal>
      )}
    </CleanConfirmModalContext.Provider>
  );
};

CleanConfirmModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
