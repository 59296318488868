/**
 * variable & Object key 大寫
 */
import { t } from 'utils/i18n';

export const ClassType = {
  Single: 'single',
  Group: 'group',
  Multiple: 'multiple',
  SyncSingle: 'sync-single',
  SyncMultiple: 'sync-multiple',
  SyncStreaming: 'sync-streaming',
};

export const ClassStyle = {
  Normal: 'normal',
  Trial: 'trial',
};

export const Roles = {
  Teacher: 'teacher',
  Student: 'student',
  Advisor: 'advisor',
  Observer: 'observer',
};

export const NOTIFICATION_TYPE_KEY = {
  ENTER: 'ENTER',
  LEAVE: 'LEAVE',
  RAISED_HAND: 'RAISED_HAND',
  NO_FOCUS: 'NO_FOCUS',
  HAND_POSE_RAISED_HAND: 'HAND_POSE_RAISED_HAND',
};

export const NOTIFICATION_TYPE = {
  [NOTIFICATION_TYPE_KEY.ENTER]: t('constants.enter', 'Vào lớp học'),
  [NOTIFICATION_TYPE_KEY.LEAVE]: t('constants.leave', 'Rời khỏi lớp học'),
  [NOTIFICATION_TYPE_KEY.RAISED_HAND]: t('constants.raiseHand', 'Giơ tay'),
  [NOTIFICATION_TYPE_KEY.NO_FOCUS]: t('constants.noFocus', 'Không chú ý'),
  [NOTIFICATION_TYPE_KEY.HAND_POSE_RAISED_HAND]: t(
    'constants.handPoseRaisedHand',
    'Sử dụng tư thế tay giơ tay'
  ),
};

export const PRODUCT_TYPE = {
  onelink: 'onelink',
  liveoneclass: 'liveoneclass',
  mmsTeacher: 'mmsTeacher',
};

export const API_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

// 學制
export const EDUCATION_LEVEL = {
  ELEMENTARY: 'E',
  JUNIOR: 'J',
  SENIOR: 'H',
  OTHER: 'other',
};

export const DEVICE_PERMISSION_STATE = {
  UNTRIGGERED: 'UNTRIGGERED',
  IN_PROGRESS: 'IN_PROGRESS',
  GRANTED: 'GRANTED',
  DENIED: 'DENIED',
};

export const FCM_TOPIC = {
  ONEBOARD_COURSE_FEEDBACK: 'oneboardCourseFeedback',
};
