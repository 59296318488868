import React from 'react';
import { StyledInput } from './Input.style';
import { t } from "utils/i18n";

export const Input = ({ 
  className, 
  placeholder = '請填寫品牌名稱',
  value,
  onChange,
  disabled = false
}) => {
const trans = (key, params) => {
  return t(`packages.deviceDetect.components.input.${key}`, "", params);
};
return (
  <StyledInput
    className={className}
    placeholder={ placeholder === "請填寫品牌名稱" ? trans("__placeholder") : placeholder}
    value={value}
    onChange={onChange}
    allowClear={true}
    data-testid="Input"
    disabled={disabled}
  ></StyledInput>
);
};