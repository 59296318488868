import React, { useState } from 'react';
import { Input, Form, Button, Row, Col, message, Select } from 'antd';
import { Loading, Box } from '@oneboard/ui-components';
import { shortId } from 'utils';
import { urlFormat } from 'utils/url';
import { ClassType, Roles } from 'constants/index';
import { createCourse } from 'services/course';
import { StyledCreateMeetingPage } from './CreateMeetingPage.style';
import { t } from 'utils/i18n';

const trans = (key, params) => {
  return t(`views.createMeetingPage.${key}`, '', params);
};

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const ClassTypeMap = {
  [ClassType.Single]: '1 v 1',
  [ClassType.Group]: '1 v 6',
  [ClassType.Multiple]: '1 v more',
  [ClassType.SyncSingle]: 'sync-single',
  [ClassType.SyncMultiple]: 'sync-multiple',
};

export const CreateMeetingPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [urls, setUrl] = useState({
    teacherUrl: '',
    studentUrl: '',
    advisorUrl: '',
    observerUrl: '',
  });

  const createUrl = (role, formData) => {
    let query = {
      role,
      classType: formData.classType,
    };

    switch (role) {
      case Roles.Teacher:
        query.userName = formData.teacherName;
        query.bookIds =
          formData.bookIds &&
          formData.bookIds.split(',').map((bookId) => bookId.trim());
        break;
      case Roles.Student:
        query.userName = formData.studentName;
        break;
      case Roles.Advisor:
        query.userName = formData.advisorName;
        query.bookIds =
          formData.bookIds &&
          formData.bookIds.split(',').map((bookId) => bookId.trim());
        break;
      case Roles.Observer:
      default:
        query.userName = shortId();
        return urlFormat({
          pathname: `${formData.meetingId}/${Roles.Observer}`,
          query,
        });
    }

    return urlFormat({
      pathname: `${formData.meetingId}/setup`,
      query,
    });
  };

  const onFinish = async (values) => {
    setIsLoading(true);
    const { courseId: meetingId } = await createCourse(values);
    if (!meetingId) {
      alert(trans('__courseCreatedFail'));
      return;
    }
    values.meetingId = meetingId;
    const teacherUrl = createUrl(Roles.Teacher, values);
    const studentUrl = createUrl(Roles.Student, values);
    const advisorUrl = createUrl(Roles.Advisor, values);
    const observerUrl = createUrl(Roles.Observer, values);
    setIsLoading(false);
    setUrl((prev) => ({
      ...prev,
      teacherUrl,
      studentUrl,
      advisorUrl,
      observerUrl,
    }));
  };

  const copyHandler = (text) => {
    navigator.clipboard.writeText(text).then(
      function () {
        message.success(trans('__courseCreatedFail'));
      },
      function (err) {
        message.error(trans('__couldNotCopyText', { err: err }));
      }
    );
  };

  const copyAllHandler = () => {
    const text = `
    ${trans('__teacher')}: ${urls.teacherUrl}

    ${trans('__student')}: ${urls.studentUrl}

    ${trans('__consultant')}: ${urls.advisorUrl}

    ${trans('__observer')}: ${urls.observerUrl}
    `;
    navigator.clipboard.writeText(text).then(
      function () {
        message.success(trans('__copiedSuccessfully', '複製成功！'));
      },
      function (err) {
        message.error(trans('__couldNotCopyText', { err: err }));
      }
    );
  };

  return (
    <StyledCreateMeetingPage data-testid="CreateMeetingPage">
      <Row justify="center">
        <Col span={12}>
          <Form {...layout} layout="vertical" onFinish={onFinish}>
            <Form.Item
              label={trans('__course')}
              name="courseName"
              rules={[
                { required: true, message: trans('__pleaseEnterCourseName') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={trans('__teacherName')}
              name="teacherName"
              rules={[
                { required: true, message: trans('__pleaseEnterTeacherName') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={trans('__bookId')}
              name="bookIds"
              rules={[
                { required: false, message: trans('__pleaseEnterTeacherName') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={trans('__studentName')}
              name="studentName"
              rules={[
                { required: true, message: trans('__pleaseEnterTeacherName') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={trans('__consultantName')}
              name="advisorName"
              rules={[
                { required: true, message: trans('__pleaseEnterTeacherName') },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={trans('__courseType')}
              name="classType"
              rules={[
                { required: true, message: trans('__pleaseSelectCourseType') },
              ]}
            >
              <Select>
                {Object.values(ClassType).map((type, index) => (
                  <Select.Option value={type} key={index}>
                    {ClassTypeMap[type]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Box display="flex" justifyContent="center">
                <Button type="primary" htmlType="submit" loading={isLoading}>
                  {trans('__generateLink')}
                </Button>
              </Box>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="center" gutter={[0, 24]}>
        <Col span={18}>
          <Input.Search
            addonBefore={trans('__teacher')}
            value={urls.teacherUrl}
            placeholder="input search text"
            enterButton={trans('__enterButton')}
            size="large"
            onSearch={() => copyHandler(urls.teacherUrl)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore={trans('__student')}
            value={urls.studentUrl}
            placeholder="input search text"
            enterButton={trans('__enterButton')}
            size="large"
            onSearch={() => copyHandler(urls.studentUrl)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore={trans('__consultant')}
            value={urls.advisorUrl}
            placeholder="input search text"
            enterButton={trans('__enterButton')}
            size="large"
            onSearch={() => copyHandler(urls.advisorUrl)}
          />
        </Col>
        <Col span={18}>
          <Input.Search
            addonBefore={trans('__observer')}
            value={urls.observerUrl}
            placeholder="input search text"
            enterButton={trans('__enterButton')}
            size="large"
            onSearch={() => copyHandler(urls.observerUrl)}
          />
        </Col>
        <Col span={10}>
          <Box display="flex" justifyContent="center">
            <Button type="primary" onClick={copyAllHandler}>
              {trans('__copyAll')}
            </Button>
          </Box>
        </Col>
      </Row>
    </StyledCreateMeetingPage>
  );
};
